const apiURL = process.env.REACT_APP_API_ENDPOINT;

const findChannel = async (name) => {
  let channel = {};

  try {
    const res = await fetch(`${apiURL}/channel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({ name }),
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        channel = { ...result };
      }
    }
  } catch (error) {
    console.log(error);
  }

  return channel;
};

const getGroupLabelList = async () => {
  let rs = {};

  try {
    const res = await fetch(`${apiURL}/product/getchanneldetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        type: "groupcategory",
      }),
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        rs = { ...result };
      }
    }
  } catch (error) {
    console.log(error);
  }

  return rs;
};

const getGroupList = async () => {
  let rs = [];

  try {
    const res = await fetch(`${apiURL}/group`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        rs = [...result.groupList??[]]
      }
    }
  } catch (error) {
    console.log(error);
  }

  return rs;
};

const getProductList = async () => {
  let rs = [];

  try {
    const res = await fetch(`${apiURL}/product`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        rs = [...result.productList??[]]
      }
    }
  } catch (error) {
    console.log(error);
  }

  return rs;
};

const updateChannelByName = async (name, channel) => {
  let rs = {};

  try {
    const res = await fetch(`${apiURL}/channel`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        name,
        channel,
      }),
    });
    if (res.ok) {
      const result = await res.json();
      if (result.success) {
        rs = { ...result };
      } else {
        rs = { success: false, message: "Trả về sai định dạng" };
      }
    }
  } catch (error) {
    console.log(error);
  }

  return rs;
};

const getPaymentState = (state) => {
  if (state) {
    return "Đã thanh toán";
  } else {
    return "Chưa thanh toán";
  }
};

const getInvoiceState = (state) => {
  if (state === 1) {
    return "Chưa xác nhận";
  } else if (state === 2) {
    return "Đã xác nhận";
  } else if (state === 3) {
    return "Đã xác nhận";
  } else if (state === 4) {
    return "Hoàn thành";
  } else if (state === 5) {
    return "Đã hủy";
  } else {
    return "";
  }
};
const getPaymentMethod = (pMethod) => {
  if (pMethod === "1") {
    return "Ship COD";
  } else if (pMethod === "2") {
    return "Chuyển khoản";
  } else if (pMethod === "3") {
    return "Banking";
  }
};
const getLocationArray = (array) => {
  let rs = [];
  if (array.length > 0) {
    for (let i in array) {
      if (array[i].Key && array[i].Key !== "") {
        rs.push(array[i].Key);
      }
    }
  }
  return rs;
};

const getCityList = async () => {
  let rs = [];
  try {
    const res = await fetch(`${apiURL}/city`, {
      method: "GET",
    });
    if (res.ok) {
      const result = await res.json();
      rs = [...(result.cityList ?? [])];
    } else {
      throw new Error("Failed to fetch data from the API");
    }
  } catch (error) {
    console.log(error);
  }
  return rs;
};

export default {
  findChannel,
  getGroupLabelList,
  updateChannelByName,
  getInvoiceState,
  getPaymentState,
  getPaymentMethod,
  getLocationArray,
  getGroupList,
  getProductList,
  getCityList
};
