import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import * as XLSX from "xlsx";
import { moneyFormat } from "../../helper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
function AddVoucher({ setAddVoucherTab, setTrigger }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  const [type, setType] = useState(1);
  //codeInput = true => input 1, codeInput = false => input file with array
  const [codeInput, setCodeInput] = useState(true)
  const [code, setCode] = useState("");
  const [codeList, setCodeList] = useState([]);
  const [active, setActive] = useState(true);
  const [isMinimum, setIsMinimum] = useState(false);
  const [minimum, setMinimum] = useState(0);
  const [useTime, setUseTime] = useState(1);
  const [useLimited, setUseLimited] = useState(false);
  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date())
  const [dateTo, setDateTo] = useState(new Date())
  const [maxAmount, setMaxAmount] = useState(0);
  const [isMaxForEachOrder, setIsMaxForEachOrder] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const createVoucher = () => {
    if(codeInput && code ===''){
      alert("Nhập code");
      return;
    }
    if(!codeInput && codeList.length === 0){
      alert("Nhập file chứa code");
      return;
    }
    if ((type === 1 && amount > 100) || type < 0) {
      alert("Giá trị không phù hợp");
      return;
    } else {
      fetch(`${apiURL}/voucher`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({
          type,
          codeInput,
          code: codeInput?code:codeList,
          active,
          useTime,
          useLimited,
          amount,
          dateFrom,
          dateTo,
          description,
          isMaxForEachOrder,
          maxAmount,
          isMinimum,
          minimum,
          isPublic
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            alert("Tạo mã voucher thành công");
          } else {
            alert("Có lỗi, thử đổi mã và thử lại");
          }
        });
    }
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const dataArr = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const newArr = [];
      dataArr.length > 0 &&
        dataArr.map((ele, index) => {
          if (ele[0]) {
            newArr.push(ele[0])
          }
        });
        setCodeList(newArr);
    };

    reader.readAsBinaryString(file);
  };

  return (
    <React.Fragment>
      <div
        className="toggleTab"
        onClick={(e) => {
          setAddVoucherTab(false);
          setTrigger((prev) => {
            return !prev;
          });
        }}
      >
        <div
          style={{
            maxHeight: "90%",
            overflowY: "auto",
            width : "50%",
            maxWidth : "none"
          }}
          className="childTab"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <h3 className="vodal-title ">Thêm voucher</h3>

            <div
              className="form-group px-0  "
              style={{ marginBottom: "10px", marginTop: "20px" }}
              onClick={(e) => {
                setActive((prev) => {
                  return !prev;
                });
              }}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                checked={active}
                type="checkbox"
              />
              <label className="label-input-group">Kích hoạt</label>
            </div>
            
            <div className=" px-0 inline">
                    <div
                    >

                      <label className="label-input-group ml10">
                        Thời gian hiệu lực
                      </label>
                    </div>

                    <div className="form-group px-0 inline ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDateTimePicker
                            views={["month", "day", "hours", "minutes"]}
                            inputFormat="DD/MM/YYYY HH:mm"
                            label="Bắt đầu từ"
                            value={dateFrom}
                            onChange={(newValue) => {
                              setDateFrom(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                style={{ width: "200px" }}
                                {...params}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    <div className="form-group px-0 inline ml20">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDateTimePicker
                            views={["month", "day", "hours", "minutes"]}
                            inputFormat="DD/MM/YYYY HH:mm"
                            label="Đến"
                            value={dateTo}
                            onChange={(newValue) => {
                              setDateTo(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                style={{ width: "200px" }}
                                {...params}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </div>
            <div
              className="form-group px-0 inline"
              style={{
                marginBottom: "0px",
                width: "100%",
              }}
            >
              <label className="label-input-group  mr20">Loại voucher</label>
              <select
                value={type}
                className="next-input"
                style={{
                  width: "200px",
                  marginRight: "20px",
                  marginBottom: "5px",
                  marginLeft: "0",
                  display: "inline-block",
                }}
                onChange={(e) => {
                  setType(Number(e.target.value));
                }}
              >
                <option value={1}>Giảm theo %</option>
                <option value={2}>Giảm trực tiếp</option>
              </select>
            </div>
            <div
              className="form-group px-0  "
              style={{ marginBottom: "10px", marginTop: "20px" }}
              onClick={(e) => {
                setCodeInput((prev) => {
                  return !prev;
                });
              }}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                checked={!codeInput}
                type="checkbox"
              />
              <label className="label-input-group">Nhập file</label>
            </div>
            {codeInput&&<div className="form-group px-0 " style={{ marginBottom: "10px" }}>
              <label className="label-input-group">Mã</label>
              <div className="buttonDiv">
                <input
                  value={code}
                  className="next-input"
                  placeholder="Nhập mã"
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                />
              </div>
            </div>}
            {
              !codeInput && <div>
                <div className="variation-edit-panel">
                              <div className="variation-edit-">
                                File xls gồm 1 cột mã bắt đầu từ dòng 1
                              </div>
                            </div>

                            <div className="product-edit-form-item-content">
                              <div style={{ marginBottom: "15px" }}>
                                <input
                                  type="file"
                                  onChange={handleFileChange}
                                  accept=".xlsx, .xls"
                                />
                              </div>
                              <div>
                                {`Có ${codeList.length} mã trong file`}
                              </div>
                            </div>
              </div>
            }
            <div className="form-group px-0 " style={{ marginBottom: "10px" }}>
              <label className="label-input-group">Mô tả</label>
              <div className="buttonDiv">
                <input
                  value={description}
                  className="next-input"
                  placeholder="Nhập mô tả"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className="form-group px-0  "
              style={{ marginBottom: "10px", marginTop: "20px" }}
              onClick={(e) => {
                setIsPublic((prev) => {
                  return !prev;
                });
              }}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                checked={isPublic}
                type="checkbox"
              />
              <label className="label-input-group">Gợi ý web</label>
            </div>
            <div
              className="form-group px-0  "
              style={{ marginBottom: "10px", marginTop: "20px" }}
              onClick={(e) => {
                setIsMinimum((prev) => {
                  return !prev;
                });
              }}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                checked={isMinimum}
                type="checkbox"
              />
              <label className="label-input-group">Giá trị đơn tối thiểu</label>
            </div>
            <div className="form-group px-0 " style={{marginBottom:"10px"}}>
              <label className="label-input-group">Giá trị tối thiểu</label>
              <div className="buttonDiv">
                <input
                  value={moneyFormat(minimum)}
                  disabled = {!isMinimum}
                  className="next-input"
                  placeholder="Nhập giá trị đơn tối thiểu"
                  onChange={(e) => {
                    const value =
                      e.target.value
                        .replace(/^0+/, "")
                        .replace(
                          /[^0-9]/g,
                          ""
                        );
                    setMinimum(Number(value));
                  }}
                />
              </div>
            </div>
            <div className="form-group px-0 " style={{ marginBottom: "10px" }}>
              <label className="label-input-group">{`Giá trị (${
                type === 1 ? `%` : `vnd`
              })`}</label>
              <div className="buttonDiv">
                <input
                  value={type === 1 ? amount : moneyFormat(amount)}
                  className="next-input"
                  placeholder="Nhập tên"
                  onChange={(e) => {
                    const value =
                      e.target.value
                        .replace(/^0+/, "")
                        .replace(
                          /[^0-9]/g,
                          ""
                        );
                        setAmount(Number(value));
                  }}
                />
              </div>
            </div>
            <div
              className="form-group px-0 "
              onClick={(e) => {
                setUseLimited((prev) => {
                  return !prev;
                });
              }}
              style={{
                marginBottom: "10px"}}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                checked={useLimited}
                type="checkbox"
                placeholder="Điện thoại"
              />
              <label className="label-input-group">Giới hạn lượt sử dụng</label>
            </div>
            {useLimited&&
            <div className="form-group px-0 " style={{marginBottom:"10px"}}>
              <label className="label-input-group">Số lần sử dụng</label>
              <div className="buttonDiv">
                <input
                  value={useTime}
                  type="number"
                  className="next-input"
                  placeholder="Nhập số lần sử dụng"
                  onChange={(e) => {
                    setUseTime(Number(e.target.value));
                  }}
                />
              </div>
            </div>}
            {/* <div
              className="form-group px-0 "
              onClick={(e) => {
                setIsMaxForEachOrder((prev) => {
                  return !prev;
                });
              }}
              style={{
                marginBottom: "10px"}}
            >
              <input
                style={{
                  marginRight: "5px",
                }}
                checked={isMaxForEachOrder}
                type="checkbox"
                placeholder="Điện thoại"
              />
              <label className="label-input-group">Giới hạn giảm tối đa mỗi đơn</label>
            </div>
            {isMaxForEachOrder&&
            <div className="form-group px-0 ">
              <label className="label-input-group">Số tiền giảm tối đa</label>
              <div className="buttonDiv">
                <input
                  value={maxAmount}
                  type="number"
                  className="next-input"
                  placeholder="Nhập số lần sử dụng"
                  onChange={(e) => {
                    setMaxAmount(Number(e.target.value));
                  }}
                />
              </div>
            </div>} */}

            <div className="right-button">
              <button
                className="btn btn-primary text-right"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm("Xác nhận tạo voucher?")) {
                    createVoucher();
                  }
                }}
              >
                Thêm
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddVoucher;
