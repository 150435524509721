import React, { useState } from "react";

function AddEmp({ setAddEmpTab , setTrigger}) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  const [name, setName] = useState("")
  const [pwd, setPwd] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [role, setRole] = useState(2)
  const createEmp = () => {
    if(role !== 0){
        if(name === "" || email === "" || pwd === "" || phone === ""){
            alert('Điền đầy đủ thông tin')
            return
        }else{
            
    fetch(`${apiURL}/employee`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({
          phone,
          name,
          email,
          pwd,
          role,
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          alert(result.mes)
        });
        }
    }else{
        alert('Chọn vai trò')
        return
    }

  }


  return (
    <React.Fragment>
      <div
        className="toggleTab"
        onClick={(e) => {
            setAddEmpTab(false);
            setTrigger(prev=>{return !prev})
        }}
      >
        <div
          className="childTab"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
                                
            <h3 className="vodal-title ">Thêm người dùng</h3>
            
            
            <div className="form-group px-0 mgt20">
            <span
                                className ="remove_link remove-cart"
                                style={{ cursor: "pointer" }}
                              >
                              </span>
                <label className="label-input-group">
                Tên người dùng
                </label>
                <div className="buttonDiv">
                <input
                    value={name}
                    className="next-input"
                    placeholder="Nhập tên"
                    onChange={(e) => {
                    setName(e.target.value);
                    }}
                />
                </div>
            </div>
            <div className="form-group px-0 mgt20">
                <label className="label-input-group">
                Email
                </label>
                <div className="buttonDiv">
                <input
                    value={email}
                    className="next-input"
                    placeholder="Email"
                    onChange={(e) => {
                    setEmail(e.target.value);
                    }}
                />
                </div>
            </div>
            <div className="form-group px-0 mgt20">
                <label className="label-input-group">
                Điện thoại
                </label>
                <div className="buttonDiv">
                <input
                    value={phone}
                    type = "number"
                    className="next-input"
                    placeholder="Điện thoại"
                    onChange={(e) => {
                    setPhone(e.target.value);
                    }}
                />
                </div>
            </div>
            <div className="form-group px-0 mgt20">
                <label className="label-input-group">
                Mật khẩu
                </label>
                <div className="buttonDiv">
                <input
                    value={pwd}
                    type = "password"
                    className="next-input"
                    placeholder="Điện thoại"
                    onChange={(e) => {
                    setPwd(e.target.value);
                    }}
                />
                </div>
            </div>
            <div className="form-group px-0 mgt20">
                <label className="label-input-group">
                Vai trò
                </label>
                <div className="buttonDiv">
                <select 
                            className="next-input"
                            style={{
                              width: "250px",
                              marginRight: "20px",
                              marginBottom: "5px",
                              marginLeft: "0",
                            }} value = {role} onChange = {(e)=>{setRole(Number(e.target.value))}}>
                    <option value = {1}>Admin</option>
                    <option value = {2}>Nhân viên</option>
                </select>
                </div>
            </div>

            <div className="right-button">
            <button
            className="btn btn-primary text-right"
            onClick={(e) => {
                e.preventDefault()
                if(window.confirm('Xác nhận tạo tài khoản?')) {
                    createEmp()
                }
            }}
            >
                Thêm
            </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddEmp;
