import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import { moneyFormat, dateFormat } from "../../helper";
import { useSearchParams } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Api from "../../api/apis";
import AddProductInfo from "../../components/AddProductInfo";
import * as XLSX from "xlsx";

function OrderInfo({ setSelect }) { 
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [order, setOrder] = useState({});
  const [searchParams] = useSearchParams();
  const [adminNote, setAdminNote] = useState("");

  const [cityList, setCityList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [cityCode, setCityCode] = useState("");
  const [districtCode, setDistrictCode] = useState("");
  const [city, setCity] = useState("")
  const [district, setDistrict] = useState("")
  const [groupLabelListAll, setGroupLabelListAll] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const [cancelReason, setCancelReason] = useState("")
  const [groupAddType, setGroupAddType] = useState(2);
  const [addProductInfoTab, setAddProductInfoTab] = useState(false);
  const [addType, setAddType] = useState("");
  const [note, setNote] = useState("")
  const [email, setEmail] = useState("")

  //chuong trinh thang 7
  const [codeList, setCodeList] = useState([]);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const dataArr = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const newArr = [];
      dataArr.length > 0 &&
        dataArr.map((ele, index) => {
          if (ele[0]&&ele[1]) {
            newArr.push([ele[0],ele[1]])
          }
        });
        setCodeList(newArr);
    };

    reader.readAsBinaryString(file);
  };

  const createJulyCode = () => {
      fetch(`${apiURL}/invoice/createjulycode`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({
          codeList
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            alert("Tạo mã voucher thành công");
          } else {
            alert("Có lỗi, thử đổi mã và thử lại");
          }
        });
  };

  useEffect(() => {
    fetch(`${apiURL}/product/getchanneldetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        type: "groupcategory",
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setGroupLabelListAll(result.list);
        }
      });
  }, [trigger]);
 
  useEffect(() => {
    let set = true;
    fetch(`${apiURL}/city`, {
      method: "GET",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((result) => {
        setCityList(result.cityList);
      })
      .catch((e) => {});

    return () => {
      set = false;
    };
  }, []);

  useEffect(() => {
    if (order && order.cityCode) {
      setCityCode(order.cityCode);
    } else {
      setCityCode(cityList.length > 0 ? cityList[0].code ?? "" : "");
    }
  }, [cityList, order]);

  useEffect(() => {
    let arr = [];
    let cityName = ""
    if (cityCode !== "" && cityList.length > 0) {
      arr = cityList[0].districts;
      cityList.map((ele, index) => {
        if (ele.code === cityCode) {
          arr = ele.districts;
          cityName = ele.name
        }
      });
    }
    setCity(cityName)
    setDistrictList(arr);
  }, [cityCode, cityList, order]);

  useEffect(() => {
    if (order && order.districtCode && districtList.length > 0) {
      let code = districtList[0].code;
      districtList.map((ele, index) => {
        if (ele.code === order.districtCode) {
          code = ele.code;
        }
      });
      setDistrictCode(code);
    } else {
      setDistrictCode(
        districtList.length > 0 ? districtList[0].code ?? "" : ""
      );
    }
  }, [districtList, order]);

  useEffect(() => {
    
    let name = ""
    if (districtList.length > 0) {
      districtList.map((ele, index) => {
        if (ele.code === districtCode) {
          name = ele.name;
        }
      });
    }
    setDistrict(name);

  }, [districtCode, districtList]);

  
  useEffect(() => {
    setAdminNote(order.adminNote ?? "");
    setEmail(order.email ?? "")
  }, [order]);
  useEffect(() => {
    setSelect("invoice")
    let id = searchParams.get("id") ?? "";
    if (id !== "") {
      fetch(`${apiURL}/invoice/find?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            setOrder(result.invoice);
            setNote(result?.note ?? "")
          } else {
            alert(result.message);
          }
        });
    }
  }, []);
  const getPaymentMethod = () => {
    let methods = {
      1:"Tiền mặt",
      2:"Chuyển khoản ngân hàng",
      3:"Thanh toán qua OnePay",
      4:"Payoo"
    }
    return methods[(order?(order?.paymentMethod??"none"):"none")]??""
  };
  const deliverOrder = () => {
    if (order) {
      fetch(`${apiURL}/invoice/change/deliver/delivering`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({ invoiceId: order._id }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            alert("Xác nhận đơn thành công");
            window.location.reload(false);
          } else {
            alert(result.message);
          }
        });
    } else {
      alert("Đơn hàng chưa được xác nhận");
    }
  };
  const cancelOrder = () => {
    if(cancelReason !== "") {
      fetch(`${apiURL}/invoice/change/invoicestate/cancel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({ invoiceId: order._id, cancelReason }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            alert("Đơn đã huỷ");
            window.location.reload(false);
          } else {
            alert(result.message);
          }
        });
    }else{
      alert('Hãy chọn lý do hủy đơn')
    }
    
  };
  const confirmPayment = () => {
    fetch(`${apiURL}/invoice/change/orderpaymentstate/success`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({ invoiceId: order._id }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          alert("Đã xác nhận thanh toán");
          window.location.reload(false);
        } else {
          alert(result.message);
        }
      });
  };
  const isDateInRange = date => {
    if (!date) return false;
    const dateInTargetTimezone = new Date(new Date(date).toLocaleString('en-US', { timeZone: 'Asia/Bangkok' }));
    
    const start = new Date(new Date('2024-07-15T00:00:00+07:00').toLocaleString('en-US', { timeZone: 'Asia/Bangkok' }));
    const end = new Date(new Date('2024-07-31T23:59:59+07:00').toLocaleString('en-US', { timeZone: 'Asia/Bangkok' }));
    return dateInTargetTimezone >= start && dateInTargetTimezone <= end;
  };
  return (
    <React.Fragment>
      <div className="AdminTab orderInfo">
        <div className="row align-items-start">
          <div className="col-lg col-sm-12">
            <div className="order-detail--list-status">
              <div className="ui-toolbar-product-info">
                <div className="ui-product-body">Mã</div>
                <div className="ui-product-head">
                  <div className="table-break-word">
                    <strong class="order-detail--list-status-code">
                      {order.code}
                    </strong>
                  </div>
                </div>
              </div>
              <div className="ui-toolbar-product-info">
                <div className="ui-product-body">Trạng thái đơn hàng</div>
                <div className="ui-product-head">
                  <div className="table-break-word">
                    <div
                      className={`order-detail--list-status-name ${
                        order.invoiceState === 1
                          ? "yellow"
                          : order.invoiceState === 3
                          ? "green"
                          : order.invoiceState === 5
                          ? "red"
                          : "red"
                      }`}
                    >
                      <span className="circle-status"></span>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                        className="badges--carrier-status-7"
                      >
                        {" "}
                        {Api.getInvoiceState(order.invoiceState)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {
                <div className="ui-toolbar-product-info">
                  <div className="ui-product-body">Trạng thái thanh toán</div>
                  <div className="ui-product-head">
                    <div className="table-break-word">
                      <div
                        className={`order-detail--list-status-name ${
                          order.paymentState ? "green" : "yellow"
                        }`}
                      >
                        <span className="circle-status"></span>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                          className="badges--carrier-status-7"
                        >
                          {" "}
                          {Api.getPaymentState(order.paymentState)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div>
              <span>{dateFormat(order.dateCreate) ?? ""}</span>
            </div>
          </div>
          <div className="col-lg-auto col-sm-12 text-right pt-2"></div>
        </div>
        <div className="padding-container">
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="omni-layout-card card-default">
                <div className="omni-layout-card--header">
                  <div
                    style={{
                      paddingLeft: "25px",
                      paddingTop: "25px",
                    }}
                  >
                    <div className="table-break-word">
                      <div
                        className={`order-detail--list-status-name ${
                          order.invoiceState === 1
                            ? "yellow"
                            : order.invoiceState === 3
                            ? "green"
                            : order.invoiceState === 5
                            ? "red"
                            : "red"
                        }`}
                      >
                        <span className="circle-status"></span>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                          className="badges--carrier-status-7"
                        >
                          {" "}
                          {Api.getInvoiceState(order.invoiceState)}
                        </span>
                      </div>
                    </div>
                  </div>
                        {
                          <div>

                          <div className="px-0 inline ">
                          <label className="label-input-group mgt20">
                            {`Mã khuyến mãi: ${
                              order && (order?.voucher?.code ?? "")
                            }`}
                          </label>
                        </div>
                          </div>
                        }
                  {
                    <div className="px-0 inline ">
                      <label className="label-input-group mgt20">
                        {`Ghi chú khách hàng: ${
                          order && (order.customerNote ?? "")
                        }`}
                      </label>
                    </div>
                  }

                  {
                    <div className=" px-0 mgt20" style={{ display: "flex" }}>
                      <label
                        className="label-input-group "
                        style={{ verticalAlign: "top", width:"130px" }}
                      >
                        {`Ghi chú nhân viên: `}
                      </label>
                      <div
                        className="buttonDiv"
                        style={{
                          flex: "1",
                          display: "inline-block",
                          marginLeft: "15px",
                          textAlign: "left",
                        }}
                      >
                        <textarea
                          placeholder="Ghi chú"
                          style={{
                            width: "100%",
                            height: "70px"
                          }}
                          className="next-input numInput  mgb20"
                          value={adminNote}
                          onChange={(e) => {
                            setAdminNote(e.target.value);
                          }}
                        />

                        <span
                          className="css-1f0mdg1 mgb20 inline"
                          style={{ display: "block" }}
                        >
                          <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation css-1a96ihp"
                            tabIndex="0"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              fetch(`${apiURL}/invoice/updateadminnote`, {
                                method: "PUT",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization:
                                    "Bearer " + localStorage.banyanAdminToken
                                      ? localStorage.banyanAdminToken
                                      : "",
                                },
                                body: JSON.stringify({
                                  adminNote,
                                  invoiceId: order._id,
                                }),
                              })
                                .then((res) => {
                                  if (res.ok) return res.json();
                                  throw new Error("Something went wrong");
                                })
                                .then((result) => {
                                  if (result.success) {
                                    alert("Cập nhật ghi chú thành công");
                                  }
                                });
                            }}
                          >
                            Cập nhật
                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                          </button>
                        </span>
                      </div>
                    </div>
                  }

{
                    <div className=" px-0 mgt20" style={{ display: "flex" }}>
                      <label
                        className="label-input-group "
                        style={{ verticalAlign: "top", width:"130px" }}
                      >
                        {`Gửi lại email: `}
                      </label>
                      <div
                        className="buttonDiv"
                        style={{
                          flex: "1",
                          display: "inline-block",
                          marginLeft: "15px",
                          textAlign: "left",
                        }}
                      >
                        <textarea
                          placeholder="Nhập email"
                          disabled
                          style={{
                            width: "100%",
                          }}
                          className="next-input numInput  mgb20"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        /> 
                        
                        <span
                          className="css-1f0mdg1 mgb20 inline"
                          style={{ display: "block" }}
                        >
                          <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation css-1a96ihp"
                            tabIndex="0"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              if (email===""){
                                alert("Nhập email")
                              }
                              else if (window.confirm("Gửi email xác nhận?")) {
                                
                              fetch(`${apiURL}/invoice/sendcusemail`, {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization:
                                    "Bearer " + localStorage.banyanAdminToken
                                      ? localStorage.banyanAdminToken
                                      : "",
                                },
                                body: JSON.stringify({
                                  invoiceId: order._id,
                                }),
                              })
                                .then((res) => {
                                  if (res.ok) return res.json();
                                  throw new Error("Something went wrong");
                                })
                                .then((result) => {
                                  if (result.success) {
                                    alert("Gửi mail thành công");
                                  }
                                });
                              }
                            }}
                          >
                            Gửi email
                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                          </button>
                        </span>
                      </div>
                    </div>
                  } 
                  {order?.dateCreate && isDateInRange(order.dateCreate) &&
                    <div className=" px-0 mgt20" style={{ display: "flex" }}>
                      <label
                        className="label-input-group "
                        style={{ verticalAlign: "top", width:"130px" }}
                      >
                        {`Code tháng 7: `}
                      </label>
                      <div
                        className="buttonDiv"
                        style={{
                          flex: "1",
                          display: "inline-block",
                          marginLeft: "15px",
                          textAlign: "left",
                        }}
                      >
                        <label
                          className="label-input-group "
                          style={{ verticalAlign: "top", width:"130px",
                            marginBottom: "15px", }}
                        >
                          {`${(order?.emailDiscountSent?.isSent??false) === false ? `Chưa gửi` : `${order?.emailDiscountSent?.code1??""}/${order?.emailDiscountSent?.code2??""}` }`}
                        </label>
                        
                        <span
                          className="css-1f0mdg1 mgb20 inline"
                          style={{ display: "block" }}
                        >
                          <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation css-1a96ihp"
                            tabIndex="0"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              if (email===""){
                                alert("Nhập email")
                              }
                              else if (window.confirm("Gửi mã?")) {
                                
                              fetch(`${apiURL}/invoice/sendcodyjuly`, {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization:
                                    "Bearer " + localStorage.banyanAdminToken
                                      ? localStorage.banyanAdminToken
                                      : "",
                                },
                                body: JSON.stringify({
                                  invoiceId: order._id,
                                }),
                              })
                                .then((res) => {
                                  if (res.ok) return res.json();
                                  throw new Error("Something went wrong");
                                })
                                .then((result) => {
                                  if (result.success) {
                                    alert("Gửi mail thành công");
                                    setTrigger(prev=>{return !prev})
                                  }else{
                                    alert(result?.message?? "error sent mail")
                                  }
                                });
                              }
                            }}
                          >
                            Gửi mã
                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                          </button>
                        </span>
                      </div>
                      {/* <div>
                <div className="variation-edit-panel">
                              <div className="variation-edit-">
                                File xls gồm 1 cột mã bắt đầu từ dòng 1
                              </div>
                            </div>

                            <div className="product-edit-form-item-content">
                              <div style={{ marginBottom: "15px" }}>
                                <input
                                  type="file"
                                  onChange={handleFileChange}
                                  accept=".xlsx, .xls"
                                />
                              </div>
                              <div>
                                {`Có ${codeList.length} mã trong file`}
                              </div>
                              <button onClick={(e)=>{
                                createJulyCode()
                              }}>tạo mã</button>
                            </div>
              </div> */}
                    </div>
                  } 
                  <div className="">
                    <label className="label-input-group mgt20">
                      {`Danh sách sản phẩm mua`}
                    </label>
                    <div className="position-relative">
                      <div className="table-list--config">
                        <div className="ui-table-normal-container">
                          <table className="ui-table greyTh">
                            <thead>
                              <tr>
                                <th className="">Sản phẩm</th>
                                <th className=" text-center">
                                  <span>Số lượng</span>
                                </th>
                                {
                                  <th className="text-center">
                                    <span>Đơn giá</span>
                                  </th>
                                }
                                {
                                  <th className="text-center">
                                    <span>Thành tiền</span>
                                  </th>
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {order.productList &&
                                order.productList.map((ele, index) => {
                                  return (
                                    <tr
                                      style={{
                                        borderBottom: "1px solid #eee",
                                      }}
                                      key={index}
                                    >
                                      <td className="  align-middle text-normal fix-overflow-tooltip">
                                        <div className="order-block--table">
                                          <div>
                                            <img
                                              style={{ maxHeight: "80px" }}
                                              src={ele.img ?`${cloudFrontURL}/${ele.img}`: ""}
                                              alt=""
                                            />
                                          </div>
                                          <div className="order-block--table-content">
                                            <div className="mb-2 text-secondary width300">
                                              {`${ele.name ?? "" ?? ""} / ${
                                                ele?.colorArrayDesc??``
                                              }`}
                                            </div>
                                            <div className="mb-2 text-secondary ">
                                              {`${ele.size}`}
                                            </div>
                                            <div className="mb-2 text-secondary ">
                                              {`${ele.sku}`}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-center align-top">
                                        {ele.quantity ?? 0}
                                      </td>
                                      {
                                        <td className="text-center align-top pt-3">
                                          {moneyFormat(
                                            order.pList && order.pList[ele.sku]
                                              ? order.pList[ele.sku]
                                              : ele.price
                                          )}
                                        </td>
                                      }
                                      {
                                        <td className="text-center align-top pt-3">
                                          {moneyFormat(
                                            (order.pList && order.pList[ele.sku]
                                              ? order.pList[ele.sku]
                                              : ele.price) * ele.quantity
                                          )}
                                        </td>
                                      }
                                    </tr>
                                  );
                                })}
                                {
                                  order && order.GWP && order.GWP.length > 0 && 
                                  order.GWP.map((ele,index)=>{
                                    return(
                                      <tr key = {index}
                                      style={{
                                        borderBottom: "1px solid #eee",
                                      }}
                                    >
                                      <td className="  align-middle text-normal fix-overflow-tooltip">
                                        <div className="order-block--table">
                                          <div>
                                            <img
                                              style={{ maxHeight: "80px" }}
                                              src={ele.img ?`${cloudFrontURL}/${ele.img}`: ""}
                                              alt=""
                                            />
                                          </div>
                                          <div className="order-block--table-content">
                                            <div className="mb-2 text-secondary width300">
                                              {`${ele.name ?? "" ?? ""} / ${
                                                ele?.colorArrayDesc??``
                                              }`}
                                            </div>
                                            <div className="mb-2 text-secondary ">
                                              {`${ele.size}`}
                                            </div>
                                            <div className="mb-2 text-secondary ">
                                              {`${ele.sku}`}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-center align-top">
                                        {ele.quantity ?? 0}
                                      </td>
                                      {
                                        <td className="text-center align-top pt-3"style={{color:"red", fontWeight:"700"}}>
                                          Quà tặng
                                        </td>
                                      }
                                      {
                                        <td className="text-center align-top pt-3"style={{color:"red", fontWeight:"700"}}>
                                          Quà tặng
                                        </td>
                                      }
                                    </tr>
                                    )
                                  })
                                }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
 
                  
                  <div className="">
                    <label className="label-input-group mgt20" style={{marginRight:10}}>
                      {`Lý do hủy đơn: `}
                    </label>
                    {order && order.invoiceState === 5 &&<label className="label-input-group mgt20">
                      {`${order?.cancelReason ?? ""}`}
                    </label>}
                    {[1, 3].includes(order.invoiceState) &&<Icon
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                          icon="material-symbols:add-circle"
                          onClick={() => {
                            setAddProductInfoTab(true);
                            setAddType("groupcategory");
                          }}
                        />}
                         {[1, 3].includes(order.invoiceState) &&(groupLabelListAll)&&<Autocomplete
                                sx={{ minWidth: 120 }}
                                id="tags-outlined"
                                size = "small"
                                

                                options= {groupLabelListAll.filter(
                                  (v, i) => v.type === 2
                                )}
                                // tên hiển thị 
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                />
                                
                                )}
                                filterOptions={(options, state) => {
                                    const inputValue = state.inputValue.toLowerCase();
                                    return options.filter((option) =>
                                      option.name.toLowerCase().includes(inputValue)
                                    );
                                  }}
                                onChange={(event, newValue) => {
                                    setCancelReason(newValue?.name??"")
                                  }}
                                  
                                //stop warning
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                            />}
                  </div>
                   
                </div>
                <div className="mgt50">
                  <div className="omni-layout-card--section text-right">
                    {order && order.invoiceState === 1 && (
                      <div className="text-right inline mr20">
                        <span
                          className="css-1f0mdg1 mgb20 inline"
                          style={{ display: "block" }}
                        >
                          <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation css-1a96ihp"
                            tabIndex="0"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();

                              if (window.confirm("Xác nhận đơn hàng?")) {
                                deliverOrder()
                              }
                            }}
                          >
                            Xác nhận đơn
                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                          </button>
                        </span>
                      </div>
                    )}

                    {[1, 3].includes(order.invoiceState) && (
                      <div className="text-right inline mr20">
                        <span
                          className="css-1f0mdg1 mgb20 inline"
                          style={{ display: "block" }}
                        >
                          <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation css-1a96ihp"
                            tabIndex="0"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();

                              if (window.confirm("Xác nhận hủy đơn hàng?")) {
                                cancelOrder();
                              }
                            }}
                          >
                            Hủy đơn
                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                          </button>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {
                <div className="omni-layout-card card-default">
                  <div className="omni-layout-card--header line-bottom">
                    <div
                      style={{
                        paddingTop: "25px",
                        paddingLeft: "25px",
                      }}
                    >
                      <div className="table-break-word">
                        <div
                          className={`order-detail--list-status-name ${
                            order.paymentState === true ? "green" : "yellow"
                          }`}
                        >
                          <span className="circle-status"></span>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                            className="badges--carrier-status-7"
                          >
                            {" "}
                            {Api.getPaymentState(order.paymentState)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="omni-layout-card--section">
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-6">
                        {
                          <div className="ui-information-body pb-4">
                            <div className="row">
                              <div className="col">Tiền hàng</div>
                              <div className="col-auto text-right">
                                {moneyFormat(order.afterDiscountPrice ?? 0)}
                              </div>
                            </div>
                          </div>
                        }
                        {
                          <div className="ui-information-body pb-4">
                            <div className="row">
                              <div className="col">Chương trình có điều kiện</div>
                              <div className="col-auto text-right">
                                - {moneyFormat(order.conditionDiscountValue ?? 0)}
                              </div>
                            </div>
                          </div>
                        }{
                          <div className="ui-information-body pb-4">
                            <div className="row">
                              <div className="col">Voucher</div>
                              <div className="col-auto text-right">
                                {`- 
                                ${(order &&
                                order.voucher) 
                                  ? moneyFormat(order.voucher.totalDiscount!==0&&(order.voucher.totalDiscount ?? 0))
                                  : moneyFormat(0)}`}
                              </div>
                            </div>
                          </div>
                        }
                        {
                          <div className="ui-information-body pb-4">
                            <div className="row">
                              <div className="col">Tiền ship</div>
                              <div className="col-auto text-right">
                                {moneyFormat(order.deliverFee!==0&&(order.deliverFee ?? 0))}
                              </div>
                            </div>
                          </div>
                        }
                        
                        {
                          <div className="ui-information-body pb-4">
                            <div className="row">
                              <div className="col">Tổng đơn</div>
                              <div className="col-auto text-right">
                                {moneyFormat(order.finalPrice ?? 0)}
                              </div>
                            </div>
                          </div>
                        }
                        <div className="ui-information-body pb-4">
                          <div className="row">
                            <div className="col">Phương thức thanh toán</div>
                            <div className="col-auto text-right mw200">
                              {getPaymentMethod()}
                            </div>
                          </div>
                        </div>
                        {order && order.paymentState === false && (
                          <div className="ui-information-body pb-4">
                            <div className="row">
                              <div className="col"></div>
                              <div className="col-auto text-right">
                                <span
                                  className="css-1f0mdg1 mgb20 inline"
                                  style={{ display: "block" }}
                                >
                                  <button
                                    className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation css-1a96ihp"
                                    tabIndex="0"
                                    type="button"
                                    onClick={(e) => {
                                      e.preventDefault();

                                      if (
                                        window.confirm(
                                          "Xác nhận thanh toán đơn hàng?"
                                        )
                                      ) {
                                        confirmPayment();
                                      }
                                    }}
                                  >
                                    Xác nhận thanh toán
                                    <span className="MuiTouchRipple-root css-w0pj6f"></span>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }

              <div className="omni-layout-card card-default ghosty">
                <p className="order-history--title">Lịch sử</p>
                <div className="activity-log ">
                  <div>
                    <div className="timeline-date-event timline-date-event--margin mt-0">
                      {order &&
                        order.historyLog &&
                        order.historyLog
                          .slice(0)
                          .reverse()
                          .map((ele, index) => {
                            return (
                              <div
                                key={index}
                                className="timeline-event-content active"
                              >
                                <div className="timeline-item">
                                  <div className="timeline-body">
                                    <div className="timeline__message-container">
                                      <div className="timeline__inner-message">
                                        <p className="mb-2">
                                          {`${ele.creator ?? ""} ${
                                            ele.action ?? ""
                                          }`}
                                        </p>
                                      </div>
                                    </div>
                                    <time className="timeline__time">
                                      <span>{dateFormat(ele.date)}</span>
                                    </time>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              {
                <div className="omni-layout-card card-default">
                  <div className="omni-layout-card--header line-bottom">
                    <span class="header-title">Thông Tin Người Mua</span>
                  </div>
                  <div className="omni-layout-card--section">
                    <div>
                      <div className=" px-0 mgt20" style={{ display: "flex" }}>
                        <label
                          className="label-input-group "
                          style={{ verticalAlign: "top" }}
                        >
                          {`Tên : ${order.customerName ?? ""}`}
                        </label>
                      </div>
                      <div className=" px-0" style={{ display: "flex" }}>
                        <label
                          className="label-input-group "
                          style={{ verticalAlign: "top" }}
                        >
                          {`Email : ${order.email??""}`}
                        </label>
                      </div>
                      <div className=" px-0" style={{ display: "flex" }}>
                        <label
                          className="label-input-group "
                          style={{ verticalAlign: "top" }}
                        >
                          {`Điện thoại : ${order.phone??""}`}
                        </label>
                      </div>
                      <div className=" px-0" style={{ display: "flex" }}>
                        <label
                          className="label-input-group "
                          style={{ verticalAlign: "top" }}
                        >
                          {`Ghi chú SĐT`}
                        </label>
                      </div>
                        <textarea
                          value={note}
                          onChange={(e)=>{
                            setNote(e.target.value)
                          }}
                          rows={4} // Số hàng bạn muốn hiển thị
                          style={{width:"100%"}}
                          placeholder="Nhập ghi chú"
                        />
                        
                        <span
                          className="css-1f0mdg1 mgb20 inline"
                          style={{ display: "block" }}
                        >
                          <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation css-1a96ihp"
                            tabIndex="0"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              fetch(`${apiURL}/phone/note`, {
                                method: "PUT",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization:
                                    "Bearer " + localStorage.banyanAdminToken
                                      ? localStorage.banyanAdminToken
                                      : "",
                                },
                                body: JSON.stringify({
                                  phone : order?.phone ?? "", 
                                  note,
                                }),
                              })
                                .then((res) => {
                                  if (res.ok) return res.json();
                                  throw new Error("Something went wrong");
                                })
                                .then((result) => {
                                  if (result.success) {
                                    alert("Cập nhật ghi chú thành công");
                                  }
                                });
                            }}
                          >
                            Cập nhật
                            <span className="MuiTouchRipple-root css-w0pj6f"></span>
                          </button>
                        </span>
                    </div>
                  </div>
                  {
                    <div className="omni-layout-card--section">
                      <p className="header-title">Địa Chỉ Giao Hàng</p>

                      <div className=" px-0" style={{ display: "flex" }}>
                        <label
                          className="label-input-group "
                          style={{ verticalAlign: "top" }}
                        >
                          {`${order.address??""}, ${district}, ${city} `}
                        </label>
                      </div>
                      
                    </div>  
                  }
                  {
                    order&&order.VAT&&order.VAT.active === true && <div className="omni-layout-card--section">
                      <p className="header-title">Có xuất hóa đơn</p>

                      <div className=" px-0" style={{ display: "flex" }}>
                        <label
                          className="label-input-group "
                          style={{ verticalAlign: "top" }}
                        >
                          {`Tên công ty: ${order&&order.VAT&&(order.VAT.companyName??"")}`}
                        </label>
                      </div>

                      <div className=" px-0" style={{ display: "flex" }}>
                        <label
                          className="label-input-group "
                          style={{ verticalAlign: "top" }}
                        >
                          {`Mã số thuế: ${order&&order.VAT&&(order.VAT.taxNumber??"")}`}
                        </label>
                      </div>
                      <div className=" px-0" style={{ display: "flex" }}>
                        <label
                          className="label-input-group "
                          style={{ verticalAlign: "top" }}
                        >
                          {`Địa chỉ: ${order&&order.VAT&&(order.VAT.address??"")}`}
                        </label>
                      </div>
                    </div>  
                  }
                </div>
              }
            </div>
          </div>
          <div className="order-delete--block"></div>
        </div>
      </div>
      {addProductInfoTab && (
        <AddProductInfo
          setAddProductInfoTab={setAddProductInfoTab}
          setTrigger={setTrigger}
          addType={addType}
          groupAddType = {groupAddType}
          groupLabelListAll={groupLabelListAll}
        />
      )}
    </React.Fragment>
  );
}

export default OrderInfo;
