import React, { useEffect, useState } from "react";
import AddStore from "../../components/AddStore";
import ChangeStore from "../../components/ChangeStore";

function StoreList({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  const [channelInfo, setChannelInfo] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [focus, setFocus] = useState({});

  const [cityList, setCityList] = useState([]);
  const [cityListFilter, setCityListFilter] = useState([]);
  const [city, setCity] = useState("");
  const [storeList, setStoreList] = useState([]);
  const [store, setStore] = useState("");
  const [addStoreTab, setAddStoreTab] = useState(false);
  const [changeStoreTab, setChangeStoreTab] = useState(false);

  useEffect(() => {
    setCity("");
  }, [cityList]);

  useEffect(() => {
    setSelect("storeList");

    fetch(`${apiURL}/city`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        setCityList(result.cityList ?? []);
      });
  }, []);

  useEffect(() => {
    const processedList = cityList.map(city => {
      city.name = city.name.replace("Tỉnh ", "").replace("Thành phố ", "").trim();
      return city;
    });
    // Lọc các object có code = 1 hoặc 79
    const filteredList = processedList.filter(city => city.code === 1 || city.code === 79);

    // Lọc các object có code khác 1 và 79 và sắp xếp theo trường name
    const otherCities = processedList.filter(city => city.code !== 1 && city.code !== 79);
    otherCities.sort((a, b) => a.name.localeCompare(b.name));

    // Kết hợp lại thành một mảng mới
    const finalList = [...filteredList, ...otherCities];
    setCityListFilter(finalList)
  }, [cityList]);

  useEffect(() => {
    fetch(`${apiURL}/store`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        setStoreList(result.storeList ?? []);
      });
  }, [trigger, addStoreTab]);

  return (
    <React.Fragment>
      {addStoreTab && (
        <AddStore setAddStoreTab={setAddStoreTab} setTrigger={setTrigger} 
        cityListFilter={cityListFilter} />
      )}
      {changeStoreTab && (
        <ChangeStore
          setChangeStoreTab={setChangeStoreTab}
          setTrigger={setTrigger}
          store={store}
          cityListFilter={cityListFilter}
        />
      )}
      <React.Fragment>
        <div className="page">
          <div className="edit_container">
            <section className="edit_section" style={{ marginBottom: "130px" }}>
              <div className="product-detail-panel product-basic-info">
                <div className="panel-header">
                  <div className="panel-title">
                    <div className="basic-info-title">Hệ thống cửa hàng</div>
                  </div>
                </div>
                <div className="panel-content-wrapper">
                  <div className="panel-content">
                    <div className="p_container">
                      <div className="edit-row">
                        <div className="edit-label edit-title">
                          <span>Thành phố</span>
                        </div>
                        <div className="edit-main">
                          <div className="popover-wrap">
                            <select
                              className="next-input"
                              style={{
                                width: "200px",
                                marginRight: "20px",
                                marginBottom: "5px",
                                marginLeft: "0",
                              }}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}
                            >
                              <option value="">Tất cả</option>
                              {cityListFilter &&
                                cityListFilter.map((ele, index) => {
                                  return (
                                    <option key={index} value={ele._id}>
                                      {ele.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="edit-row">
                        <div className="edit-label edit-title">
                          <span>Danh sách cửa hàng</span>
                        </div>
                        <div className="edit-main">
                          <div className="popover-wrap">
                            <div
                              style={{
                                height: "400px",
                                overflowY: "auto",
                                overflowX: "hidden",
                              }}
                            >
                              {storeList.map((ele, index) => {
                                return (
                                  (ele.city === city || city === "") && (
                                    <div
                                      className="storeElement"
                                      key={index}
                                      onClick={() => {
                                        setChangeStoreTab(true);
                                        setStore(ele._id);
                                      }}
                                      style={{
                                        backgroundColor:
                                          ele?.show === true
                                            ? "white"
                                            : "#ff6b6b",
                                      }}
                                    >
                                      <div style={{ fontWeight: 700 }}>
                                        {(ele?.name).toUpperCase()}
                                      </div>
                                      <div>{ele?.address}</div>
                                      <div>{ele?.phone}</div>
                                    </div>
                                  )
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="edit-row">
                        <div className="edit-label edit-title"></div>
                        <div className="edit-main">
                          <div className="popover-wrap">
                            <div className="product-edit-form-item-content">
                              <div style={{ lineHeight: "0px" }}>
                                <div style={{ marginBottom: "50px" }}>
                                  <button
                                    className="site-button orange-button "
                                    onClick={() => {
                                      setAddStoreTab(true);
                                    }}
                                  >
                                    Thêm cửa hàng
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
}

export default StoreList;
