import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";

function AddNews({ setAddNewsTab, setTrigger, newsState, news }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;

  const [name, setName] = useState(news?.name ?? "");
  const [seoLink, setSeoLink] = useState(news?.seoLink ?? "");
  const [content, setContent] = useState(news?.content ?? "");
  const [mainImage, setMainImage] = useState(news?.mainImage ?? null);
  
  const onDescriptionChange = (event) => {
    setContent(event.editor.getData());
  };

  const [show, setShow] = useState(news?.show ?? true);

  const createNews = async () => {
    if (name === "") {
      alert("Điền tên bài đăng");
      return;
    }
    if (seoLink === "") {
      alert("Điền SEO link");
      return;
    }
  
    try {
      let img = "";
  
      if (typeof mainImage !== "string" || !mainImage || mainImage !== null || mainImage !== undefined) {
        const formData = new FormData();
        formData.append("img", mainImage);
  
        const response = await axios.post(
          `${apiURL}/product/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (response.data.success === true) {
          img = response.data.result.Key ?? "";
        } else {
          throw new Error("Lỗi");
        }
      } else {
        throw new Error("Hãy chọn ảnh");
      }
  
      const res = await fetch(`${apiURL}/news`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (localStorage.banyanAdminToken || ""),
        },
        body: JSON.stringify({
          name,
          content,
          show,
          mainImage: img,
          seoLink
        }),
      });
  
      if (res.ok) {
        const result = await res.json();
        alert(result.mes);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const updateNews = async () => {
    if (name === "") {
      alert("Điền tên bài đăng");
      return;
    }
  
    try {
      let img = "";
  
      if (typeof mainImage !== "string") {
        const formData = new FormData();
        formData.append("img", mainImage);
  
        const response = await axios.post(
          `${apiURL}/product/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (response.data.success === true) {
          img = response.data.result.Key ?? "";
        } else {
          throw new Error("Lỗi");
        }
      }else{
        img = mainImage
      }
  
      const res = await fetch(`${apiURL}/news`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (localStorage.banyanAdminToken || ""),
        },
        body: JSON.stringify({
          id : news?._id ?? null,
          update : {
            name,
            content,
            show,
            mainImage: img,
            seoLink
          }
        }),
      });
  
      if (res.ok) {
        const result = await res.json();
        alert(result.mes);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };
  

  return (
    <React.Fragment>
      <div className="toggleTab">
        <div
          className="childTab"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            width: "70%",
            maxWidth: "none",
            height : "90%",
            maxHeight : "90%",
            overflow : "auto"
          }}
        >
          <div>
            <h3 className="vodal-title ">{`${
              newsState === 1 ? `Thêm bài viết` : `Sửa bài viết`
            }`}</h3>

            <div className="edit-row">
              <div className="edit-label edit-title">
                <span></span>
              </div>
              <div className="edit-main">
                <div className="popover-wrap">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={show}
                          onChange={(e) => {
                            return setShow(e.target.checked);
                          }}
                        />
                      }
                      label="Hiện bài"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <label className="label-input-group">Ảnh</label>
            <div className={`edit-row `}>
              <div className="edit-main">
                <input
                  style={{ marginBottom: "10px" }}
                  type="file"
                  accept="image/*" // Để chỉ cho phép chọn các tập tin ảnh
                  onChange={(e) => {
                    if (Array.from(e.target.files).length === 1) {
                      setMainImage(e.target.files[0]);
                    } else {
                      setMainImage(news?.mainImage ?? null);
                    }
                  }}
                />

                {mainImage !== "" && (mainImage && mainImage !== null&& mainImage !== undefined) && (
                  <div
                    className="edit-main shopee-image-manager"
                    style={{
                      display: "inline-block",
                      width: "auto!important",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        className="img_box_dis_auto"
                        style={{ width: "100px" }}
                      >
                        <div className="img_each_create_auto">
                          <img
                            className="img"
                            src={
                              typeof mainImage === "string"
                                ? `${cloudFrontURL}/${mainImage}`
                                : URL.createObjectURL(mainImage)
                            }
                            alt="none"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="form-group px-0 mgt20">
              <span
                className="remove_link remove-cart"
                style={{ cursor: "pointer" }}
              ></span>
              <label className="label-input-group">Tên bài viết</label>
              <div className="buttonDiv">
                <input
                  value={name}
                  className="next-input"
                  placeholder="Nhập tên"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group px-0 mgt20">
              <span
                className="remove_link remove-cart"
                style={{ cursor: "pointer" }}
              ></span>
              <label className="label-input-group">SEO link</label>
              <div className="buttonDiv">
                <input
                  value={seoLink}
                  disabled = {newsState === 2}
                  className="next-input"
                  placeholder="Nhập SEO link"
                  onChange={(e) => {
                    setSeoLink(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group px-0 mgt20">
              <span
                className="remove_link remove-cart"
                style={{ cursor: "pointer" }}
              ></span>
              <label className="label-input-group">Nội dung (mã html)</label>
              <div className="buttonDiv">
                {(
                  <div className="edit-main" style = {{maxWidth:"none", width : "100%"}}>
                    <div className="popover-wrap">
                      <div style={{ lineHeight: "40px" }}>
                        <>
                          <CKEditor
                            initData={(news._id) ? (news?.content ?? "") : content}
                            data={content}
                            onChange={onDescriptionChange}
                          />
                        </>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="right-button">
              <button
                className="btn btn-primary text-right"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    window.confirm(
                      `Xác nhận ${newsState === 1 ? `thêm` : `sửa`} bài viết?`
                    )
                  ) {
                    if (newsState === 1) {
                      createNews();
                    } else {
                      updateNews();
                    }
                  }
                }}
              >
                {`${newsState === 1 ? `Thêm` : `Cập nhật`}`}
              </button>
              <button
                className="btn btn-primary text-right"
                style={{ marginLeft: "20px" }}
                onClick={(e) => {
                  setAddNewsTab(false);
                  setTrigger((prev) => {
                    return !prev;
                  });
                }}
              >
                Đóng
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddNews;
