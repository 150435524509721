import React, { useEffect, useState } from "react";
import Api from '../api/apis';
import * as XLSX from "xlsx";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";

function ForHerTab({ setForHerTab }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  const [channelInfo, setChannelInfo] = useState({});
  const [focus, setFocus] = useState({});
  const [line, setSku] = useState("");
  
  const [temp, setTemp] = useState({});
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let set = true;

    const fetchData = async () => {
      try {
        let rs = {};
        rs = { ...(await Api.findChannel("American Tourister")) };
        if (set && rs.success) {
          setChannelInfo(rs.channel);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      set = false;
    };
  }, []);

  useEffect(() => {
    if(channelInfo._id){
      const objectFromArr = {};
      for (const item of (channelInfo.forHer ?? [])) {
          objectFromArr[item] = 1;
      }
      setTemp(objectFromArr)
    }
  }, [channelInfo]);

  const updateChannel = (async()=>{
    try {
        let rs = {};
        rs = { ...(await Api.updateChannelByName(channelInfo.name??"", {...channelInfo, forHer : Object.keys(temp)})) };
        if ( rs.success) {
            alert(rs.message)
        }else{
            alert(rs.message)
        }
      } catch (error) {
        console.log(error);
      }
  })

  const handleFocus = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: true,
    }));
  };


  const handleBlur = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: false,
    }));
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const dataArr = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const newObj = {};
      dataArr.length > 0 &&
        dataArr.map((ele, index) => {
          if (ele[0]) {
              newObj[ele[0]] = 1;
          }
        });
      setTemp(newObj);
    };

    reader.readAsBinaryString(file);
  };

  
  const columns = [
    {
      field: "line",
      headerName: "Line",
      width: 150,
      cellClassName: "centered-cell",
    },
    {
      field: "iconCell",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon
          color="secondary"
          onClick={() => {
            const newObj = { ...temp };
            delete newObj[params.row.line];
            setTemp(newObj);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    const newArray = [];
    Object.keys(temp).map((ele, index) => {
      newArray.push({ id: index, line: ele });
    });
    setRows(newArray);
  }, [temp]);

  return (
    <React.Fragment>
      <div
        className="toggleTab"
        onClick={(e) => {
          setForHerTab(false);
        }}
      >
        <div
          style={{
            maxHeight: "90%",
            overflowY: "auto",
            height: "80%",
            width: "50%",
            maxWidth: "none",
          }}
          className="childTab"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <h3 className="vodal-title ">For Her</h3>
            <div className="edit-row">
                      <div style={{justifyContent:'left',marginTop:'15px'}}>
                        <span> https://americantourister.vn/for-her</span>
                      </div>
                      <div className="edit-label edit-title" style={{justifyContent:'left',marginTop:'15px'}}>
                        <span>Thêm thủ công</span>
                      </div>
                      <div className="" style={{justifyContent:'left' }}>
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.skutag ? "focused" : ""
                                }`}
                                style={{ width: "200px", marginRight: "15px" }}
                              >
                                <input
                                  type="text"
                                  value={line}
                                  placeholder="Line"
                                  onChange={(e) => {
                                    setSku(e.target.value);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("skutag")}
                                  onBlur={() => handleBlur("skutag")}
                                />
                              </div>
                            </div>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                if (line === "") {
                                  alert("Chưa nhập line");
                                  return;
                                } else {
                                  const newObj = { ...temp, [line]: 1 };
                                  setTemp({...newObj})
                                }
                              }}
                              style={{ cursor: "pointer", color: "#2673dd" }}
                            >
                              Thêm
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title" style={{justifyContent:'left' }}>
                        <span>Nhập file</span>
                      </div>
                      <div className="">
                        <div className="popover-wrap">
                          <div className="variation-edit-item">
                            <div className="variation-edit-panel">
                              <div className="variation-edit-">
                                File xls gồm 1 cột: Line sản phẩm cột A, bắt đầu từ dòng 1
                              </div>
                            </div>

                            <div className="product-edit-form-item-content">
                              <div style={{ marginBottom: "15px" }}>
                                <input
                                  type="file"
                                  onChange={handleFileChange}
                                  accept=".xlsx, .xls"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title" style={{justifyContent:'left' }}>
                        <span>Chi tiết giá</span>
                      </div>
                      <div className="">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            
                          <div>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => {
                                    // Tạo một mảng chứa dữ liệu từ object
                                    const data = Object.entries(temp);
                                
                                    // Tạo một workbook mới
                                    const wb = XLSX.utils.book_new();
                                
                                    // Tạo một worksheet từ dữ liệu
                                    const ws = XLSX.utils.aoa_to_sheet(data);
                                
                                    // Thêm worksheet vào workbook
                                    XLSX.utils.book_append_sheet(wb, ws, "Data");
                                
                                    // Thiết lập tên file
                                    const filename = "data.xlsx";
                                
                                    // Lưu workbook thành file Excel
                                    XLSX.writeFile(wb, filename);
                                }}
                                
                                  style={{marginBottom:"10px"}}
                                >
                                  Xuất file
                                </button>
                              </div>
                            <div style={{ lineHeight: "0px" }}>
                              {/*  */}

                              <div style={{ height: 400, width: "100%" }}>
                                <DataGrid
                                  rows={rows}
                                  columns={columns}
                                  disableSelectionOnClick
                                  onRowClick={(e) => {
                                    setSku(e.row.line);
                                  }}
                                  initialState={{
                                    pagination: {
                                      paginationModel: { page: 0, pageSize: 5 },
                                    },
                                  }}
                                  pageSizeOptions={[5, 10]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => updateChannel()}
                                >
                                  Cập nhật
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForHerTab;
