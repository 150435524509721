import React, { useEffect, useState } from "react";
import Api from "../../api/apis";
import { CKEditor } from "ckeditor4-react";

function PaymentMethod({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [channelInfo, setChannelInfo] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [COD, setCOD] = useState("");
  const [banking, setBanking] = useState("");
  const [onePay, setOnePay] = useState("");
  const [payoo, setPayoo] = useState("");

  useEffect(() => {
    setSelect("paymentmethod");
    let set = true;

    const fetchData = async () => {
      try {
        let rs = {};
        rs = { ...(await Api.findChannel("American Tourister")) };
        if (set && rs.success) {
          setChannelInfo(rs.channel);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      set = false;
    };
  }, [trigger]);

  useEffect(() => {
    setCOD(channelInfo?.paymentDesc?.["COD"] ?? "");
    setBanking(channelInfo?.paymentDesc?.["banking"] ?? "");
    setOnePay(channelInfo?.paymentDesc?.["onePay"] ?? "");
    setPayoo(channelInfo?.paymentDesc?.["payoo"] ?? "");
  }, [channelInfo]);

  const updateChannel = async () => {
    try {
      const newChannel = JSON.parse(JSON.stringify(channelInfo));
      let paymentDesc = { COD, banking, onePay, payoo };
      newChannel.paymentDesc = paymentDesc;
      let rs = {};
      rs = {
        ...(await Api.updateChannelByName(channelInfo.name ?? "", newChannel)),
      };
      if (rs.success) {
        alert(rs.message);
        setTrigger((prev) => {
          return prev + 1;
        });
      } else {
        alert(rs.message);
      }
    } catch (error) {
      alert(error.message ?? "Có lỗi xảy ra, thử lại");
      console.log(error);
      return;
    }
  };

  return (
    <React.Fragment>
      <div className="page">
        <div className="edit_container">
          <section className="edit_section" style={{ marginBottom: "130px" }}>
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Phương thức thanh toán</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Ship COD</span>
                      </div>
                      {channelInfo && channelInfo._id && (
                        <div className="edit-main">
                          <div className="popover-wrap">
                            <div style={{ lineHeight: "40px" }}>
                              <>
                                <CKEditor
                                  initData={
                                    channelInfo?.paymentDesc?.["COD"] ?? ""
                                  }
                                  data={COD}
                                  config={{
                                    height: "200px",
                                  }}
                                  onChange={(e) => {
                                    setCOD(e.editor.getData());
                                  }}
                                />
                              </>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Chuyển khoản</span>
                      </div>
                      {channelInfo && channelInfo._id && (
                        <div className="edit-main">
                          <div className="popover-wrap">
                            <div style={{ lineHeight: "40px" }}>
                              <>
                                <CKEditor
                                  initData={
                                    channelInfo?.paymentDesc?.["banking"] ?? ""
                                  }
                                  data={banking}
                                  config={{
                                    height: "200px",
                                  }}
                                  onChange={(e) => {
                                    setBanking(e.editor.getData());
                                  }}
                                />
                              </>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>OnePay</span>
                      </div>

                      {channelInfo && channelInfo._id && (
                        <div className="edit-main">
                          <div className="popover-wrap">
                            <div style={{ lineHeight: "40px" }}>
                              <>
                                <CKEditor
                                  initData={
                                    channelInfo?.paymentDesc?.["onePay"] ?? ""
                                  }
                                  data={onePay}
                                  config={{
                                    height: "200px",
                                  }}
                                  onChange={(e) => {
                                    setOnePay(e.editor.getData());
                                  }}
                                />
                              </>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Payoo</span>
                      </div>

                      {channelInfo && channelInfo._id && (
                        <div className="edit-main">
                          <div className="popover-wrap">
                            <div style={{ lineHeight: "40px" }}>
                              <>
                                <CKEditor
                                  initData={
                                    channelInfo?.paymentDesc?.["payoo"] ?? ""
                                  }
                                  data={onePay}
                                  config={{
                                    height: "200px",
                                  }}
                                  onChange={(e) => {
                                    setPayoo(e.editor.getData());
                                  }}
                                />
                              </>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => {
                                    if (window.confirm("Xác nhận cập nhật?")) {
                                      updateChannel();
                                    }
                                  }}
                                >
                                  Cập nhật
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PaymentMethod;
