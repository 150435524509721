import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { moneyFormat, dateFormat } from "../../helper";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Stack from "@mui/material/Stack";
import CustomerInvoice from "../../components/CustomerInvoice";

function Customer({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [memberList, setMemberList] = useState([]);

  //filter
  const [isCreateTime, setIsCreateTime] = useState(true);
  const [CreateTimeFrom, setCreateTimeFrom] = useState(new Date(2024, 0, 1));
  const [CreateTimeTo, setCreateTimeTo] = useState(new Date());
  const [search, setSearch] = useState("");


  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [trigger, setTrigger] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const [customerInvoiceTab, setCustomerInvoiceTab] = useState(false)
  const [phone, setPhone] = useState("")

  useEffect(() => {
    let set = true;
    let filter = { page, search };
    if (isCreateTime) {
      filter.isCreateTime = isCreateTime;
      filter.CreateTimeFrom = CreateTimeFrom;
      filter.CreateTimeTo = CreateTimeTo;
    }
    
    fetch(`${apiURL}/employee/search/customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify(filter),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success && set) {
          setTotalCount(
            result.memberList[0].memberList.length > 0
              ? result.memberList[0].count[0]["count"]
              : 0
          );
          setMemberList(
            result.memberList[0].memberList.length > 0
              ? result.memberList[0].memberList
              : []
          );
          setMaxPage(
            result.memberList[0].memberList.length > 0
              ? Math.ceil(result.memberList[0].count[0]["count"] / 10)
              : 0
          );
        } else {
        }
      });
    return () => {
      set = false;
    };
  }, [page, trigger]);

  useEffect(() => {
    setPage(1);
    setTrigger((prev) => {
      return !prev;
    });
  }, [isCreateTime, CreateTimeFrom, CreateTimeTo, search]);

  useEffect(() => {
    setSelect("customer");
  }, []);

  return (
    <React.Fragment>
      <div className="AdminTab">
        <h1>Khách hàng</h1>

        <span className="css-1f0mdg1 mgb20 inline"></span>
        <div className="flexRow mgb50">
          <div className="unitBox w100 mr20">
            <div className="headerBox">Khách hàng</div>
            <div className="contentBox">
              <div>
                <div className="">
                <div className="form-group px-0 inline mr20">
                  <div className="inline">
                    <label className="label-input-group">Số điện thoại</label>
                    <div className="buttonDiv">
                      <input
                        className="next-input numInput"
                        onBlur={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                  <div className=" px-0 inline">

                    <div className="form-group px-0 inline ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDateTimePicker
                            views={["month", "day", "hours", "minutes"]}
                            inputFormat="DD/MM/YYYY HH:mm"
                            label="Bắt đầu từ"
                            value={CreateTimeFrom}
                            onChange={(newValue) => {
                              setCreateTimeFrom(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                style={{ width: "200px" }}
                                {...params}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    <div className="form-group px-0 inline ml20">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDateTimePicker
                            views={["month", "day", "hours", "minutes"]}
                            inputFormat="DD/MM/YYYY HH:mm"
                            label="Đến"
                            value={CreateTimeTo}
                            onChange={(newValue) => {
                              setCreateTimeTo(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                style={{ width: "200px" }}
                                {...params}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </div>

                </div>
              </div>
              <div className="position-relative">
                <div className="table-list--config">
                  <div className="ui-table-normal-container">
                    <table className="ui-table">
                      <thead>
                        <tr>
                          <th className="">
                            <span>Số điện thoại</span>
                          </th>
                          <th className="" >
                            <span>Tổng đơn thành công</span>
                          </th>
                          <th className="">
                            <span>Doanh thu tích lũy</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {memberList &&
                          memberList.map((ele, index) => {
                            return (
                              <tr
                                style={{
                                  borderBottom: "1px solid #eee",
                                }}
                                key={index}
                              >
                                <td
                                  className="align-top white-space-normal"
                                >
                                  {ele?._id??""}
                                </td>
                                <td
                                  className={`align-top pt-3 `}
                                >
                                  {ele?.totalOrders??0}
                                </td>
                                <td className="align-top pt-3 green" >
                                  {moneyFormat(ele?.totalIncome??0)}
                                </td>
                                <td className="align-top pt-3">
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    icon="bx:right-arrow"
                                    onClick={(e) => {
                                      if(ele._id !== ""){
                                        setPhone(ele._id)
                                        setCustomerInvoiceTab(true)
                                      }
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div>
                <div className="row no-gutters justify-content-between paginate">
                  <div className="col-auto "></div>
                  <div className="col-auto ">
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto mr-3">
                        <span
                          style={{ color: "rgb(128, 128, 128)" }}
                        >{`Tổng: ${totalCount}`}</span>
                      </div>
                      <div className="col">
                        <ul className="pagination pagination-lg justify-content-center">
                          {page > 2 && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) => setPage(1)}
                            >
                              <div className="page-link">
                                <Icon icon="material-symbols:first-page" />
                              </div>
                            </li>
                          )}
                          {page > 1 && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) =>
                                setPage((prev) => {
                                  return prev - 1;
                                })
                              }
                            >
                              <div className="page-link">{page - 1}</div>
                            </li>
                          )}
                          <li className="page-item hidden-mobile active">
                            <div className="page-link">{page}</div>
                          </li>
                          {page < maxPage && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) =>
                                setPage((prev) => {
                                  return prev + 1;
                                })
                              }
                            >
                              <div className="page-link">{page + 1}</div>
                            </li>
                          )}
                          {page < maxPage - 1 && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) => setPage(maxPage)}
                            >
                              <div className="page-link">
                                <Icon icon="material-symbols:last-page" />
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        customerInvoiceTab === true && <CustomerInvoice setCustomerInvoiceTab = {setCustomerInvoiceTab} phone = {phone}></CustomerInvoice>
      }
    </React.Fragment>
  );
}

export default Customer;
