import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import * as XLSX from "xlsx";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import ChangeRemain from "../../components/product/ChangeRemain";
import CachingTab from "../../components/CachingTab";
import HotDealTab from "../../components/HotDealTab";
import ForHerTab from "../../components/ForHerTab";

function AllProduct({ setSelect, user }) {
  const colorList = {
    Nude: "Be",
    Black: "Đen",
    Blue: "Xanh dương",
    Green: "Xanh lá",
    Grey: "Xám",
    "Light Blue": "Xanh nhạt",
    Navy: "Xanh đen",
    Orange: "Cam",
    Pink: "Hồng",
    Purple: "Tím",
    Red: "Đỏ",
    Silver: "Bạc",
    White: "Trắng",
    Yellow: "Vàng",
    Brown: "Nâu",
  };
  const [changeRemainTab, setChangeRemainTab] = useState(false);

  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  //filter vars here
  const [tag, setTag] = useState("");
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(0);
  const [type, setType] = useState(0);

  //pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [trigger, setTrigger] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const itemPerPage = 10;

  const [discountList, setDiscountList] = useState({});
  const [cachingTab, setCachingTab] = useState(false);
  const [hotDealTab, setHotDealTab] = useState(false);
  const [forHerTab, setForHerTab] = useState(false);

  const getDiscount = async () => {
    let priceInfo = {};

    try {
      const res = await fetch(`${apiURL}/discount/at`, {
        method: "GET",
      });
      if (res.ok) {
        const result = await res.json();
        if (result.success) {
          priceInfo = { ...result.priceInfo };
        }
      } else {
        throw new Error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.log(error);
    }

    return priceInfo;
  };

  const deleteProduct = async (id) => {
    fetch(`${apiURL}/product`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({ id }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setTrigger((prev) => {
            return !prev;
          });
        } else {
          alert("Có lỗi");
        }
      });
  };

  useEffect(() => {
    let set = true;
    let filter = { page, itemPerPage };
    if (search !== "") {
      filter["search"] = search;
    }
    if (tag !== "") {
      filter["categoryIndex"] = tag;
    }
    if (show === 1) {
      filter["show"] = true;
    }
    if (show === 2) {
      filter["show"] = false;
    }
    if (type !== 0) {
      filter["productType"] = type;
    }

    fetch(`${apiURL}/product/findfilter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify(filter),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success && set) {
          setTotalCount(
            result.productList[0].productList.length > 0
              ? result.productList[0].count[0]["count"]
              : 0
          );
          setProductList(
            result.productList[0].productList.length > 0
              ? result.productList[0].productList
              : []
          );
          setMaxPage(
            result.productList[0].productList.length > 0
              ? Math.ceil(result.productList[0].count[0]["count"] / itemPerPage)
              : 0
          );
        } else {
        }
      });

    let data = {};
    const fetchData = async () => {
      try {
        data = { ...(await getDiscount()) };
        setDiscountList(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      set = false;
    };
  }, [page, trigger]);

  useEffect(() => {
    setPage(1);
    setTrigger((prev) => {
      return !prev;
    });
  }, [search, tag, show, type]);

  const exportFile = async (mode) => {
    let filter = { itemPerPage };
    if (search !== "") {
      filter["search"] = search;
    }
    if (tag !== "") {
      filter["categoryIndex"] = tag;
    }
    if (show === 1) {
      filter["show"] = true;
    }
    if (show === 2) {
      filter["show"] = false;
    }
    if (type !== 0) {
      filter["productType"] = type;
    }
    let pArray = new Array(maxPage).fill([])
    if (maxPage > 0) {
      const promises = pArray.map(async (ele, index) => {
        filter.page = index + 1
        try {
          const response = await fetch(`${apiURL}/product/findfilter`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.banyanAdminToken
                ? `Bearer ${localStorage.banyanAdminToken}`
                : "",
            },
            body: JSON.stringify(filter),
          });
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          const result = await response.json();
          if (result.success) {
            return result.productList[0].productList.length > 0
              ? result.productList[0].productList
              : [];
          } else {
            throw new Error("Failed to fetch product data");
          }
        } catch (error) {
          console.error("Error fetching product data:", error);
          return [];
        }
      });
    
      try {
        const productArray = await Promise.all(promises);
        const arr = productArray.flatMap(items => items);
        if (arr.length > 0) {
          var data = [];
          if (mode === 1) {
            data = [
              [
                "Url",
                "Tên",
                "Mô tả",
                "Hãng",
                "Loại sản phẩm",
                "Hiển thị",
                "Thuộc tính",
                "Giá trị thuộc tính 1",
                "Thuộc tính",
                "Giá trị thuộc tính 2",
                "SKU",
                "Số lượng",
                "Giá",
                "Link hình",
                "Tất cả hình",
              ], // Định nghĩa các cột
            ];
            arr.map((ele, index) => {
              (ele?.combine ?? []).map((ele1, index1) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(
                  ele?.description ?? "",
                  "text/html"
                );
                const imgList = ele1?.imgList ?? [];
                data.push([
                  ele?.SEOLink ?? "",
                  ele?.name ?? "",
                  doc.body.textContent.replace(/\n/g, " "),
                  "American Tourister",
                  Number(ele?.productType === 1)
                    ? "Vali"
                    : Number(ele?.productType === 2)
                    ? "Balo"
                    : Number(ele?.productType === 3)
                    ? "Túi xách"
                    : Number(ele?.productType === 4)
                    ? "Phụ kiện"
                    : "",
                  (ele1?.show ?? false === true) &&
                  (ele1?.showAll ?? false) === true
                    ? "YES"
                    : "NO",
                  "Size",
                  ele1?.sizeDesc ?? "",
                  "Color",
                  colorList[
                    ele?.colorArray[Number(ele1?.color ?? 0)] ?? ""
                  ] ?? "",
                  ele1?.sku ?? "",
                  ele1?.remain ?? 0,
                  ele1?.price ?? 0 + " VND",
                  cloudFrontURL + "/" + ele1?.img[0] ?? "",
                  (imgList.length >= 2 ? imgList.slice(1) : imgList)
                    .map((item) => cloudFrontURL + "/" + item)
                    .join(","),
                ]);
              });
            });
          } else if (mode === 2) {
            data = [
              [
                "Id",
                "title",
                "description",
                "product_type",
                "link",
                "image_link",
                "all_image_link",
                "condition",
                "availability",
                "price",
                "sale_price",
                "brand",
                "identifier_exists",
                "shipping",
                "google_product_category",
              ], // Định nghĩa các cột
            ];
            arr.map((ele, index) => {
              (ele?.combine ?? []).map((ele1, index1) => {
                const removeArr = "+=-_!@#$%^&*()~`:;|\\/?.,><";
                const charSet = new Set(removeArr.split(""));
                const parser = new DOMParser();
                const doc = parser.parseFromString(
                  ele?.description ?? "",
                  "text/html"
                );
                const imgList = ele1?.imgList ?? [];
                data.push([
                  // `${ele?.SEOLink ?? ""}?color=${ele1.color ?? 0}&size=${
                  //   ele1.size ?? 0
                  // }`
                  //   .split("")
                  //   .filter((char) => !charSet.has(char))
                  //   .join(""),
                  (ele1?.sku) ?? "",
                  ele?.name ?? "",
                  doc.body.textContent.replace(/\n/g, " "),
                  Number(ele?.productType === 1)
                    ? "Vali"
                    : Number(ele?.productType === 2)
                    ? "Balo"
                    : Number(ele?.productType === 3)
                    ? "Túi xách"
                    : Number(ele?.productType === 4)
                    ? "Phụ kiện"
                    : "",
                  `https://americantourister.vn/p/${
                    ele?.SEOLink ?? ""
                  }?color=${ele1.color ?? 0}&size=${ele1.size ?? 0}`,
                  cloudFrontURL + "/" + ele1?.img[0] ?? "",
                  (imgList.length >= 2 ? imgList.slice(1) : imgList)
                    .map((item) => cloudFrontURL + "/" + item)
                    .join(","),
                  "new",
                  (ele1?.remain ?? 0) > 0 ? "in stock" : "out stock",
                  ele1?.price ?? 0 + " VND",
                  Math.round( (discountList[ele1?.sku] ?? (ele1?.price ?? 0)) )+ " VND",
                  "American Tourister",
                  "FALSE",
                  ":::0 VND",
                  "Business & Industrial > Retail",
                ]);
              });
            });
          }

          convertDataToExcel(data, mode);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    } 
  };

  function convertDataToExcel(arr, mode) {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(arr); //array of array

    XLSX.utils.book_append_sheet(wb, ws, "Sanpham");
    ws["!cols"] = fitToColumn(arr);
    ws["!cols"][2] = { wch: 30 };
    if (mode === 1) {
      XLSX.writeFile(wb, "Sanpham.xlsx");
    }
    if (mode === 2) {
      ws["!cols"][6] = { wch: 80 };
      XLSX.writeFile(wb, "GoogleAds.xlsx");
    }
  }

  function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({
      wch: Math.max(
        ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
      ),
    }));
  }

  useEffect(() => {
    setSelect("allproduct");

    fetch(`${apiURL}/product/getchanneldetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        type: "groupcategory",
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setCategoryList(result.list);
        }
      });
  }, []);

  return (
    user&&(user?._id??"")!==""&&<React.Fragment>
      {cachingTab&& (
        <CachingTab
          setCachingTab={setCachingTab}
        />
      )}
      {hotDealTab&& (
        <HotDealTab
          setHotDealTab={setHotDealTab}
        />
      )}
      {forHerTab&& (
        <ForHerTab
          setForHerTab={setForHerTab}
        />
      )}
      <div className="AdminTab allProduct">
        <h1>Sản phẩm</h1>
        <div className="unitBox w100" style={{ height: "auto" }}>
          <div className="headerBox">Danh sách sản phẩm</div>
          <div className="contentBox">
            <div className="form-group px-0 inline mr20">
              <div className="inline">
                <label className="label-input-group">Từ khóa</label>
                <div className="buttonDiv">
                  <input
                    className="next-input numInput"
                    onBlur={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group px-0 inline">
              <div className="inline">
                <label className="label-input-group">Loại hàng</label>
                <div className="">
                  <select
                    className="next-input"
                    style={{
                      width: "200px",
                      marginRight: "20px",
                      marginBottom: "5px",
                      marginLeft: "0",
                    }}
                    onChange={(e) => {
                      setType(Number(e.target.value));
                    }}
                  >
                    {["Tất cả", "Vali", "Balo", "Túi xách", "Phụ kiện"].map(
                      (ele, index) => {
                        return (
                          <option key={index} value={index}>
                            {ele}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group px-0 inline">
              <div className="inline">
                <label className="label-input-group">Trạng thái</label>
                <div className="">
                  <select
                    className="next-input"
                    style={{
                      width: "200px",
                      marginRight: "20px",
                      marginBottom: "5px",
                      marginLeft: "0",
                    }}
                    onChange={(e) => {
                      setShow(Number(e.target.value));
                    }}
                  >
                    {["Tất cả", "Đang bán", "Đã ẩn"].map((ele, index) => {
                      return (
                        <option key={index} value={index}>
                          {ele}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group px-0 inline">
              <div className="inline">
                <label className="label-input-group">Nhóm sản phẩm</label>
                <div className="">
                  <select
                    className="next-input"
                    style={{
                      width: "200px",
                      marginRight: "20px",
                      marginBottom: "5px",
                      marginLeft: "0",
                    }}
                    onChange={(e) => {
                      setTag(e.target.value);
                    }}
                  >
                    <option value={""}>Tất cả</option>
                    {categoryList && (categoryList.sort((a, b) => a.name.localeCompare(b.name)).filter((ele)=>{return ele.type !== 2})).map((ele, index) => {
                      return (
                        <option key={index} value={ele["_id"]}>
                          {ele["name"]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            {user&&user.role === 1&&<Button
              variant="contained"
              style={{
                marginLeft: 20,
                textTransform: "none",
                fontSize: "14px",
              }}
              onClick={(e) => {
                setChangeRemainTab(true)
              }}
            >
              Nhập tồn
            </Button>}
            <Button
              variant="contained"
              style={{
                marginLeft: 20,
                textTransform: "none",
                fontSize: "14px",
              }}
              onClick={(e) => {
                exportFile(1);
              }}
            >
              Xuất sản phẩm
            </Button>
            <Button
              variant="contained"
              style={{
                marginLeft: 20,
                textTransform: "none",
                fontSize: "14px",
              }}
              onClick={(e) => {
                exportFile(2);
              }}
            >
              Google file
            </Button>
            {/* <Button
              variant="contained"
              style={{
                marginLeft: 20,
                textTransform: "none",
                fontSize: "14px",
              }}
              onClick={(e) => {
                setCachingTab(true)
              }}
            >
              Caching
            </Button> */}
            <Button
              variant="contained"
              style={{
                marginLeft: 20,
                textTransform: "none",
                fontSize: "14px",
              }}
              onClick={(e) => {
                setHotDealTab(true)
              }}
            >
              Hot Deals
            </Button>
            <Button
              variant="contained"
              style={{
                marginLeft: 20,
                textTransform: "none",
                fontSize: "14px",
              }}
              onClick={(e) => {
                setForHerTab(true)
              }}
            >
              For Her
            </Button>
            <div className="position-relative">
              <div className="table-list--config">
                <div className="ui-table-normal-container">
                  <table className="ui-table">
                    <thead>
                      <tr>
                        <th className="" style={{ minWidth: "40px" }}>
                          <span></span>
                        </th>
                        <th className="thName">
                          <span>Sản phẩm</span>
                        </th>
                        <th className="thCode">
                          <span>Mã SEO</span>
                        </th>
                        <th className="thCode">
                          <span>Tồn kho</span>
                        </th>
                        <th className="thCode">
                          <span>Trạng thái</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productList.map((ele, index) => {
                        let sumRemain = 0, countProducts = 0
                        
                        ele.productList.map((e1)=>{
                          e1.map((e2)=>{ 
                            sumRemain+=e2.remain
                            countProducts++
                          })
                        })
                        return (
                          <tr
                            key={ele._id}
                            style={{
                              borderBottomWidth: 2,
                              borderBottomColor: "rgba(128, 128, 128, 0.5)", // 50% opacity
                              maxHeight: 60,
                            }}
                          >
                            <td className="tdName">
                              <div className="row align-items-start flex-nowrap">
                                <div
                                  className="col"
                                  style={{ paddingRight: 0 }}
                                >
                                  <div style={{ maxWidth: "40px" }}>
                                    <img
                                      alt="pImg"
                                      src={`${cloudFrontURL}/${
                                        ele?.productImgListUrl?.[0]?.[0]?.[0] ??
                                        ``
                                      }`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="tdName">
                              <div className="row align-items-start flex-nowrap">
                                <div className="col">
                                  <div className="col max-width-350px ">
                                    <a
                                      className="mb-2  table-break-word black"
                                      style={{
                                        cursor: "pointer",
                                        maxWidth: "100%",
                                        whiteSpace: "normal",
                                        overflow: "hidden",
                                      }}
                                      href={`/allproduct/product?id=${ele._id}`}
                                    >
                                      {`${ele.name ?? ""}`}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="tdName">
                              <div className="row align-items-start flex-nowrap">
                                <div className="col">
                                  <div className="col max-width-350px ">
                                    <div className="mb-2  table-break-word black">
                                      {ele.SEOLink ?? ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="tdName">
                              <div className="row align-items-start flex-nowrap">
                                <div className="col">
                                  <div className="col max-width-350px ">
                                    <div className="mb-2  table-break-word black">  
                                        {`${sumRemain} trong ${countProducts} biến thể`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="tdName">
                              <div className="row align-items-start flex-nowrap">
                                <div className="col">
                                  <div className="col max-width-350px ">
                                    <div
                                      className="mb-2  table-break-word black"
                                      style={{
                                        color:
                                          ele.show === true ? "green" : "red",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {ele.show === true ? "Đang bán" : "Đã ẩn"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            {user&&user.role === 1 && <td className="align-top pt-3">
                              <IconButton
                                onClick={(e) => {
                                  if(user&&user.role === 1){
                                    if (
                                      window.confirm("Xác nhận xóa sản phẩm?")
                                    ) {
                                      deleteProduct(ele._id);
                                    }
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div>
              <div className="row no-gutters justify-content-between paginate">
                <div className="col-auto "></div>
                <div className="col-auto ">
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto mr-3">
                      <span
                        style={{ color: "rgb(128, 128, 128)" }}
                      >{`Tổng: ${totalCount}`}</span>
                    </div>
                    <div className="col">
                      <ul className="pagination pagination-lg justify-content-center">
                        {page > 2 && (
                          <li
                            className="page-item hidden-mobile"
                            onClick={(e) => setPage(1)}
                          >
                            <div className="page-link">
                              <Icon icon="material-symbols:first-page" />
                            </div>
                          </li>
                        )}
                        {page > 1 && (
                          <li
                            className="page-item hidden-mobile"
                            onClick={(e) =>
                              setPage((prev) => {
                                return prev - 1;
                              })
                            }
                          >
                            <div className="page-link">{page - 1}</div>
                          </li>
                        )}
                        <li className="page-item hidden-mobile active">
                          <div className="page-link">{page}</div>
                        </li>
                        {page < maxPage && (
                          <li
                            className="page-item hidden-mobile"
                            onClick={(e) =>
                              setPage((prev) => {
                                return prev + 1;
                              })
                            }
                          >
                            <div className="page-link">{page + 1}</div>
                          </li>
                        )}
                        {page < maxPage - 1 && (
                          <li
                            className="page-item hidden-mobile"
                            onClick={(e) => setPage(maxPage)}
                          >
                            <div className="page-link">
                              <Icon icon="material-symbols:last-page" />
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {changeRemainTab && (
        <ChangeRemain
          setChangeRemainTab={setChangeRemainTab}
          setTrigger={setTrigger}
        />
      )}
    </React.Fragment>
  );
}

export default AllProduct;
