import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import * as XLSX from "xlsx";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function GiftWithPurchase({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [giftWithPurchaseList, setGiftWithPurchaseList] = useState([]);

  //filter vars here
  const [search, setSearch] = useState("");

  //pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [trigger, setTrigger] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    let set = true;
    let filter = { page };
    if (search !== "") {
      filter["search"] = search;
    }

    fetch(`${apiURL}/giftwithpurchase/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify(filter),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success && set) {
          setTotalCount(
            result.giftWithPurchaseList[0].giftWithPurchaseList.length > 0
              ? result.giftWithPurchaseList[0].count[0]["count"]
              : 0
          );
          setGiftWithPurchaseList(
            result.giftWithPurchaseList[0].giftWithPurchaseList.length > 0
              ? result.giftWithPurchaseList[0].giftWithPurchaseList
              : []
          );
          setMaxPage(
            result.giftWithPurchaseList[0].giftWithPurchaseList.length > 0
              ? Math.ceil(result.giftWithPurchaseList[0].count[0]["count"] / 20)
              : 0
          );
        } else {
        }
      });
    return () => {
      set = false;
    };
  }, [page, trigger]);

  useEffect(() => {
    setPage(1);
    setTrigger((prev) => {
      return !prev;
    });
  }, [search]);

  useEffect(() => {
    setSelect("giftwithpurchase");
  }, []);

  
  const deleteGWP = (_id) => {
    fetch(`${apiURL}/giftwithpurchase/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if(result.success){
          alert('Xóa chương trình thành công')
          setTrigger((prev)=>{return !prev})
        }else{
          alert('Có lỗi')
        }
      });
};

  return (
    <React.Fragment>
      <div className="AdminTab allProduct">
        <div className="unitBox w100">
          <div className="headerBox">Danh sách chương trình</div>
          <div className="contentBox">
            <div className="form-group px-0 inline mr20">
              <div className="inline">
                <label className="label-input-group">Từ khóa</label>
                <div className="buttonDiv">
                  <input
                    className="next-input numInput"
                    onBlur={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <button
              className="site-button orange-button "
              onClick={(e) => {
                window.open("/giftwithpurchase/giftwithpurchaseinfo?id=new", "_self");
              }}
            >
              Tạo chương trình
            </button>
            <div className="position-relative">
              <div className="table-list--config">
                <div className="ui-table-normal-container">
                  <table className="ui-table">
                    <thead>
                      <tr>
                        <th className="thName">
                          <span>Mã chương trình</span>
                        </th>
                        <th className="thCode">
                          <span>Tên chương trình</span>
                        </th>
                        <th className="thCode">
                          <span>Loại chương trình</span>
                        </th>
                        <th className="thCode">
                          <span>Trạng thái</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {giftWithPurchaseList.map((ele, index) => {
                        return (
                          <tr key={ele._id}>
                            <td className="tdName">
                              <div className="row align-items-start flex-nowrap">
                                <div className="col">
                                  <div className="col max-width-350px ">
                                    <div className="mb-2  table-break-word black">
                                      {`${ele.giftWithPurchaseProgramCode ?? ""}`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="align-top white-space-normal">
                              {ele.name ?? ""}
                            </td>
                            <td className="align-top white-space-normal">
                              {(ele.isCondition && ele.isCondition === true) ? (ele.conditionType === 1? "Số lượng" : "Tổng đơn") : "Toàn bộ"}
                            </td>
                            <td
                              className={`align-top white-space-normal ${
                                ele.show === true ? "green" : "red"
                              }`}
                            >
                              {ele.show === true ? "Hoạt động" : "Dừng"}
                            </td>
                            <td className="align-top pt-3">
                              <Icon
                                style={{
                                  cursor: "pointer",
                                }}
                                icon="bx:right-arrow"
                                onClick={(e) => {
                                  window.open(
                                    `/giftwithpurchase/giftwithpurchaseinfo?id=${ele._id}`
                                  );
                                }}
                              />
                            </td>
                            <td className="align-top pt-3">
                              <IconButton style={{padding:0}}
                                onClick={(e) => {
                                  if (
                                    window.confirm("Xác nhận xóa chương trình?")
                                  ) {
                                    deleteGWP(ele._id);
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div>
              <div className="row no-gutters justify-content-between paginate">
                <div className="col-auto "></div>
                <div className="col-auto ">
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto mr-3">
                      <span
                        style={{ color: "rgb(128, 128, 128)" }}
                      >{`Tổng: ${totalCount}`}</span>
                    </div>
                    <div className="col">
                      <ul className="pagination pagination-lg justify-content-center">
                        {page > 2 && (
                          <li
                            className="page-item hidden-mobile"
                            onClick={(e) => setPage(1)}
                          >
                            <div className="page-link">
                              <Icon icon="material-symbols:first-page" />
                            </div>
                          </li>
                        )}
                        {page > 1 && (
                          <li
                            className="page-item hidden-mobile"
                            onClick={(e) =>
                              setPage((prev) => {
                                return prev - 1;
                              })
                            }
                          >
                            <div className="page-link">{page - 1}</div>
                          </li>
                        )}
                        <li className="page-item hidden-mobile active">
                          <div className="page-link">{page}</div>
                        </li>
                        {page < maxPage && (
                          <li
                            className="page-item hidden-mobile"
                            onClick={(e) =>
                              setPage((prev) => {
                                return prev + 1;
                              })
                            }
                          >
                            <div className="page-link">{page + 1}</div>
                          </li>
                        )}
                        {page < maxPage - 1 && (
                          <li
                            className="page-item hidden-mobile"
                            onClick={(e) => setPage(maxPage)}
                          >
                            <div className="page-link">
                              <Icon icon="material-symbols:last-page" />
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GiftWithPurchase;
