import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

function Profile({ user, setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [initUser, setInitUser] = useState({...user})
  useEffect(()=>{
    setInitUser({...user})
    setSelect("employee1")
  },[user])


  const updateAccount = () => {
    try {
        if(initUser.phone === ""){
            throw new Error("Chưa nhập số điện thoại")
        }
        if(((initUser?.newPassword??"") !== "" || (initUser?.newPasswordConfirm??"") !== "") && ((initUser?.newPassword??"")!==(initUser?.newPasswordConfirm??""))){
            throw new Error("Mật khẩu xác nhận không chính xác")
        }

        fetch(apiURL + "/employee", {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + localStorage.banyanAdminToken
                  ? localStorage.banyanAdminToken
                  : "",
            },
            body : JSON.stringify({
                user: initUser
            })
          })
            .then((res) => {
              if (res.ok) return res.json();
              throw new Error("Something went wrong");
            })
            .then((result) => {
                if(result.success){
                    alert("Cập nhật thành công")
                }
            });
    } catch (error) {
      alert(error.message ?? "Có lỗi xảy ra, thử lại");
      console.log(error);
      return;
    }
  };
  return (
    <React.Fragment>
      <div className="page">
        <div className="edit_container">
          <section className="edit_section" style={{ marginBottom: "130px" }}>
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Thông tin tài khoản</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Email</span>
                      </div>

                      <div className="edit-main">
                        <div className="popover-wrap">
                          <Box
                            component="form"
                            size="small"
                            style={{ marginBottom: "10px" }}
                          >
                            <div>
                              <Paper
                                elevation={0}
                                style={{ background: "#ffffff" }}
                              >
                                <TextField
                                  style={{ minWidth: "100%" }}
                                  
                                  size="small"
                                  value={initUser?.email??""}
                                  disabled
                                />
                              </Paper>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Tên</span>
                      </div>

                      <div className="edit-main">
                        <div className="popover-wrap">
                          <Box
                            component="form"
                            size="small"
                            style={{ marginBottom: "10px" }}
                          >
                            <div>
                              <Paper
                                elevation={0}
                                style={{ background: "#ffffff" }}
                              >
                                <TextField
                                  style={{ minWidth: "100%" }}
                                  
                                  size="small"
                                  value={initUser?.name ?? ""}
                                  disabled
                                />
                              </Paper>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Số điện thoại</span>
                      </div>

                      <div className="edit-main">
                        <div className="popover-wrap">
                          <Box
                            component="form"
                            size="small"
                            style={{ marginBottom: "10px" }}
                          >
                            <div>
                              <Paper
                                elevation={0}
                                style={{ background: "#ffffff" }}
                              >
                                <TextField
                                  style={{ minWidth: "100%" }}
                                  
                                  size="small"
                                  value={initUser?.phone ?? ""}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setInitUser({...initUser, phone : e.target.value})
                                  }}
                                />
                              </Paper>
                            </div>
                          </Box>
                        </div>
                      </div> 
                    </div>
                    
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Mật khẩu mới</span>
                      </div>

                      <div className="edit-main">
                        <div className="popover-wrap">
                          <Box
                            component="form"
                            size="small"
                            style={{ marginBottom: "10px" }}
                          >
                            <div>
                              <Paper
                                elevation={0}
                                style={{ background: "#ffffff" }}
                              >
                                <TextField
                                    type = "password"
                                  style={{ minWidth: "100%" }}
                                  
                                  size="small"
                                  value={initUser?.newPassword ?? ""}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setInitUser({...initUser, newPassword : e.target.value})
                                  }}
                                />
                              </Paper>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>

                    
                    
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Xác nhận mật khẩu</span>
                      </div>

                      <div className="edit-main">
                        <div className="popover-wrap">
                          <Box
                            component="form"
                            size="small"
                            style={{ marginBottom: "10px" }}
                          >
                            <div>
                              <Paper
                                elevation={0}
                                style={{ background: "#ffffff" }}
                              >
                                <TextField
                                    type = "password"
                                  style={{ minWidth: "100%" }}
                                  
                                  size="small"
                                  value={initUser?.newPasswordConfirm ?? ""}
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setInitUser({...initUser, newPasswordConfirm : e.target.value})
                                  }}
                                />
                              </Paper>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>


                    
                    <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => {
                                    if (window.confirm("Xác nhận cập nhật?")) {
                                      updateAccount();
                                    }
                                  }}
                                >
                                  Cập nhật
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Profile;
