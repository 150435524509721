import React, { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { moneyFormat, dateFormat } from "../../../helper";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import * as XLSX from "xlsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";

function CreateDiscount({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [temp, setTemp] = useState({});
  const [show, setShow] = useState(true);
  const [focus, setFocus] = useState({});
  const [name, setName] = useState("");
  const [discountProgramCode, setDiscountProgramCode] = useState("");
  const [description, setDescription] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [priceInfo, setPriceInfo] = useState({});
  const [rows, setRows] = useState([]);
  const [sku, setSku] = useState("");
  const [price, setPrice] = useState(0);

  const [isCondition, setIsCondition] = useState(false);
  const [conditionType, setConditionType] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [totalValue, setTotalValue] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const dataArr = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const newObj = {};
      dataArr.length > 0 &&
        dataArr.map((ele, index) => {
          if (ele[0] && ele[1]) {
            if (typeof ele[1] === "number" && ele[1] > 0) {
              newObj[ele[0]] = Math.round(ele[1]);
            }
          }
        });
      setTemp(newObj);
    };

    reader.readAsBinaryString(file);
  };

  const handleFocus = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: true,
    }));
  };

  const createDiscount = (e) => {
    if (
      dateFormat(dateFrom) === "Invalid Date Invalid Date" ||
      dateFormat(dateTo) === "Invalid Date Invalid Date"
    ) {
      alert("Nhập đúng định dạng ngày");
      return;
    }
    if (dateFrom >= dateTo) {
      alert("Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
      return;
    }
    if (name === "") {
      alert("Nhập tên chương trình");
      return;
    }
    if (discountProgramCode === "") {
      alert("Nhập mã chương trình");
      return;
    }

    if (window.confirm("Xác nhận tạo chương trình?")) {
      fetch(`${apiURL}/discount`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({
          dateFrom,
          dateTo,
          name,
          show,
          description,
          discountProgramCode,
          priceInfo,
          isCondition,
          conditionType,
          quantity,
          totalValue,
          discountValue,
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            alert("Tạo chương trình thành công");
          } else {
            alert(result.message ?? "Lỗi");
          }
        });
    }
  };

  const handleBlur = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: false,
    }));
  };
  const columns = [
    {
      field: "sku",
      headerName: "SKU",
      width: 150,
      cellClassName: "centered-cell",
    },
    {
      field: "price",
      headerName: "Giá",
      type: "number",
      width: 150,
      cellClassName: "centered-cell",
    },
    {
      field: "iconCell",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon
          color="secondary"
          onClick={() => {
            const newObj = { ...priceInfo };
            delete newObj[params.row.sku];
            setPriceInfo(newObj);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    const newArray = [];
    Object.keys(priceInfo).map((ele, index) => {
      newArray.push({ id: index, sku: ele, price: priceInfo[ele] });
    });
    setRows(newArray);
  }, [priceInfo]);

  return (
    <React.Fragment>
      <div className="page">
        <div className="edit_container">
          <section className="edit_section">
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Thông tin cơ bản</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span></span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={show}
                                  onChange={(e) => {
                                    return setShow(e.target.checked);
                                  }}
                                />
                              }
                              label="Kích hoạt"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <div className="mandatory">
                          <div className="mandatory-icon">*</div>
                        </div>
                        <span>Tên chương trình</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.name ? "focused" : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("name")}
                                  onBlur={() => handleBlur("name")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <div className="mandatory">
                          <div className="mandatory-icon">*</div>
                        </div>
                        <span>Mã chương trình</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.discountProgramCode ? "focused" : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  value={discountProgramCode}
                                  onChange={(e) => {
                                    const regex = /^[a-zA-Z0-9-_ ]*$/;
                                    const value = e.target.value;
                                    if (regex.test(value)) {
                                      setDiscountProgramCode(value);
                                    }
                                  }}
                                  className="input_input"
                                  onFocus={() =>
                                    handleFocus("discountProgramCode")
                                  }
                                  onBlur={() =>
                                    handleBlur("discountProgramCode")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Mô tả</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.description ? "focused" : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  value={description}
                                  onChange={(e) => {
                                    setDescription(e.target.value);
                                  }}
                                  className="input_input"
                                  onFocus={() =>
                                    handleFocus("discountProgramCode")
                                  }
                                  onBlur={() =>
                                    handleBlur("discountProgramCode")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="edit_section" style={{ marginBottom: "50px" }}>
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Chi tiết chương trình</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row" style={{ marginBottom: 0 }}>
                      <div className="edit-label edit-title">
                        <div className="mandatory">
                          <div className="mandatory-icon">*</div>
                        </div>
                        <span>Thời gian</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div className="form-group px-0 width300 inline mr20">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Stack spacing={3}>
                                    <DesktopDateTimePicker
                                      views={[
                                        "month",
                                        "day",
                                        "hours",
                                        "minutes",
                                      ]}
                                      inputFormat="DD/MM/YYYY HH:mm"
                                      label="Ngày bắt đầu"
                                      value={dateFrom}
                                      onChange={(newValue) => {
                                        setDateFrom(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>
                              <div className="form-group px-0 width300 inline ">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Stack spacing={3}>
                                    <DesktopDateTimePicker
                                      views={[
                                        "month",
                                        "day",
                                        "hours",
                                        "minutes",
                                      ]}
                                      inputFormat="DD/MM/YYYY HH:mm"
                                      label="Ngày kết thúc"
                                      value={dateTo}
                                      onChange={(newValue) => {
                                        setDateTo(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span></span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isCondition}
                                  onChange={(e) => {
                                    return setIsCondition(e.target.checked);
                                  }}
                                />
                              }
                              label="Điều kiện"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Loại điều kiện</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={conditionType}
                              onChange={(e) => {
                                setConditionType(Number(e.target.value));
                              }}
                            >
                              <FormControlLabel
                                disabled={!isCondition}
                                value={1}
                                control={<Radio />}
                                label="Số lượng mua"
                              />
                              <FormControlLabel
                                disabled={!isCondition}
                                value={2}
                                control={<Radio />}
                                label="Giá trị mua"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Chi tiết giảm</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              {conditionType === 1 && (
                                <Paper
                                  elevation={0}
                                  style={{
                                    background: "#ffffff",
                                    marginBottom: 10,
                                  }}
                                >
                                  <TextField
                                    label="Số lượng"
                                    id="outlined-size-small"
                                    size="small"
                                    // type="number"
                                    value={quantity}
                                    disabled={isCondition === false}
                                    onChange={(e) => {
                                      const value = e.target.value
                                        .replace(/^0+/, "")
                                        .replace(/[^0-9]/g, "");
                                      setQuantity(Number(value));
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault(); // Hủy sự kiện onSubmit
                                      }
                                    }}
                                  />
                                </Paper>
                              )}

                              {conditionType === 2 && (
                                <Paper
                                  elevation={0}
                                  style={{
                                    background: "#ffffff",
                                    marginBottom: 10,
                                  }}
                                >
                                  <TextField
                                    label="Tổng tối thiểu"
                                    id="outlined-size-small"
                                    size="small"
                                    // type="number"
                                    value={moneyFormat(totalValue)}
                                    disabled={isCondition === false}
                                    onChange={(e) => {
                                      const value = e.target.value
                                        .replace(/^0+/, "")
                                        .replace(/[^0-9]/g, "");
                                      setTotalValue(Number(value));
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        e.preventDefault(); // Hủy sự kiện onSubmit
                                      }
                                    }}
                                  />
                                </Paper>
                              )}
                              <Paper
                                elevation={0}
                                style={{ background: "#ffffff" }}
                              >
                                <TextField
                                  label="Giảm tiền"
                                  id="outlined-size-small"
                                  size="small"
                                  // type="number"
                                  value={moneyFormat(discountValue)}
                                  disabled={isCondition === false}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    setDiscountValue(Number(value));
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Hủy sự kiện onSubmit
                                    }
                                  }}
                                />
                              </Paper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Thêm thủ công</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.skutag ? "focused" : ""
                                }`}
                                style={{ width: "200px", marginRight: "15px" }}
                              >
                                <input
                                  type="text"
                                  value={sku}
                                  placeholder="SKU"
                                  onChange={(e) => {
                                    setSku(e.target.value);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("skutag")}
                                  onBlur={() => handleBlur("skutag")}
                                />
                              </div>
                              <div
                                className={`input_inner ${
                                  focus.price ? "focused" : ""
                                }`}
                                style={{ width: "200px", marginRight: "15px" }}
                              >
                                <input
                                  type="text"
                                  value={moneyFormat(price)}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    setPrice(Number(value));
                                  }}
                                  placeholder="Giá"
                                  className="input_input"
                                  onFocus={() => handleFocus("price")}
                                  onBlur={() => handleBlur("price")}
                                />
                              </div>
                            </div>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                if (sku === "") {
                                  alert("Chưa nhập sku");
                                  return;
                                } else {
                                  const newObj = { ...priceInfo, [sku]: price };
                                  setPriceInfo(newObj);
                                  setSku("");
                                  setPrice(0);
                                }
                              }}
                              style={{ cursor: "pointer", color: "#2673dd" }}
                            >
                              Thêm
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Nhập file</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="variation-edit-item">
                            <div className="variation-edit-panel">
                              <div className="variation-edit-">
                                File xls gồm 2 cột: SKU cột 1, giá khuyến mãi
                                cột 2, bắt đầu từ hàng thứ 1
                              </div>
                            </div>

                            <div className="product-edit-form-item-content">
                              <div style={{ marginBottom: "15px" }}>
                                <input
                                  type="file"
                                  onChange={handleFileChange}
                                  accept=".xlsx, .xls"
                                />
                              </div>
                              <div>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => setPriceInfo({ ...temp })}
                                >
                                  Cập nhật file
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Chi tiết giá</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            {/* */}

                            <div style={{ height: 400, width: "100%" }}>
                              <style>
                                {`
            .MuiDataGrid-cell:focus {
              outline: none !important;
            }
          `}
                              </style>
                              <DataGrid
                                rows={rows}
                                columns={columns}
                                disableSelectionOnClick
                                onRowClick={(e) => {
                                  setSku(e.row.sku);
                                }}
                                initialState={{
                                  pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                  },
                                }}
                                pageSizeOptions={[5, 10]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => createDiscount()}
                                >
                                  Tạo chương trình
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateDiscount;
