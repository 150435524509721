import React, { useState, useEffect } from "react";
import { moneyFormat, dateFormat } from "../helper";
import { Icon } from "@iconify/react";
import Api from "../api/apis";

function CustomerInvoice({ setCustomerInvoiceTab, phone }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  const invoiceStateArr = [
    "Tất cả",
    "Chưa xác nhận",
    "Đã xác nhận",
    "Đã xác nhận",
    "Đã hoàn thành",
    "Đã hủy",
  ];
  const paymentStateArr = ["Tất cả", "Đã thanh toán", "Chưa thanh toán"];
  const getPaymentMethod = (method) => {
    let methods = {
      1:"Tiền mặt",
      2:"Chuyển khoản ngân hàng",
      3:"Thanh toán online qua OnePay"
    }
    return methods[method]??""
  };


  const [invoiceList, setInvoiceList] = useState([]);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);

  useEffect(() => {
    fetch(`${apiURL}/employee/search/customer`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({phone, page}),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setTotalCount(
            result.invoiceList[0].invoiceList.length > 0
              ? result.invoiceList[0].count
              : 0
          );
          setTotalIncome(
            result.invoiceList[0].totalIncome
              ? result.invoiceList[0].totalIncome
              : 0
          );
          setInvoiceList(
            result.invoiceList[0].invoiceList.length > 0
              ? result.invoiceList[0].invoiceList
              : []
          );
          setMaxPage(
            result.invoiceList[0].invoiceList.length > 0
              ? Math.ceil(result.invoiceList[0].count / 10)
              : 0
          );
        } else {
        }
      });
  }, []);

  return (
    <React.Fragment>
      <div
        className="toggleTab"
        onClick={(e) => {
          setCustomerInvoiceTab(false);
        }}
      >
        <div
          className="childTab"
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ maxWidth: "80%", height: "80%", overflow:"auto", padding : "40px" }}
        >
          <div>
            <h3 className="vodal-title ">Thông tin khách hàng</h3>

            <div className="form-group px-0 mgt20">
              <span
                className="remove_link remove-cart"
                style={{ cursor: "pointer" }}
              ></span>
              <label className="label-input-group">
                {`Số điện thoại : ${phone}`}
              </label>
              <div className="buttonDiv"></div>
            </div>
            <div className="form-group px-0 mgt20">
              <span
                className="remove_link remove-cart"
                style={{ cursor: "pointer" }}
              ></span>
              <label className="label-input-group">
                {`Doanh thu tích lũy : ${moneyFormat(totalIncome)}`}
              </label>
              <div className="buttonDiv"></div>
            </div>
            <h3 className="vodal-title " style={{marginBottom:"30px"}}>Đơn đã mua</h3>

            <div className="position-relative "  style = {{overflow:"auto", maxHeight:"300px"}}>
                <div className="table-list--config" >
                  <div className="ui-table-normal-container">
                    <table className="ui-table">
                      <thead>
                      <tr>
                          <th className=""style={{maxWidth:100}}>
                            <span>Mã</span>
                          </th>
                          <th className=""style={{maxWidth:100}}>
                            <span>Ngày tạo</span>
                          </th>
                          <th className="" style={{maxWidth:110, whiteSpace:"nowrap", overflow:"hidden"

                        }}>
                            <span>Khách hàng</span>
                          </th>
                          <th className="" style={{maxWidth:150}}>
                            <span>Thanh toán</span>
                          </th>
                          <th className="">
                            <span>Trạng thái</span>
                          </th>
                          <th className="" style={{maxWidth:200}}>
                            <span>Doanh thu</span>
                          </th>
                          <th className="">
                            <span>Tổng tiền</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody >
                        {invoiceList &&
                          invoiceList.map((ele, index) => {
                            return (
                                <tr
                                style={{
                                  borderBottom: "1px solid #eee",
                                }}
                                key={index}
                              >
                                <td className="" style={{ maxWidth: 130, width:130 }}>
                                  {ele?.code ?? ""}
                                </td>
                                <td
                                  className="align-top white-space-normal"
                                  style={{ maxWidth: 100 }}
                                >
                                  {dateFormat(ele.dateCreate)}
                                </td>
                                <td
                                  className="align-top white-space-normal"
                                  style={{
                                    maxWidth: 110,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow:"ellipsis"
                                  }}
                                >
                                  {ele?.customerName ?? ""}
                                </td>
                                <td
                                  className={`align-top pt-3 ${
                                    ele.paymentState ? "green" : "yellow"
                                  }`}
                                  style={{ maxWidth: 150 }}
                                >
                                  {Api.getPaymentState(ele.paymentState)}
                                </td>
                                <td
                                  className={`align-top pt-3 ${
                                    ele.invoiceState === 1
                                      ? "yellow"
                                      : ele.invoiceState === 3
                                      ? "green"
                                      : ele.invoiceState === 5
                                      ? "red"
                                      : "red"
                                  }`}
                                >
                                  {Api.getInvoiceState(ele.invoiceState ?? "")}
                                </td>
                                <td className="align-top pt-3">
                                  {moneyFormat(ele.finalPrice-ele.deliverFee)}
                                </td>
                                <td className="align-top pt-3">
                                  {moneyFormat(ele.finalPrice)}
                                </td>
                                <td className="align-top pt-3">
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    icon="bx:right-arrow"
                                    onClick={(e) => {
                                      window.open(
                                        `/invoice/orderinfo?id=${ele._id}`
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div>
                <div className="row no-gutters justify-content-between paginate">
                  <div className="col-auto "></div>
                  <div className="col-auto ">
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto mr-3">
                        <span
                          style={{ color: "rgb(128, 128, 128)" }}
                        >{`Tổng: ${totalCount}`}</span>
                      </div>
                      <div className="col">
                        <ul className="pagination pagination-lg justify-content-center">
                          {page > 2 && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) => setPage(1)}
                            >
                              <div className="page-link">
                                <Icon icon="material-symbols:first-page" />
                              </div>
                            </li>
                          )}
                          {page > 1 && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) =>
                                setPage((prev) => {
                                  return prev - 1;
                                })
                              }
                            >
                              <div className="page-link">{page - 1}</div>
                            </li>
                          )}
                          <li className="page-item hidden-mobile active">
                            <div className="page-link">{page}</div>
                          </li>
                          {page < maxPage && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) =>
                                setPage((prev) => {
                                  return prev + 1;
                                })
                              }
                            >
                              <div className="page-link">{page + 1}</div>
                            </li>
                          )}
                          {page < maxPage - 1 && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) => setPage(maxPage)}
                            >
                              <div className="page-link">
                                <Icon icon="material-symbols:last-page" />
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CustomerInvoice;
