import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function AddProductInfo({
  setAddProductInfoTab,
  setTrigger,
  addType,
  groupAddType,
  groupLabelListAll,
}) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [removeId, setRemoveId] = useState("")
  const [name, setName] = useState("");
  const [ofProductType, setOfProductType] = useState([]);
  const typeList = [{"_id":1, "name" :'Vali'},{"_id":2, "name" :'Balo'},{"_id":3, "name" :'Túi xách'},{"_id":4, "name" :'Phụ kiện'}]
  const createInfo = () => {
    if (name !== "" && ((ofProductType.length!==0&&[0,1].includes(groupAddType)) || ![0,1].includes(groupAddType))) {
      fetch(`${apiURL}/product/productdetail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({
          type: addType,
          name,
          groupAddType,
          ofProductType
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            setAddProductInfoTab(false);
            setTrigger((prev) => {
              return !prev;
            });
          } else {
            alert("Tên đã tồn tại");
          }
        });
      } else  { 
      alert("Nhập tên và chọn nhóm hàng");
      return;
    }
  };
  const deleteInfo = () => {
    if(removeId!==""){

      fetch(`${apiURL}/product/productdetail`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({
          _id:removeId
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            setAddProductInfoTab(false);
            setTrigger((prev) => {
              return !prev;
            });
          } else {
            alert("Có lỗi");
          }
        });
    }
  };
  return (
    <React.Fragment>
      <div
        className="toggleTab"
        onClick={(e) => {
          setAddProductInfoTab(false);
          setTrigger((prev) => {
            return !prev;
          });
        }}
      >
        <div
          className="childTab"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <h3 className="vodal-title ">{`Thêm ${
              groupAddType === 0 ? "nhãn" : (groupAddType === 1 ? "bộ sưu tập" : "lý do hủy đơn")
            }`}</h3>

            <div className="form-group px-0 mgt20">
              <label className="label-input-group">Tên</label>
              <div className="buttonDiv">
                <input
                  value={name}
                  className="next-input"
                  placeholder="Nhập tên"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            
            {[0,1].includes(groupAddType)&&<div className="form-group px-0 mgt20">
              <label className="label-input-group">Nhóm hàng</label>
              <div className="buttonDiv">
                <Autocomplete
                  multiple
                  sx={{ minWidth: 120 }}
                  id="tags-outlined"
                  size="small"
                  options={typeList}
                  // tên hiển thị
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} />}
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.name.toLowerCase().includes(inputValue)
                    );
                  }}
                  onChange={(event, newValue) => {
                    const selectedGroupIds = newValue.map(
                      (option) => option._id
                    );
                    setOfProductType([...selectedGroupIds]);
                  }}
                  //stop warning
                  isOptionEqualToValue={(option, value) => option._id===value._id}
                />
              </div>
            </div>}

            <div className="right-button">
              <button
                className="btn btn-primary text-right"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm(`Thêm ${
                    groupAddType === 0 ? "nhãn" : (groupAddType === 1 ?"bộ sưu tập":"lý do hủy đơn")
                  }?`)) {
                    createInfo();
                  }
                }}
              >
                Thêm
              </button>
            </div>
          </div>

          <div>
            <h3 className="vodal-title ">{`Xóa ${
              groupAddType === 0 ? "nhãn" : (groupAddType === 1 ?"bộ sưu tập":"lý do hủy đơn")
            }`}</h3>

            <div className="form-group px-0 mgt20">
              <label className="label-input-group">Tên</label>
              <div className="buttonDiv">
                <Autocomplete
                  sx={{ minWidth: 120 }}
                  id="tags-outlined"
                  size="small"
                  options={groupLabelListAll.filter((item) => item.type === groupAddType)}
                  // tên hiển thị
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} />}
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    return options.filter((option) =>
                      option.name.toLowerCase().includes(inputValue)
                    );
                  }}
                  onChange={(event, newValue) => { 
                    setRemoveId(newValue?._id??"");
                  }}
                  //stop warning
                  isOptionEqualToValue={(option, value) => option._id===value._id}
                />
              </div>
            </div>

            <div className="right-button" >
              <button style={{background:'red', border:"red"}}
                className="btn btn-primary text-right"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm(`Xóa ${
                    groupAddType === 0 ? "nhãn" : (groupAddType === 1 ?"bộ sưu tập":"lý do hủy đơn")
                  }?`)) {
                    deleteInfo();
                  }
                }}
              >
                Xóa
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddProductInfo;
