import { Route, Routes, Link } from "react-router-dom";
import "swiper/css/bundle";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import AddNewProduct from "../pages/new_channel/product/AddNewProduct";
import AllProduct from "../pages/product/AllProduct";
import CreateNewDiscount from "../pages/new_channel/discount/CreateDiscount";
import Customer from "../pages/customer/Customer";
import Discount from "../pages/discount/Discount";
import DiscountInfo from "../pages/discount/DiscountInfo";
import Employee from "../pages/customer/Employee";
import GiftWithPurchase from "../pages/discount/GiftWithPurchase";
import GiftWithPurchaseInfo from "../pages/discount/GiftWithPurchaseInfo";
import Guided from "../pages/invoice/Guided";
import ImageContent from "../pages/channel/ImageContent";
import Invoice from "../pages/invoice/Invoice";
import Login from "../pages/Login/Login";
import Menu from "../pages/channel/Menu";
import NewsFeed from "../pages/channel/NewsFeed";
import OrderInfo from "../pages/invoice/OrderInfo";
import PaymentMethod from "../pages/channel/PaymentMethod";
import ProductInfo from "../pages/product/ProductInfo";
import Profile from "../pages/Login/Profile";
import Shipping from "../pages/channel/Shipping";
import StoreList from "../pages/channel/StoreList";
import Voucher from "../pages/discount/Voucher";

function RoutePath() {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [select, setSelect] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [userMenu, setUserMenu] = useState(false);
  const [user, setUser] = useState({});
  useEffect(() => {
    fetch(apiURL + "/employee/loginCheck", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          localStorage &&
            localStorage.setItem(
              "BayanAdminInfo",
              JSON.stringify(result.decoded)
            );
          setUser(result.decoded);
          setIsLogin(true);
        } else {
          setIsLogin(false);
        }
      });
  }, []);
  return (
    <React.Fragment>
      {!isLogin && <Login />}
      {isLogin && (user?._id ?? "") !== "" && (
        <div
          className="AdminAllCss"
          onClick={(e) => {
            setUserMenu(false);
          }}
        >
          <div className="wrapperAdmin">
            <div className="sidebarAdmin">
              <div className="sidebarContainer">
                <div className="sidebarFormat">
                  <div className="sidebarTitle">BANYAN</div>
                  <div className="sidebarContent">
                    <div className="sidebarListWrapper">
                      <ul className="sidebarList">
                        {user && user.role === 1 && (
                          <React.Fragment>
                            <li className="sidebarAdminGroup">Quản lý kênh</li>
                            <li
                              className={
                                select === "storeList"
                                  ? "sidebarAdminElement active"
                                  : "sidebarAdminElement"
                              }
                            >
                              <a href="/storelist" className="sidebarLink">
                                <div className="sidebarEleIcon">
                                  <Icon icon="material-symbols:store" />
                                </div>
                                <div className="sidebarEleText">
                                  <span>Hệ thống cửa hàng</span>
                                </div>
                              </a>
                            </li>
                            <li
                              className={
                                select === "menu"
                                  ? "sidebarAdminElement active"
                                  : "sidebarAdminElement"
                              }
                            >
                              <a href="/menu" className="sidebarLink">
                                <div className="sidebarEleIcon">
                                  <Icon icon="material-symbols:store" />
                                </div>
                                <div className="sidebarEleText">
                                  <span>Menu</span>
                                </div>
                              </a>
                            </li>
                            <li
                              className={
                                select === "imageContent"
                                  ? "sidebarAdminElement active"
                                  : "sidebarAdminElement"
                              }
                            >
                              <a href="/imagecontent" className="sidebarLink">
                                <div className="sidebarEleIcon">
                                  <Icon icon="material-symbols:store" />
                                </div>
                                <div className="sidebarEleText">
                                  <span>Nội dung kênh</span>
                                </div>
                              </a>
                            </li>
                            <li
                              className={
                                select === "news"
                                  ? "sidebarAdminElement active"
                                  : "sidebarAdminElement"
                              }
                            >
                              <a href="/news" className="sidebarLink">
                                <div className="sidebarEleIcon">
                                  <Icon icon="material-symbols:store" />
                                </div>
                                <div className="sidebarEleText">
                                  <span>Bài viết</span>
                                </div>
                              </a>
                            </li>
                            <li
                              className={
                                select === "shipping"
                                  ? "sidebarAdminElement active"
                                  : "sidebarAdminElement"
                              }
                            >
                              <a href="/shipping" className="sidebarLink">
                                <div className="sidebarEleIcon">
                                  <Icon icon="material-symbols:store" />
                                </div>
                                <div className="sidebarEleText">
                                  <span>Phí giao hàng</span>
                                </div>
                              </a>
                            </li>
                            <li
                              className={
                                select === "paymentmethod"
                                  ? "sidebarAdminElement active"
                                  : "sidebarAdminElement"
                              }
                            >
                              <a href="/paymentmethod" className="sidebarLink">
                                <div className="sidebarEleIcon">
                                  <Icon icon="material-symbols:store" />
                                </div>
                                <div className="sidebarEleText">
                                  <span>Phương thức thanh toán</span>
                                </div>
                              </a>
                            </li>
                          </React.Fragment>
                        )}
                        <li className="sidebarAdminGroup">Sản phẩm</li>
                        {user && user.role === 1 && (
                          <li
                            className={
                              select === "AddNewProduct"
                                ? "sidebarAdminElement active"
                                : "sidebarAdminElement"
                            }
                          >
                            <a href="/addproduct" className="sidebarLink">
                              <div className="sidebarEleIcon">
                                <Icon icon="mdi:briefcase-add" />
                              </div>
                              <div className="sidebarEleText">
                                <span>Tạo sản phẩm</span>
                              </div>
                            </a>
                          </li>
                        )}
                        <li
                          className={
                            select === "allproduct"
                              ? "sidebarAdminElement active"
                              : "sidebarAdminElement"
                          }
                        >
                          <a href="/allproduct" className="sidebarLink">
                            <div className="sidebarEleIcon">
                              <Icon icon="material-symbols:luggage-outline-rounded" />
                            </div>
                            <div className="sidebarEleText">
                              <span>Sản phẩm</span>
                            </div>
                          </a>
                        </li>

                        <li className="sidebarAdminGroup">Người dùng</li>

                        <li
                          className={
                            select === "employee1"
                              ? "sidebarAdminElement active"
                              : "sidebarAdminElement"
                          }
                        >
                          <a href="/profile" className="sidebarLink">
                            <div className="sidebarEleIcon">
                              <Icon icon="clarity:employee-group-line" />
                            </div>
                            <div className="sidebarEleText">
                              <span>Thông tin tài khoản</span>
                            </div>
                          </a>
                        </li>
                        {user && user.role === 1 && (
                          <li
                            className={
                              select === "employee"
                                ? "sidebarAdminElement active"
                                : "sidebarAdminElement"
                            }
                          >
                            <a href="/employee" className="sidebarLink">
                              <div className="sidebarEleIcon">
                                <Icon icon="clarity:employee-group-line" />
                              </div>
                              <div className="sidebarEleText">
                                <span>Danh sách nhân viên</span>
                              </div>
                            </a>
                          </li>
                        )}
                        <li
                          className={
                            select === "customer"
                              ? "sidebarAdminElement active"
                              : "sidebarAdminElement"
                          }
                        >
                          <a href="/customer" className="sidebarLink">
                            <div className="sidebarEleIcon">
                              <Icon icon="clarity:employee-group-line" />
                            </div>
                            <div className="sidebarEleText">
                              <span>Khách hàng</span>
                            </div>
                          </a>
                        </li>

                        <li className="sidebarAdminGroup">Đơn đặt hàng</li>

                        <li
                          className={
                            select === "invoice"
                              ? "sidebarAdminElement active"
                              : "sidebarAdminElement"
                          }
                        >
                          <a href="/invoice" className="sidebarLink">
                            <div className="sidebarEleIcon">
                              <Icon icon="material-symbols:order-approve-outline-sharp" />
                            </div>
                            <div className="sidebarEleText">
                              <span>Danh sách đơn</span>
                            </div>
                          </a>
                        </li>

                        <li
                          className={
                            select === "guided"
                              ? "sidebarAdminElement active"
                              : "sidebarAdminElement"
                          }
                        >
                          {user && user.role === 1 && (
                          <a href="/guided" className="sidebarLink">
                            <div className="sidebarEleIcon">
                              <Icon icon="material-symbols:order-approve-outline-sharp" />
                            </div>
                            <div className="sidebarEleText">
                              <span>Báo cáo guided</span>
                            </div>
                          </a>
                          )}
                        </li>

                        {user && user.role === 1 && (
                          <React.Fragment>
                            <li className="sidebarAdminGroup">Khuyến mãi</li>

                            <li
                              className={
                                select === "discount"
                                  ? "sidebarAdminElement active"
                                  : "sidebarAdminElement"
                              }
                            >
                              <a href="/discount" className="sidebarLink">
                                <div className="sidebarEleIcon">
                                  <Icon icon="mdi:cart-discount" />
                                </div>
                                <div className="sidebarEleText">
                                  <span>Chương trình khuyến mãi</span>
                                </div>
                              </a>
                            </li>

                            <li
                              className={
                                select === "voucher"
                                  ? "sidebarAdminElement active"
                                  : "sidebarAdminElement"
                              }
                            >
                              <a href="/voucher" className="sidebarLink">
                                <div className="sidebarEleIcon">
                                  <Icon icon="mdi:voucher-outline" />
                                </div>
                                <div className="sidebarEleText">
                                  <span>Voucher</span>
                                </div>
                              </a>
                            </li>
                            <li
                              className={
                                select === "giftwithpurchase"
                                  ? "sidebarAdminElement active"
                                  : "sidebarAdminElement"
                              }
                            >
                              <a
                                href="/giftwithpurchase"
                                className="sidebarLink"
                              >
                                <div className="sidebarEleIcon">
                                  <Icon icon="mdi:voucher-outline" />
                                </div>
                                <div className="sidebarEleText">
                                  <span>Gift With Purchase</span>
                                </div>
                              </a>
                            </li>
                          </React.Fragment>
                        )}
                      </ul>
                      <div style={{ minHeight: "30px" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mainPageAdmin">
              <header className="mainPageAdminHeader">
                <div className="css-8g4m5a">
                  <div
                    className="css-10uvzj5"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <span
                      className="headerSpan relative"
                      onClick={(e) => {
                        setUserMenu((prev) => {
                          return !prev;
                        });
                      }}
                    >
                      <div className="MuiAvatar-root MuiAvatar-circular css-12kqh4d">
                        <Icon icon="mdi:user" />
                      </div>
                    </span>
                    {userMenu && (
                      <div
                        onClick={(e) => {
                          setUserMenu(false);
                        }}
                        className="userMenu"
                      >
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <div
                            className="infoAdminArea css-rt80a4"
                            style={{ width: "200px" }}
                          >
                            <h5 className="MuiTypography-root MuiTypography-h5 css-55ijvw">
                              {user.name ?? ""}
                            </h5>
                            <p className="MuiTypography-root MuiTypography-body1 css-hxfal">
                              {user.email ?? ""}
                            </p>
                          </div>
                          <hr className="MuiDivider-root MuiDivider-fullWidth css-6gnggm"></hr>
                          <nav
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Link to="/profile">
                              <ul className="css-1h8lf8h">
                                <div className="css-ey7g9c">
                                  <div className="css-mvy2gl">
                                    <Icon icon="mdi:user" />
                                  </div>
                                  <div className="css-c9erkg">
                                    <span className="css-1xeobr1">
                                      Thông tin
                                    </span>
                                  </div>
                                </div>
                              </ul>
                            </Link>
                          </nav>
                          <nav
                            onClick={(e) => {
                              e.preventDefault();
                              localStorage.removeItem("banyanAdminToken");
                              window.location.reload(false);
                            }}
                          >
                            <ul className="css-1h8lf8h">
                              <div className="css-ey7g9c">
                                <div className="css-mvy2gl">
                                  <Icon icon="material-symbols:logout" />
                                </div>
                                <div className="css-c9erkg">
                                  <span className="css-1xeobr1">Đăng xuất</span>
                                </div>
                              </div>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </header>
              <div className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular css-xly39e"></div>
              <div className="CmtLayout-content css-m7fmz7">
                <Routes>
                  {user && user.role === 1 && (
                    <Route
                      path="/menu"
                      element={
                        <React.Fragment>
                          {" "}
                          <Menu setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  <Route
                    path="/profile"
                    element={
                      <React.Fragment>
                        {" "}
                        <Profile setSelect={setSelect} user={user} />{" "}
                      </React.Fragment>
                    }
                  />
                  {user && user.role === 1 && (
                    <Route
                      path="/imagecontent"
                      element={
                        <React.Fragment>
                          {" "}
                          <ImageContent setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  {user && user.role === 1 && (
                    <Route
                      path="/news"
                      element={
                        <React.Fragment>
                          {" "}
                          <NewsFeed setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  {user && user.role === 1 && (
                    <Route
                      path="/paymentmethod"
                      element={
                        <React.Fragment>
                          {" "}
                          <PaymentMethod setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  {user && user.role === 1 && (
                    <Route
                      path="/shipping"
                      element={
                        <React.Fragment>
                          {" "}
                          <Shipping setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  {user && user.role === 1 && (
                    <Route
                      path="/storelist"
                      element={
                        <React.Fragment>
                          {" "}
                          <StoreList setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  <Route
                    path="/allproduct"
                    element={
                      <React.Fragment>
                        {" "}
                        <AllProduct user={user} setSelect={setSelect} />{" "}
                      </React.Fragment>
                    }
                  />
                  <Route
                    path="/allproduct/product"
                    element={
                      <div>
                        {" "}
                        <ProductInfo setSelect={setSelect} />{" "}
                      </div>
                    }
                  ></Route>
                  {user && user.role === 1 && (
                    <Route
                      path="/employee"
                      element={
                        <div>
                          {" "}
                          <Employee setSelect={setSelect} />{" "}
                        </div>
                      }
                    ></Route>
                  )}
                  <Route
                    path="/customer"
                    element={
                      <div>
                        {" "}
                        <Customer setSelect={setSelect} />{" "}
                      </div>
                    }
                  ></Route>
                  {/* <Route
                    path="/warehouse"
                    element={
                      <React.Fragment>
                        {" "}
                        <Warehouse setSelect={setSelect} />{" "}
                      </React.Fragment>
                    }
                  /> */}
                  {user && user.role === 1 && (
                    <Route
                      path="/discount"
                      element={
                        <React.Fragment>
                          {" "}
                          <Discount setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  {user && user.role === 1 && (
                    <Route
                      path="/discount/creatediscount"
                      element={
                        <React.Fragment>
                          {" "}
                          <CreateNewDiscount setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  {user && user.role === 1 && (
                    <Route
                      path="/discount/discountinfo"
                      element={
                        <React.Fragment>
                          {" "}
                          <DiscountInfo setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  {user && user.role === 1 && (
                    <Route
                      path="/voucher"
                      element={
                        <React.Fragment>
                          {" "}
                          <Voucher setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  {user && user.role === 1 && (
                    <Route
                      path="/giftwithpurchase"
                      element={
                        <React.Fragment>
                          {" "}
                          <GiftWithPurchase setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  {user && user.role === 1 && (
                    <Route
                      path="/giftwithpurchase/giftwithpurchaseinfo"
                      element={
                        <React.Fragment>
                          {" "}
                          <GiftWithPurchaseInfo setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                  <Route
                    path="/invoice"
                    element={
                      <React.Fragment>
                        {" "}
                        <Invoice user={user} setSelect={setSelect} />{" "}
                      </React.Fragment>
                    }
                  />
                  {user && user.role === 1 && (
                  <Route
                    path="/guided"
                    element={
                      <React.Fragment>
                        {" "}
                        <Guided user={user} setSelect={setSelect} />{" "}
                      </React.Fragment>
                    }
                  />
                  )}
                  <Route
                    path="/invoice/orderinfo"
                    element={
                      <React.Fragment>
                        {" "}
                        <OrderInfo setSelect={setSelect} />{" "}
                      </React.Fragment>
                    }
                  />
                  {user && user.role === 1 && (
                    <Route
                      path="/addproduct"
                      element={
                        <React.Fragment>
                          {" "}
                          <AddNewProduct setSelect={setSelect} />{" "}
                        </React.Fragment>
                      }
                    />
                  )}
                </Routes>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default RoutePath;
