import React, { useEffect, useState } from "react";
import Api from "../../api/apis";
import { Icon } from "@iconify/react";


function Menu({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  const [channelInfo, setChannelInfo] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [groupLabelList, setGroupLabelList] = useState([]);
  const [SEODesc, setSEODesc] = useState([false, 0, 0, 0])

  useEffect(() => {
    setSelect("menu");
    let set = true;

    const fetchData = async () => {
      try {
        let rs = {};
        rs = { ...(await Api.findChannel("American Tourister")) };
        if (set && rs.success) {
          setChannelInfo(rs.channel);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    const fetchData1 = async () => {
      try {
        let rs = {};
        rs = { ...(await Api.getGroupLabelList()) };
        if (set && rs.success) {
          setGroupLabelList(rs.list);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData1();

    return () => {
      set = false;
    };
  }, [trigger]);

  const updateChannel = (async()=>{
    try {
        let rs = {};
        rs = { ...(await Api.updateChannelByName(channelInfo.name??"", channelInfo)) };
        if ( rs.success) {
            alert(rs.message)
            setTrigger(prev=>{return prev+1})
        }else{
            alert(rs.message)
        }
      } catch (error) {
        console.log(error);
      }
  })

  return (
    <React.Fragment>
      {SEODesc[0] === true && (
        <React.Fragment>
          <div
            className="toggleTab"
            onClick={(e) => {
              setSEODesc([false, 0, 0, 0]);
            }}
          >
            <div
              style={{
                maxHeight: "80%",
                overflowY: "auto",
                width: "50%",
                height: "50%",
                maxWidth: "80%",
              }}
              className="childTab"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div>
                <h3 className="vodal-title ">Tối ưu SEO</h3>

                <div
                  className="form-group px-0 mgb0"
                  style={{ marginTop: "30px" }}
                >
                  <label className="label-input-group">Tiêu đề trang</label>
                  <div className="buttonDiv">
                    <input
                      value={
                        SEODesc[1] === 1
                          ? channelInfo?.luggageMenu[SEODesc[2]]?.arr[
                              SEODesc[3]
                            ]?.title ?? ""
                          : SEODesc[1] === 2
                          ? channelInfo?.backpackMenu[SEODesc[2]]?.arr[
                              SEODesc[3]
                            ]?.title ?? ""
                          : SEODesc[1] === 3
                          ? channelInfo?.bagMenu[SEODesc[2]]?.arr[SEODesc[3]]
                              ?.title ?? ""
                          : SEODesc[1] === 4
                          ? channelInfo?.accessoryMenu[SEODesc[2]]?.arr[
                              SEODesc[3]
                            ]?.title ?? ""
                          : ""
                      }
                      className="next-input"
                      placeholder="Nhập tiêu đề"
                      onChange={(e) => {
                        var t;
                        if (SEODesc[1] === 1) {
                          t = channelInfo.luggageMenu;
                          t[SEODesc[2]].arr[SEODesc[3]]["title"] =
                            e.target.value;
                          setChannelInfo((prev) => {
                            return {
                              ...prev,
                              luggageMenu: t,
                            };
                          });
                        }
                        if (SEODesc[1] === 2) {
                          t = channelInfo.backpackMenu;
                          t[SEODesc[2]].arr[SEODesc[3]]["title"] =
                            e.target.value;
                          setChannelInfo((prev) => {
                            return {
                              ...prev,
                              backpackMenu: t,
                            };
                          });
                        }
                        if (SEODesc[1] === 3) {
                          t = channelInfo.bagMenu;
                          t[SEODesc[2]].arr[SEODesc[3]]["title"] =
                            e.target.value;
                          setChannelInfo((prev) => {
                            return {
                              ...prev,
                              bagMenu: t,
                            };
                          });
                        }
                        if (SEODesc[1] === 4) {
                          t = channelInfo.accessoryMenu;
                          t[SEODesc[2]].arr[SEODesc[3]]["title"] =
                            e.target.value;
                          setChannelInfo((prev) => {
                            return {
                              ...prev,
                              accessoryMenu: t,
                            };
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="form-group px-0 mgb0">
                  <label className="label-input-group">Mô tả trang</label>
                  <div className="buttonDiv">
                    <input
                      value={
                        SEODesc[1] === 1
                          ? channelInfo?.luggageMenu[SEODesc[2]]?.arr[
                              SEODesc[3]
                            ]?.description ?? ""
                          : SEODesc[1] === 2
                          ? channelInfo?.backpackMenu[SEODesc[2]]?.arr[
                              SEODesc[3]
                            ]?.description ?? ""
                          : SEODesc[1] === 3
                          ? channelInfo?.bagMenu[SEODesc[2]]?.arr[SEODesc[3]]
                              ?.description ?? ""
                          : SEODesc[1] === 4
                          ? channelInfo?.accessoryMenu[SEODesc[2]]?.arr[
                              SEODesc[3]
                            ]?.description ?? ""
                          : ""
                      }
                      className="next-input"
                      placeholder="Nhập mô tả"
                      onChange={(e) => {
                        var t;
                        if (SEODesc[1] === 1) {
                          t = channelInfo.luggageMenu;
                          t[SEODesc[2]].arr[SEODesc[3]]["description"] =
                            e.target.value;
                          setChannelInfo((prev) => {
                            return {
                              ...prev,
                              luggageMenu: t,
                            };
                          });
                        }
                        if (SEODesc[1] === 2) {
                          t = channelInfo.backpackMenu;
                          t[SEODesc[2]].arr[SEODesc[3]]["description"] =
                            e.target.value;
                          setChannelInfo((prev) => {
                            return {
                              ...prev,
                              backpackMenu: t,
                            };
                          });
                        }
                        if (SEODesc[1] === 3) {
                          t = channelInfo.bagMenu;
                          t[SEODesc[2]].arr[SEODesc[3]]["description"] =
                            e.target.value;
                          setChannelInfo((prev) => {
                            return {
                              ...prev,
                              bagMenu: t,
                            };
                          });
                        }
                        if (SEODesc[1] === 4) {
                          t = channelInfo.accessoryMenu;
                          t[SEODesc[2]].arr[SEODesc[3]]["description"] =
                            e.target.value;
                          setChannelInfo((prev) => {
                            return {
                              ...prev,
                              accessoryMenu: t,
                            };
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <div className="page">
        <div className="edit_container">
          <section className="edit_section" style={{ marginBottom: "130px" }}>
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Mục lục</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Vali
                        </label>
                        <Icon
                          style={{ cursor: "pointer" }}
                          icon="material-symbols:add-circle"
                          onClick={(e) => {
                            if (channelInfo.luggageMenu.length < 6) {
                              var t = channelInfo.luggageMenu;
                              t.push({ typeOfDt: "", arr: [] });
                              setChannelInfo((prev) => {
                                return { ...prev, luggageMenu: t };
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              {channelInfo && channelInfo.luggageMenu && (
                                <div>
                                  {channelInfo &&
                                    channelInfo.luggageMenu &&
                                    channelInfo.luggageMenu.map(
                                      (ele, index) => {
                                        return (
                                          <div key={`${index}`}>
                                            <div className="ml20 inline">
                                              <input
                                                placeholder="Đề mục"
                                                style={{ marginBottom: "5px" }}
                                                className="next-input mr20 inline width150"
                                                value={
                                                  channelInfo.luggageMenu[index]
                                                    .typeOfDt
                                                }
                                                onChange={(e) => {
                                                  var t =
                                                    channelInfo.luggageMenu;
                                                  t[index].typeOfDt =
                                                    e.target.value;
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      luggageMenu: t,
                                                    };
                                                  });
                                                }}
                                              />
                                              <input
                                                placeholder="Seo Link"
                                                style={{ marginBottom: "5px" }}
                                                className="next-input mr20 inline width150"
                                                value={
                                                  channelInfo.luggageMenu[index]
                                                    ?.SEOLink ?? ""
                                                }
                                                onChange={(e) => {
                                                  var t =
                                                    channelInfo.luggageMenu;
                                                  t[index].SEOLink =
                                                    e.target.value
                                                      .trim()
                                                      .replace(/\s+/g, "");
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      luggageMenu: t,
                                                    };
                                                  });
                                                }}
                                              />
                                              <Icon
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "5px",
                                                }}
                                                icon="material-symbols:add-circle"
                                                onClick={(e) => {
                                                  if (
                                                    channelInfo.luggageMenu[
                                                      index
                                                    ].arr.length < 10
                                                  ) {
                                                    var t =
                                                      channelInfo.luggageMenu;
                                                    t[index].arr.push({
                                                      active: true,
                                                      field: "",
                                                      id: null,
                                                      SEOLink: "",
                                                      title: "",
                                                      description: "",
                                                      typeOfField: 0,
                                                    });
                                                    setChannelInfo((prev) => {
                                                      return {
                                                        ...prev,
                                                        luggageMenu: t,
                                                      };
                                                    });
                                                  }
                                                }}
                                              />
                                              <Icon
                                                style={{ cursor: "pointer" }}
                                                icon="ph:x-circle-fill"
                                                onClick={(e) => {
                                                  var t =
                                                    channelInfo.luggageMenu;
                                                  t.splice(index, 1);
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      luggageMenu: t,
                                                    };
                                                  });
                                                }}
                                              />
                                              {channelInfo &&
                                                channelInfo.luggageMenu &&
                                                channelInfo.luggageMenu[index]
                                                  .arr &&
                                                channelInfo.luggageMenu[
                                                  index
                                                ].arr.map((ele1, index1) => {
                                                  return (
                                                    <div key={`${index1}`}>
                                                      <div
                                                        className="ml50 inline"
                                                        style={{
                                                          marginBottom: "5px",
                                                        }}
                                                      >
                                                        <input
                                                          placeholder="Phân loại"
                                                          className="next-input mr20 inline width150"
                                                          value={
                                                            channelInfo
                                                              .luggageMenu[
                                                              index
                                                            ].arr[index1][
                                                              "field"
                                                            ]
                                                          }
                                                          onChange={(e) => {
                                                            var t =
                                                              channelInfo.luggageMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["field"] =
                                                              e.target.value;
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  luggageMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />
                                                        <input
                                                          placeholder="Seo Link"
                                                          className="next-input mr20 inline width150"
                                                          value={
                                                            channelInfo
                                                              .luggageMenu[
                                                              index
                                                            ].arr[index1][
                                                              "SEOLink"
                                                            ]
                                                          }
                                                          onChange={(e) => {
                                                            var t =
                                                              channelInfo.luggageMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["SEOLink"] =
                                                              e.target.value
                                                                .trim()
                                                                .replace(
                                                                  /\s+/g,
                                                                  ""
                                                                );
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  luggageMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />

                                                        <Icon
                                                          icon="material-symbols:edit"
                                                          style={{
                                                            cursor: "pointer",
                                                            marginRight: 10,
                                                          }}
                                                          onClick={(e) => {
                                                            setSEODesc([
                                                              true,
                                                              1,
                                                              index,
                                                              index1,
                                                            ]);
                                                          }}
                                                        />
                                                        <select
                                                          className="next-input inline"
                                                          style={{
                                                            width: "200px",
                                                            marginRight: "20px",
                                                            marginBottom: "5px",
                                                            marginLeft: "0",
                                                          }}
                                                          value={
                                                            channelInfo &&
                                                            channelInfo.luggageMenu &&
                                                            channelInfo
                                                              .luggageMenu[
                                                              index
                                                            ].arr &&
                                                            channelInfo
                                                              .luggageMenu[
                                                              index
                                                            ].arr[index1] &&
                                                            (channelInfo
                                                              .luggageMenu[
                                                              index
                                                            ].arr[index1][
                                                              "id"
                                                            ] ??
                                                              "")
                                                          }
                                                          onChange={(e) => {
                                                            var t =
                                                              channelInfo.luggageMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["id"] =
                                                              e.target.value;
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  luggageMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        >
                                                          <option value={""}>
                                                            Chọn cụm sản phẩm
                                                          </option>
                                                          {groupLabelList
                                                            .filter(
                                                              (option) => {
                                                                return (
                                                                  option.type ===
                                                                    channelInfo
                                                                      .luggageMenu[
                                                                      index
                                                                    ].arr[
                                                                      index1
                                                                    ][
                                                                      "typeOfField"
                                                                    ] &&
                                                                  option.ofProductType.includes(
                                                                    1
                                                                  )
                                                                );
                                                              }
                                                            )
                                                            .map(
                                                              (
                                                                ele2,
                                                                index2
                                                              ) => {
                                                                return (
                                                                  <option
                                                                    key={`${index2}`}
                                                                    value={
                                                                      ele2[
                                                                        "_id"
                                                                      ]
                                                                    }
                                                                  >
                                                                    {
                                                                      ele2[
                                                                        "name"
                                                                      ]
                                                                    }
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                        </select>
                                                        <Icon
                                                          icon="eva:swap-fill"
                                                          style={{
                                                            cursor: "pointer",
                                                            marginRight: 10,
                                                          }}
                                                          onClick={(e) => {
                                                            var t =
                                                              channelInfo.luggageMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["typeOfField"] =
                                                              1 -
                                                              t[index].arr[
                                                                index1
                                                              ]["typeOfField"];
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  luggageMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />
                                                        <Icon
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          icon="ph:x-circle-fill"
                                                          onClick={(e) => {
                                                            var t =
                                                              channelInfo.luggageMenu;
                                                            t[index].arr.splice(
                                                              index,
                                                              1
                                                            );
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  luggageMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Balo
                        </label>
                        <Icon
                          style={{ cursor: "pointer" }}
                          icon="material-symbols:add-circle"
                          onClick={(e) => {
                            if (channelInfo.backpackMenu.length < 6) {
                              var t = channelInfo.backpackMenu;
                              t.push({ typeOfDt: "", arr: [] });
                              setChannelInfo((prev) => {
                                return { ...prev, backpackMenu: t };
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              {channelInfo && channelInfo.backpackMenu && (
                                <div>
                                  {channelInfo &&
                                    channelInfo.backpackMenu &&
                                    channelInfo.backpackMenu.map(
                                      (ele, index) => {
                                        return (
                                          <div key={`${index}`}>
                                            <div className="ml20 inline">
                                              <input
                                                placeholder="Đề mục"
                                                style={{ marginBottom: "5px" }}
                                                className="next-input mr20 inline width150"
                                                value={
                                                  channelInfo.backpackMenu[
                                                    index
                                                  ].typeOfDt
                                                }
                                                onChange={(e) => {
                                                  var t =
                                                    channelInfo.backpackMenu;
                                                  t[index].typeOfDt =
                                                    e.target.value;
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      backpackMenu: t,
                                                    };
                                                  });
                                                }}
                                              />
                                              <input
                                                placeholder="Seo Link"
                                                style={{ marginBottom: "5px" }}
                                                className="next-input mr20 inline width150"
                                                value={
                                                  channelInfo.backpackMenu[
                                                    index
                                                  ]?.SEOLink ?? ""
                                                }
                                                onChange={(e) => {
                                                  var t =
                                                    channelInfo.backpackMenu;
                                                  t[index].SEOLink =
                                                    e.target.value
                                                      .trim()
                                                      .replace(/\s+/g, "");
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      backpackMenu: t,
                                                    };
                                                  });
                                                }}
                                              />
                                              <Icon
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "5px",
                                                }}
                                                icon="material-symbols:add-circle"
                                                onClick={(e) => {
                                                  if (
                                                    channelInfo.backpackMenu[
                                                      index
                                                    ].arr.length < 10
                                                  ) {
                                                    var t =
                                                      channelInfo.backpackMenu;
                                                    t[index].arr.push({
                                                      active: true,
                                                      field: "",
                                                      id: null,
                                                      SEOLink: "",
                                                      title: "",
                                                      description: "",
                                                      typeOfField: 0,
                                                    });
                                                    setChannelInfo((prev) => {
                                                      return {
                                                        ...prev,
                                                        backpackMenu: t,
                                                      };
                                                    });
                                                  }
                                                }}
                                              />
                                              <Icon
                                                style={{ cursor: "pointer" }}
                                                icon="ph:x-circle-fill"
                                                onClick={(e) => {
                                                  var t =
                                                    channelInfo.backpackMenu;
                                                  t.splice(index, 1);
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      backpackMenu: t,
                                                    };
                                                  });
                                                }}
                                              />
                                              {channelInfo &&
                                                channelInfo.backpackMenu &&
                                                channelInfo.backpackMenu[index]
                                                  .arr &&
                                                channelInfo.backpackMenu[
                                                  index
                                                ].arr.map((ele1, index1) => {
                                                  return (
                                                    <div key={`${index1}`}>
                                                      <div
                                                        className="ml50 inline"
                                                        style={{
                                                          marginBottom: "5px",
                                                        }}
                                                      >
                                                        <input
                                                          placeholder="Phân loại"
                                                          className="next-input mr20 inline width150"
                                                          value={
                                                            channelInfo
                                                              .backpackMenu[
                                                              index
                                                            ].arr[index1][
                                                              "field"
                                                            ]
                                                          }
                                                          onChange={(e) => {
                                                            var t =
                                                              channelInfo.backpackMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["field"] =
                                                              e.target.value;
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  backpackMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />
                                                        <input
                                                          placeholder="Seo Link"
                                                          className="next-input mr20 inline width150"
                                                          value={
                                                            channelInfo
                                                              .backpackMenu[
                                                              index
                                                            ].arr[index1][
                                                              "SEOLink"
                                                            ]
                                                          }
                                                          onChange={(e) => {
                                                            var t =
                                                              channelInfo.backpackMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["SEOLink"] =
                                                              e.target.value
                                                                .trim()
                                                                .replace(
                                                                  /\s+/g,
                                                                  ""
                                                                );
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  backpackMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />

                                                        <Icon
                                                          icon="material-symbols:edit"
                                                          style={{
                                                            cursor: "pointer",
                                                            marginRight: 10,
                                                          }}
                                                          onClick={(e) => {
                                                            setSEODesc([
                                                              true,
                                                              2,
                                                              index,
                                                              index1,
                                                            ]);
                                                          }}
                                                        />
                                                        <select
                                                          className="next-input inline"
                                                          style={{
                                                            width: "200px",
                                                            marginRight: "20px",
                                                            marginBottom: "5px",
                                                            marginLeft: "0",
                                                          }}
                                                          value={
                                                            channelInfo &&
                                                            channelInfo.backpackMenu &&
                                                            channelInfo
                                                              .backpackMenu[
                                                              index
                                                            ].arr &&
                                                            channelInfo
                                                              .backpackMenu[
                                                              index
                                                            ].arr[index1] &&
                                                            (channelInfo
                                                              .backpackMenu[
                                                              index
                                                            ].arr[index1][
                                                              "id"
                                                            ] ??
                                                              "")
                                                          }
                                                          onChange={(e) => {
                                                            var t =
                                                              channelInfo.backpackMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["id"] =
                                                              e.target.value;
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  backpackMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        >
                                                          <option value={""}>
                                                            Chọn cụm sản phẩm
                                                          </option>
                                                          {groupLabelList
                                                            .filter(
                                                              (option) => {
                                                                return (
                                                                  option.type ===
                                                                    channelInfo
                                                                      .backpackMenu[
                                                                      index
                                                                    ].arr[
                                                                      index1
                                                                    ][
                                                                      "typeOfField"
                                                                    ] &&
                                                                  option.ofProductType.includes(
                                                                    2
                                                                  )
                                                                );
                                                              }
                                                            )
                                                            .map(
                                                              (
                                                                ele2,
                                                                index2
                                                              ) => {
                                                                return (
                                                                  <option
                                                                    key={`${index2}`}
                                                                    value={
                                                                      ele2[
                                                                        "_id"
                                                                      ]
                                                                    }
                                                                  >
                                                                    {
                                                                      ele2[
                                                                        "name"
                                                                      ]
                                                                    }
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                        </select>

                                                        <Icon
                                                          icon="eva:swap-fill"
                                                          style={{
                                                            cursor: "pointer",
                                                            marginRight: 10,
                                                          }}
                                                          onClick={(e) => {
                                                            var t =
                                                              channelInfo.backpackMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["typeOfField"] =
                                                              1 -
                                                              t[index].arr[
                                                                index1
                                                              ]["typeOfField"];
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  backpackMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />
                                                        <Icon
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          icon="ph:x-circle-fill"
                                                          onClick={(e) => {
                                                            var t =
                                                              channelInfo.backpackMenu;
                                                            t[index].arr.splice(
                                                              index,
                                                              1
                                                            );
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  backpackMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Túi xách
                        </label>
                        <Icon
                          style={{ cursor: "pointer" }}
                          icon="material-symbols:add-circle"
                          onClick={(e) => {
                            if (channelInfo.bagMenu.length < 6) {
                              var t = channelInfo.bagMenu;
                              t.push({ typeOfDt: "", arr: [] });
                              setChannelInfo((prev) => {
                                return { ...prev, bagMenu: t };
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              {channelInfo && channelInfo.bagMenu && (
                                <div>
                                  {channelInfo &&
                                    channelInfo.bagMenu &&
                                    channelInfo.bagMenu.map((ele, index) => {
                                      return (
                                        <div key={`${index}`}>
                                          <div className="ml20 inline">
                                            <input
                                              placeholder="Đề mục"
                                              style={{ marginBottom: "5px" }}
                                              className="next-input mr20 inline width150"
                                              value={
                                                channelInfo.bagMenu[index]
                                                  .typeOfDt
                                              }
                                              onChange={(e) => {
                                                var t = channelInfo.bagMenu;
                                                t[index].typeOfDt =
                                                  e.target.value;
                                                setChannelInfo((prev) => {
                                                  return {
                                                    ...prev,
                                                    bagMenu: t,
                                                  };
                                                });
                                              }}
                                            />
                                            <input
                                              placeholder="Seo Link"
                                              style={{ marginBottom: "5px" }}
                                              className="next-input mr20 inline width150"
                                              value={
                                                channelInfo.bagMenu[index]
                                                  ?.SEOLink ?? ""
                                              }
                                              onChange={(e) => {
                                                var t = channelInfo.bagMenu;
                                                t[index].SEOLink =
                                                  e.target.value
                                                    .trim()
                                                    .replace(/\s+/g, "");
                                                setChannelInfo((prev) => {
                                                  return {
                                                    ...prev,
                                                    bagMenu: t,
                                                  };
                                                });
                                              }}
                                            />
                                            <Icon
                                              style={{
                                                cursor: "pointer",
                                                marginRight: "5px",
                                              }}
                                              icon="material-symbols:add-circle"
                                              onClick={(e) => {
                                                if (
                                                  channelInfo.bagMenu[index].arr
                                                    .length < 10
                                                ) {
                                                  var t = channelInfo.bagMenu;
                                                  t[index].arr.push({
                                                    active: true,
                                                    field: "",
                                                    id: null,
                                                    SEOLink: "",
                                                    title: "",
                                                    description: "",
                                                    typeOfField: 0,
                                                  });
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      bagMenu: t,
                                                    };
                                                  });
                                                }
                                              }}
                                            />
                                            <Icon
                                              style={{ cursor: "pointer" }}
                                              icon="ph:x-circle-fill"
                                              onClick={(e) => {
                                                var t = channelInfo.bagMenu;
                                                t.splice(index, 1);
                                                setChannelInfo((prev) => {
                                                  return {
                                                    ...prev,
                                                    bagMenu: t,
                                                  };
                                                });
                                              }}
                                            />
                                            {channelInfo &&
                                              channelInfo.bagMenu &&
                                              channelInfo.bagMenu[index].arr &&
                                              channelInfo.bagMenu[
                                                index
                                              ].arr.map((ele1, index1) => {
                                                return (
                                                  <div key={`${index1}`}>
                                                    <div
                                                      className="ml50 inline"
                                                      style={{
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      <input
                                                        placeholder="Phân loại"
                                                        className="next-input mr20 inline width150"
                                                        value={
                                                          channelInfo.bagMenu[
                                                            index
                                                          ].arr[index1]["field"]
                                                        }
                                                        onChange={(e) => {
                                                          var t =
                                                            channelInfo.bagMenu;
                                                          t[index].arr[index1][
                                                            "field"
                                                          ] = e.target.value;
                                                          setChannelInfo(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                bagMenu: t,
                                                              };
                                                            }
                                                          );
                                                        }}
                                                      />
                                                      <input
                                                        placeholder="Seo Link"
                                                        className="next-input mr20 inline width150"
                                                        value={
                                                          channelInfo.bagMenu[
                                                            index
                                                          ].arr[index1][
                                                            "SEOLink"
                                                          ]
                                                        }
                                                        onChange={(e) => {
                                                          var t =
                                                            channelInfo.bagMenu;
                                                          t[index].arr[index1][
                                                            "SEOLink"
                                                          ] = e.target.value
                                                            .trim()
                                                            .replace(
                                                              /\s+/g,
                                                              ""
                                                            );
                                                          setChannelInfo(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                bagMenu: t,
                                                              };
                                                            }
                                                          );
                                                        }}
                                                      />

                                                      <Icon
                                                        icon="material-symbols:edit"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginRight: 10,
                                                        }}
                                                        onClick={(e) => {
                                                          setSEODesc([
                                                            true,
                                                            3,
                                                            index,
                                                            index1,
                                                          ]);
                                                        }}
                                                      />
                                                      <select
                                                        className="next-input inline"
                                                        style={{
                                                          width: "200px",
                                                          marginRight: "20px",
                                                          marginBottom: "5px",
                                                          marginLeft: "0",
                                                        }}
                                                        value={
                                                          channelInfo &&
                                                          channelInfo.bagMenu &&
                                                          channelInfo.bagMenu[
                                                            index
                                                          ].arr &&
                                                          channelInfo.bagMenu[
                                                            index
                                                          ].arr[index1] &&
                                                          (channelInfo.bagMenu[
                                                            index
                                                          ].arr[index1]["id"] ??
                                                            "")
                                                        }
                                                        onChange={(e) => {
                                                          var t =
                                                            channelInfo.bagMenu;
                                                          t[index].arr[index1][
                                                            "id"
                                                          ] = e.target.value;
                                                          setChannelInfo(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                bagMenu: t,
                                                              };
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        <option value={""}>
                                                          Chọn cụm sản phẩm
                                                        </option>
                                                        {groupLabelList
                                                          .filter((option) => {
                                                            return (
                                                              option.type ===
                                                                channelInfo
                                                                  .bagMenu[
                                                                  index
                                                                ].arr[index1][
                                                                  "typeOfField"
                                                                ] &&
                                                              option.ofProductType.includes(
                                                                3
                                                              )
                                                            );
                                                          })
                                                          .map(
                                                            (ele2, index2) => {
                                                              return (
                                                                <option
                                                                  key={`${index2}`}
                                                                  value={
                                                                    ele2["_id"]
                                                                  }
                                                                >
                                                                  {ele2["name"]}
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                      </select>

                                                      <Icon
                                                        icon="eva:swap-fill"
                                                        style={{
                                                          cursor: "pointer",
                                                          marginRight: 10,
                                                        }}
                                                        onClick={(e) => {
                                                          var t =
                                                            channelInfo.bagMenu;
                                                          t[index].arr[index1][
                                                            "typeOfField"
                                                          ] =
                                                            1 -
                                                            t[index].arr[
                                                              index1
                                                            ]["typeOfField"];
                                                          setChannelInfo(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                bagMenu: t,
                                                              };
                                                            }
                                                          );
                                                        }}
                                                      />
                                                      <Icon
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        icon="ph:x-circle-fill"
                                                        onClick={(e) => {
                                                          var t =
                                                            channelInfo.bagMenu;
                                                          t[index].arr.splice(
                                                            index,
                                                            1
                                                          );
                                                          setChannelInfo(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                bagMenu: t,
                                                              };
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Phụ kiện
                        </label>
                        <Icon
                          style={{ cursor: "pointer" }}
                          icon="material-symbols:add-circle"
                          onClick={(e) => {
                            if (channelInfo.accessoryMenu.length < 6) {
                              var t = channelInfo.accessoryMenu;
                              t.push({ typeOfDt: "", arr: [] });
                              setChannelInfo((prev) => {
                                return { ...prev, accessoryMenu: t };
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              {channelInfo && channelInfo.accessoryMenu && (
                                <div>
                                  {channelInfo &&
                                    channelInfo.accessoryMenu &&
                                    channelInfo.accessoryMenu.map(
                                      (ele, index) => {
                                        return (
                                          <div key={`${index}`}>
                                            <div className="ml20 inline">
                                              <input
                                                placeholder="Đề mục"
                                                style={{ marginBottom: "5px" }}
                                                className="next-input mr20 inline width150"
                                                value={
                                                  channelInfo.accessoryMenu[
                                                    index
                                                  ].typeOfDt
                                                }
                                                onChange={(e) => {
                                                  var t =
                                                    channelInfo.accessoryMenu;
                                                  t[index].typeOfDt =
                                                    e.target.value;
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      accessoryMenu: t,
                                                    };
                                                  });
                                                }}
                                              />
                                              <input
                                                placeholder="Seo Link"
                                                style={{ marginBottom: "5px" }}
                                                className="next-input mr20 inline width150"
                                                value={
                                                  channelInfo.accessoryMenu[
                                                    index
                                                  ]?.SEOLink ?? ""
                                                }
                                                onChange={(e) => {
                                                  var t =
                                                    channelInfo.accessoryMenu;
                                                  t[index].SEOLink =
                                                    e.target.value
                                                      .trim()
                                                      .replace(/\s+/g, "");
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      accessoryMenu: t,
                                                    };
                                                  });
                                                }}
                                              />
                                              <Icon
                                                style={{
                                                  cursor: "pointer",
                                                  marginRight: "5px",
                                                }}
                                                icon="material-symbols:add-circle"
                                                onClick={(e) => {
                                                  if (
                                                    channelInfo.accessoryMenu[
                                                      index
                                                    ].arr.length < 10
                                                  ) {
                                                    var t =
                                                      channelInfo.accessoryMenu;
                                                    t[index].arr.push({
                                                      active: true,
                                                      field: "",
                                                      id: null,
                                                      SEOLink: "",
                                                      title: "",
                                                      description: "",
                                                      typeOfField: 0,
                                                    });
                                                    setChannelInfo((prev) => {
                                                      return {
                                                        ...prev,
                                                        accessoryMenu: t,
                                                      };
                                                    });
                                                  }
                                                }}
                                              />
                                              <Icon
                                                style={{ cursor: "pointer" }}
                                                icon="ph:x-circle-fill"
                                                onClick={(e) => {
                                                  var t =
                                                    channelInfo.accessoryMenu;
                                                  t.splice(index, 1);
                                                  setChannelInfo((prev) => {
                                                    return {
                                                      ...prev,
                                                      accessoryMenu: t,
                                                    };
                                                  });
                                                }}
                                              />
                                              {channelInfo &&
                                                channelInfo.accessoryMenu &&
                                                channelInfo.accessoryMenu[index]
                                                  .arr &&
                                                channelInfo.accessoryMenu[
                                                  index
                                                ].arr.map((ele1, index1) => {
                                                  return (
                                                    <div key={`${index1}`}>
                                                      <div
                                                        className="ml50 inline"
                                                        style={{
                                                          marginBottom: "5px",
                                                        }}
                                                      >
                                                        <input
                                                          placeholder="Phân loại"
                                                          className="next-input mr20 inline width150"
                                                          value={
                                                            channelInfo
                                                              .accessoryMenu[
                                                              index
                                                            ].arr[index1][
                                                              "field"
                                                            ]
                                                          }
                                                          onChange={(e) => {
                                                            var t =
                                                              channelInfo.accessoryMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["field"] =
                                                              e.target.value;
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  accessoryMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />
                                                        <input
                                                          placeholder="Seo Link"
                                                          className="next-input mr20 inline width150"
                                                          value={
                                                            channelInfo
                                                              .accessoryMenu[
                                                              index
                                                            ].arr[index1][
                                                              "SEOLink"
                                                            ]
                                                          }
                                                          onChange={(e) => {
                                                            var t =
                                                              channelInfo.accessoryMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["SEOLink"] =
                                                              e.target.value
                                                                .trim()
                                                                .replace(
                                                                  /\s+/g,
                                                                  ""
                                                                );
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  accessoryMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />

                                                        <Icon
                                                          icon="material-symbols:edit"
                                                          style={{
                                                            cursor: "pointer",
                                                            marginRight: 10,
                                                          }}
                                                          onClick={(e) => {
                                                            setSEODesc([
                                                              true,
                                                              4,
                                                              index,
                                                              index1,
                                                            ]);
                                                          }}
                                                        />
                                                        <select
                                                          className="next-input inline"
                                                          style={{
                                                            width: "200px",
                                                            marginRight: "20px",
                                                            marginBottom: "5px",
                                                            marginLeft: "0",
                                                          }}
                                                          value={
                                                            channelInfo &&
                                                            channelInfo.accessoryMenu &&
                                                            channelInfo
                                                              .accessoryMenu[
                                                              index
                                                            ].arr &&
                                                            channelInfo
                                                              .accessoryMenu[
                                                              index
                                                            ].arr[index1] &&
                                                            (channelInfo
                                                              .accessoryMenu[
                                                              index
                                                            ].arr[index1][
                                                              "id"
                                                            ] ??
                                                              "")
                                                          }
                                                          onChange={(e) => {
                                                            var t =
                                                              channelInfo.accessoryMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["id"] =
                                                              e.target.value;
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  accessoryMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        >
                                                          <option value={""}>
                                                            Chọn cụm sản phẩm
                                                          </option>
                                                          {groupLabelList
                                                            .filter(
                                                              (option) => {
                                                                return (
                                                                  option.type ===
                                                                    channelInfo
                                                                      .accessoryMenu[
                                                                      index
                                                                    ].arr[
                                                                      index1
                                                                    ][
                                                                      "typeOfField"
                                                                    ] &&
                                                                  option.ofProductType.includes(
                                                                    4
                                                                  )
                                                                );
                                                              }
                                                            )
                                                            .map(
                                                              (
                                                                ele2,
                                                                index2
                                                              ) => {
                                                                return (
                                                                  <option
                                                                    key={`${index2}`}
                                                                    value={
                                                                      ele2[
                                                                        "_id"
                                                                      ]
                                                                    }
                                                                  >
                                                                    {
                                                                      ele2[
                                                                        "name"
                                                                      ]
                                                                    }
                                                                  </option>
                                                                );
                                                              }
                                                            )}
                                                        </select>

                                                        <Icon
                                                          icon="eva:swap-fill"
                                                          style={{
                                                            cursor: "pointer",
                                                            marginRight: 10,
                                                          }}
                                                          onClick={(e) => {
                                                            var t =
                                                              channelInfo.accessoryMenu;
                                                            t[index].arr[
                                                              index1
                                                            ]["typeOfField"] =
                                                              1 -
                                                              t[index].arr[
                                                                index1
                                                              ]["typeOfField"];
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  accessoryMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />
                                                        <Icon
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          icon="ph:x-circle-fill"
                                                          onClick={(e) => {
                                                            var t =
                                                              channelInfo.accessoryMenu;
                                                            t[index].arr.splice(
                                                              index,
                                                              1
                                                            );
                                                            setChannelInfo(
                                                              (prev) => {
                                                                return {
                                                                  ...prev,
                                                                  accessoryMenu:
                                                                    t,
                                                                };
                                                              }
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Vali
                        </label>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Tiêu đề trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.luggageTitle ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập tiêu đề"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            luggageTitle: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Mô tả trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.luggageDesc ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập mô tả"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            luggageDesc: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Vali size Cabin
                        </label>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Tiêu đề trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.cabinTitle ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập tiêu đề"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            cabinTitle: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Mô tả trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.cabinDesc ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập mô tả"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            cabinDesc: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Vali size Trung
                        </label>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Tiêu đề trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.mediumTitle ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập tiêu đề"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            mediumTitle: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Mô tả trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.mediumDesc ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập mô tả"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            mediumDesc: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Vali size Đại
                        </label>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Tiêu đề trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.largeTitle ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập tiêu đề"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            largeTitle: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Mô tả trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.largeDesc ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập mô tả"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            largeDesc: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Balo
                        </label>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Tiêu đề trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.backpackTitle ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập tiêu đề"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            backpackTitle: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Mô tả trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.backpackDesc ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập mô tả"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            backpackDesc: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Túi xách
                        </label>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Tiêu đề trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.bagTitle ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập tiêu đề"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            bagTitle: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Mô tả trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.bagDesc ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập mô tả"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            bagDesc: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <label className="label-input-group width100 mr20">
                          Phụ kiên
                        </label>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Tiêu đề trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.accessoryTitle ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập tiêu đề"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            accessoryTitle: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="form-group px-0 mgb0"
                                style={{ marginTop: "5px" }}
                              >
                                <label className="label-input-group">
                                  Mô tả trang
                                </label>
                                <div className="buttonDiv">
                                  <input
                                    value={
                                      channelInfo?.menuTitle?.accessoryDesc ?? ""
                                    }
                                    className="next-input"
                                    placeholder="Nhập mô tả"
                                    onChange={(e) => {
                                      setChannelInfo((prev) => {
                                        return {
                                          ...prev,
                                          menuTitle: {
                                            ...(channelInfo?.menuTitle ?? {}),
                                            accessoryDesc: e.target.value,
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Xác nhận cập nhật mục lục kênh?"
                                      )
                                    ) {
                                      updateChannel();
                                    }
                                  }}
                                >
                                  Cập nhật
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Menu;
