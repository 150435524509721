import React, { useEffect, useState } from "react";

function CachingTab({ setCachingTab }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;

  const [temp, setTemp] = useState([]);
  const [allImg, setAllImg] = useState([]);

  useEffect(() => {
    fetch(`${apiURL}/image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setTemp(result?.imgList ?? []);
        } else {
          alert("Có lỗi");
        }
      });
  }, []);

  return (
    <React.Fragment>
      <div
        className="toggleTab"
        onClick={(e) => {
          setCachingTab(false);
        }}
      >
        <div
          style={{
            maxHeight: "90%",
            overflowY: "auto",
            height: "80%",
            width: "50%",
            maxWidth: "none",
          }}
          className="childTab"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <h3 className="vodal-title ">Caching</h3>

            <div className="right-button" style={{marginBottom:"20px"}}>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  setAllImg(temp);
                }}
              >
                Caching
              </button>
            </div>

            {allImg.length > 0 &&
              allImg.map((ele, index) => {
                return (
                  <div key={index} className="img_box_dis" style={{
                    display : "inline-block"}}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display : "inline-block"
                      }}
                    >
                      <div className="img_each_create">
                        <img
                          className="img"
                          src={`${cloudFrontURL}/${ele}`}
                          alt="none"
                        ></img>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CachingTab;
