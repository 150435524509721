import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import * as XLSX from "xlsx";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function AddStore({ setAddStoreTab, setTrigger, cityListFilter }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [coordinate, setCoordinate] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(true);

  // Biểu thức chính quy kiểm tra định dạng iframe
  const iframeRegex = /<iframe[^>]*>[^<]*<\/iframe>/;

  // Kiểm tra nếu coordinate khớp với định dạng iframe
  const isIframe = iframeRegex.test(coordinate);

  const createStore = () => {
    if (
      name === "" ||
      coordinate === "" ||
      address === "" ||
      phone === "" ||
      city === ""
    ) {
      alert("Điền đầy đủ thông tin");
      return;
    } else {
      fetch(`${apiURL}/store`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({
          phone,
          name,
          coordinate,
          address,
          city,
          show,
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            alert("Tạo cửa hàng thành công");
            setTrigger((prev) => {
              return !prev;
            });
          } else {
            alert("Có lỗi, thử đổi tên và thử lại");
          }
        });
    }
  };

  return (
    <React.Fragment>
      <div
        className="toggleTab"
        onClick={(e) => {
          setAddStoreTab(false);
          setTrigger((prev) => {
            return !prev;
          });
        }}
      >
        <div
          style={{
            maxHeight: "80%",
            overflowY: "auto",
          }}
          className="childTab"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <h3 className="vodal-title ">Thêm cửa hàng</h3>

            <div className="form-group px-0 mgt20 mgb0">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={show}
                      onChange={(e) => {
                        return setShow(e.target.checked);
                      }}
                    />
                  }
                  label="Hoạt động"
                />
              </FormGroup>
            </div>
            <div className="form-group  px-0 inline mgb0">
              <label className="label-input-group">Chọn thành phố</label>
              <select
                className="next-input"
                style={{
                  width: "200px",
                  marginRight: "20px",
                  marginBottom: "5px",
                  marginLeft: "0",
                }}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              >
                <option value="">Tất cả</option>
                {cityListFilter &&
                  cityListFilter.map((ele, index) => {
                    return (
                      <option key={index} value={ele._id}>
                        {ele.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="form-group px-0 mgb0">
              <label className="label-input-group">Tên cửa hàng</label>
              <div className="buttonDiv">
                <input
                  value={name}
                  className="next-input"
                  placeholder="Nhập tên"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group px-0 mgb0">
              <label className="label-input-group">Địa chỉ</label>
              <div className="buttonDiv">
                <input
                  value={address}
                  className="next-input"
                  placeholder="Nhập địa chỉ"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group px-0 mgb0">
              <label className="label-input-group">Tọa độ</label>
              <div className="buttonDiv">
                <input
                  value={coordinate}
                  className="next-input"
                  placeholder="Nhập tọa độ"
                  onChange={(e) => {
                    setCoordinate(e.target.value);
                  }}
                />
              </div>
              {isIframe && (
                <div dangerouslySetInnerHTML={{ __html: coordinate }}></div>
              )}
            </div>
            <div className="form-group px-0 mgb0">
              <label className="label-input-group">Điện thoại</label>
              <div className="buttonDiv">
                <input
                  value={phone}
                  className="next-input"
                  placeholder="Điện thoại"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="right-button">
              <button
                className="btn btn-primary text-right"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm("Xác nhận tạo cửa hàng?")) {
                    createStore();
                  }
                }}
              >
                Thêm
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddStore;
