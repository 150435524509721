import React, { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { moneyFormat, dateFormat } from "../../helper";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import * as XLSX from "xlsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useSearchParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import Autocomplete from "@mui/material/Autocomplete";

// có id thì lấy GWP info, không có thì là tạo
function GiftWithPurchaseInfo({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  // state = 1 là thêm, state = 2 là get info
  const [state, setState] = useState(1);

  const [searchParams] = useSearchParams();
  const [focus, setFocus] = useState({});
  const [remainList, setRemainList] = useState({})
  const [giftWithPurchase, setGiftWithPurchase] = useState({
    name: "",
    giftWithPurchaseProgramCode: "",
    description: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    show: true,
    // Các loại khuyến mãi gift
    // 1 : Mua đủ số tiền sẽ tặng 1 món quà, mỗi mốc tiền có 1 danh sách sku quà, lấy theo ưu tiên
    // 2 : Mua số lượng (xet theo loại hàng vali, balo,..), cũng ưu tiên xét theo mốc điều kiện nào thỏa mãn sẽ lấy
    // 3 : mua 1 sp thuộc bộ sưu tập (type của productGroupCategory = 1) (tìm trong ProductGroupCategory, phải trùng product Type) của product thì được tặng 1 sp, cũng xếp theo ưu tiên
    GWPType: 1,
    // GWPType = 1, theo giá trị tổng đơn
    amountType: {
      mileStones: 1,
      list: [
        {
          amount: 0,
          gift: [],
        },
      ],
    },
    // GWPType = 2, theo số lượng hàng mua
    categoryAmountType: {
      mileStones: 1,
      list: [
        {
          amount: [0, 0, 0, 0],
          gift: [],
        },
      ],
    },
    // GWPType = 3, theo số phân loại hàng
    productGroupAmountType: {
      mileStones: 1,
      list: [
        {
          amount: 0,
          collection: null,
          gift: [],
        },
      ],
    },
  });
  const [groupLabelListAll, setGroupLabelListAll] = useState([]);

  useEffect(() => {
    setSelect("giftwithpurchase");
    let id = searchParams.get("id") ?? "";
    if (id === "new") {
      setState(1);
    } else {
      setState(2);
      if (id !== "") {
        fetch(`${apiURL}/giftWithPurchase/search?id=` + id, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + localStorage.banyanAdminToken
                ? localStorage.banyanAdminToken
                : "",
          },
        })
          .then((res) => {
            if (res.ok) return res.json();
            throw new Error("Something went wrong");
          })
          .then((result) => {
            if (result.success) {
              setGiftWithPurchase(result?.giftWithPurchase ?? {});
              setRemainList(result?.remainList ?? {})
            } else {
              alert(result.mes);
            }
          });
      }
    }

    fetch(`${apiURL}/product/getchanneldetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        type: "groupcategory",
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setGroupLabelListAll(result.list);
        }
      });
  }, []);

  const handleFocus = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: true,
    }));
  };

  const createGiftWithPurchase = (e) => {
    if (
      dateFormat(giftWithPurchase.dateFrom) === "Invalid Date Invalid Date" ||
      dateFormat(giftWithPurchase.dateTo) === "Invalid Date Invalid Date"
    ) {
      alert("Nhập đúng định dạng ngày");
      return;
    }
    if (giftWithPurchase.dateFrom >= giftWithPurchase.dateTo) {
      alert("Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
      return;
    }
    if (giftWithPurchase.name === "") {
      alert("Nhập tên chương trình");
      return;
    }
    if (giftWithPurchase.giftWithPurchaseProgramCode === "") {
      alert("Nhập mã chương trình");
      return;
    }

    if (window.confirm("Tạo chương trình?")) {
      fetch(`${apiURL}/giftWithPurchase`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({ ...giftWithPurchase }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            alert("Tạo thành công");
            // Chuyển hướng đến đường dẫn mong muốn
            window.location.href = `/giftwithpurchase/giftwithpurchaseinfo?id=${result?._id ?? ""}`;
          } else {
            alert(result.message ?? "Lỗi");
          }
        });
        
    }
  };

  const updateGiftWithPurchase = (e) => {
    if (
      dateFormat(giftWithPurchase.dateFrom) === "Invalid Date Invalid Date" ||
      dateFormat(giftWithPurchase.dateTo) === "Invalid Date Invalid Date"
    ) {
      alert("Nhập đúng định dạng ngày");
      return;
    }
    if (giftWithPurchase.dateFrom >= giftWithPurchase.dateTo) {
      alert("Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
      return;
    }
    if (giftWithPurchase.name === "") {
      alert("Nhập tên chương trình");
      return;
    }
    if (giftWithPurchase.giftWithPurchaseProgramCode === "") {
      alert("Nhập mã chương trình");
      return;
    }

    if (window.confirm("Cập nhật chương trình?")) {
      fetch(`${apiURL}/giftWithPurchase`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({ ...giftWithPurchase }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            alert("Cập nhật thành công");
          } else {
            alert(result.message ?? "Lỗi");
          }
        });
    }
  };

  const handleBlur = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: false,
    }));
  };

  const handleFileChange = (e, type, index) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const dataArr = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const newArr = [];
      dataArr.length > 0 &&
        dataArr.map((ele, index) => {
          if (ele[0]) {
            if (
              typeof ele[0] === "string" &&
              ele[0] !== "" &&
              !newArr.includes(ele[0])
            ) {
              newArr.push(String(ele[0]));
            }
          }
        });

      // set gwp
      let temp = [];
      if (type === 1) {
        temp = [...giftWithPurchase.amountType.list];
      } else if (type === 2) {
        temp = [...giftWithPurchase.categoryAmountType.list];
      } else if (type === 3) {
        temp = [...giftWithPurchase.productGroupAmountType.list];
      }
      temp[index].gift = newArr;

      if (type === 1) {
        return setGiftWithPurchase({
          ...giftWithPurchase,
          amountType: {
            ...giftWithPurchase.amountType,
            list: temp,
          },
        });
      } else if (type === 2) {
        return setGiftWithPurchase({
          ...giftWithPurchase,
          categoryAmountType: {
            ...giftWithPurchase.categoryAmountType,
            list: temp,
          },
        });
      } else if (type === 3) {
        return setGiftWithPurchase({
          ...giftWithPurchase,
          productGroupAmountType: {
            ...giftWithPurchase.productGroupAmountType,
            list: temp,
          },
        });
      }
    };
    reader.readAsBinaryString(file);
  };

  const columns = [
    {
      field: "sku",
      headerName: "SKU",
      width: 150,
      cellClassName: "centered-cell",
    },
    {
      field: "remain",
      headerName: "Tồn",
      type: "number",
      width: 150,
      cellClassName: "centered-cell",
    },
  ];

  return (
    <React.Fragment>
      <div className="page">
        <div className="edit_container">
          <section className="edit_section">
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Thông tin cơ bản</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span></span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={giftWithPurchase?.show ?? true}
                                  onChange={(e) => {
                                    let temp = {
                                      ...giftWithPurchase,
                                      show: e.target.checked,
                                    };
                                    return setGiftWithPurchase({ ...temp });
                                  }}
                                />
                              }
                              label="Kích hoạt"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <div className="mandatory">
                          <div className="mandatory-icon">*</div>
                        </div>
                        <span>Tên chương trình</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.name ? "focused" : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  value={giftWithPurchase?.name ?? ""}
                                  onChange={(e) => {
                                    let temp = {
                                      ...giftWithPurchase,
                                      name: e.target.value,
                                    };
                                    return setGiftWithPurchase({ ...temp });
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("name")}
                                  onBlur={() => handleBlur("name")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <div className="mandatory">
                          <div className="mandatory-icon">*</div>
                        </div>
                        <span>Mã chương trình</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.giftWithPurchaseProgramCode
                                    ? "focused"
                                    : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  disabled={state === 2}
                                  value={
                                    giftWithPurchase?.giftWithPurchaseProgramCode ??
                                    ""
                                  }
                                  onChange={(e) => {
                                    const regex = /^[a-zA-Z0-9-_ ]*$/;
                                    const value = e.target.value;
                                    if (regex.test(value)) {
                                      let temp = {
                                        ...giftWithPurchase,
                                        giftWithPurchaseProgramCode: value,
                                      };
                                      return setGiftWithPurchase({ ...temp });
                                    }
                                  }}
                                  className="input_input"
                                  onFocus={() =>
                                    handleFocus("giftWithPurchaseProgramCode")
                                  }
                                  onBlur={() =>
                                    handleBlur("giftWithPurchaseProgramCode")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Mô tả</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.description ? "focused" : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  value={giftWithPurchase?.description ?? ""}
                                  onChange={(e) => {
                                    let temp = {
                                      ...giftWithPurchase,
                                      description: e.target.value,
                                    };
                                    return setGiftWithPurchase({ ...temp });
                                  }}
                                  className="input_input"
                                  onFocus={() =>
                                    handleFocus("giftWithPurchaseProgramCode")
                                  }
                                  onBlur={() =>
                                    handleBlur("giftWithPurchaseProgramCode")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="edit_section" style={{ marginBottom: "50px" }}>
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Chi tiết chương trình</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <div className="mandatory">
                          <div className="mandatory-icon">*</div>
                        </div>
                        <span>Thời gian</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className="form-group px-0 width300 inline mr20"
                                style={{ marginBottom: 0 }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Stack spacing={3}>
                                    <DesktopDateTimePicker
                                      views={[
                                        "month",
                                        "day",
                                        "hours",
                                        "minutes",
                                      ]}
                                      inputFormat="DD/MM/YYYY HH:mm"
                                      label="Ngày bắt đầu"
                                      value={
                                        giftWithPurchase?.dateFrom ?? new Date()
                                      }
                                      onChange={(newValue) => {
                                        let temp = {
                                          ...giftWithPurchase,
                                          dateFrom: newValue,
                                        };
                                        return setGiftWithPurchase({ ...temp });
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>
                              <div
                                className="form-group px-0 width300 inline "
                                style={{ marginBottom: 0 }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Stack spacing={3}>
                                    <DesktopDateTimePicker
                                      views={[
                                        "month",
                                        "day",
                                        "hours",
                                        "minutes",
                                      ]}
                                      inputFormat="DD/MM/YYYY HH:mm"
                                      label="Ngày kết thúc"
                                      value={
                                        giftWithPurchase?.dateTo ?? new Date()
                                      }
                                      onChange={(newValue) => {
                                        let temp = {
                                          ...giftWithPurchase,
                                          dateTo: newValue,
                                        };
                                        return setGiftWithPurchase({ ...temp });
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <div className="mandatory"></div>
                        <span>Loại chương trình</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div className="">
                                {giftWithPurchase.GWPType && <FormControl>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={giftWithPurchase.GWPType}
                                    name="radio-buttons-group"
                                    onChange={(e) => {
                                      let temp = {
                                        ...giftWithPurchase,
                                        GWPType: Number(e.target.value),
                                      };
                                      return setGiftWithPurchase({ ...temp });
                                    }}
                                  >
                                    <FormControlLabel
                                      value={1}
                                      control={<Radio />}
                                      label="Theo tổng tiền mua"
                                    />
                                    <FormControlLabel
                                      value={2}
                                      control={<Radio />}
                                      label="Theo mặt hàng"
                                    />
                                    <FormControlLabel
                                      value={3}
                                      control={<Radio />}
                                      label="Theo bộ sưu tập"
                                    />
                                  </RadioGroup>
                                </FormControl>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* type 1 */}
                    {giftWithPurchase &&
                      (giftWithPurchase?.GWPType ?? 0) === 1 &&
                      giftWithPurchase &&
                      new Array(
                        Number(giftWithPurchase?.amountType?.mileStones ?? 0)
                      )
                        .fill("a")
                        .map((ele, index) => {
                          return (
                            <React.Fragment
                              key={
                                index + giftWithPurchase.amountType.mileStones
                              }
                            >
                              <div className="edit-row">
                                <div className="edit-label edit-title">
                                  <div className="mandatory"></div>
                                  <span>{`Mốc ${index + 1}`}</span>
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                    icon="material-symbols:add-circle"
                                    onClick={() => {
                                      let temp = [
                                        ...giftWithPurchase.amountType.list,
                                      ];
                                      let elementToInsert = {
                                        amount: 0,
                                        gift: [],
                                      };
                                      temp.splice(
                                        index + 1,
                                        0,
                                        elementToInsert
                                      );
                                      return setGiftWithPurchase({
                                        ...giftWithPurchase,
                                        amountType: {
                                          ...giftWithPurchase.amountType,
                                          list: temp,
                                          mileStones: temp.length,
                                        },
                                      });
                                    }}
                                  />
                                  <Icon
                                    icon="clarity:remove-solid"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                      visibility:
                                        Number(
                                          giftWithPurchase.amountType.mileStones
                                        ) > 1
                                          ? "normal"
                                          : "hidden",
                                    }}
                                    onClick={() => {
                                      let temp = [
                                        ...giftWithPurchase.amountType.list,
                                      ];
                                      temp.splice(index, 1);
                                      return setGiftWithPurchase({
                                        ...giftWithPurchase,
                                        amountType: {
                                          ...giftWithPurchase.amountType,
                                          list: temp,
                                          mileStones: temp.length,
                                        },
                                      });
                                    }}
                                  />
                                </div>

                                <div className="edit-main">
                                  <div className="popover-wrap">
                                    <div className="variation-edit-item">
                                      <div className="variation-edit-panel">
                                        <Box
                                          component="form"
                                          size="small"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <div>
                                            <Paper
                                              elevation={0}
                                              style={{ background: "#ffffff" }}
                                            >
                                              <TextField
                                                style={{ minWidth: "100%" }}
                                                label="Giá tiền"
                                                id="outlined-size-small"
                                                size="small"
                                                value={moneyFormat(
                                                  giftWithPurchase?.amountType
                                                    ?.list[index].amount ?? 0
                                                )}
                                                onChange={(e) => {
                                                  const value = e.target.value
                                                    .replace(/^0+/, "")
                                                    .replace(/[^0-9]/g, "");
                                                  let temp = [
                                                    ...giftWithPurchase
                                                      .amountType.list,
                                                  ];
                                                  temp[index].amount =
                                                    Number(value);
                                                  return setGiftWithPurchase({
                                                    ...giftWithPurchase,
                                                    amountType: {
                                                      ...giftWithPurchase.amountType,
                                                      list: temp,
                                                    },
                                                  });
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </Box>
                                      </div>
                                      <div className="variation-edit-panel">
                                        <div className="variation-edit-">
                                          File xls gồm 1 cột: SKU cột A, bắt đầu
                                          từ ô A1
                                        </div>
                                      </div>
                                      <div className="product-edit-form-item-content">
                                        <div style={{ marginBottom: "15px" }}>
                                          <input
                                            type="file"
                                            onChange={(e) => {
                                              return handleFileChange(
                                                e,
                                                giftWithPurchase?.GWPType,
                                                index
                                              );
                                            }}
                                            accept=".xlsx, .xls"
                                          />
                                        </div>
                                      </div>

                                      <div className="popover-wrap">
                                        <div className="product-edit-form-item-content">
                                          <div style={{ lineHeight: "0px" }}>
                                            <div
                                              style={{
                                                height: 300,
                                                width: "100%",
                                              }}
                                            >
                                              <DataGrid
                                                style={{background:"white"}}
                                                rows={giftWithPurchase.amountType.list[
                                                  index
                                                ].gift.reduce(
                                                  (pre, cur, index1) => {
                                                    return [
                                                      ...pre,
                                                      { id: index1, sku: cur, remain : (remainList[cur] ?? 0) },
                                                    ];
                                                  },
                                                  []
                                                )}
                                                columns={columns}
                                                disableSelectionOnClick
                                                initialState={{
                                                  pagination: {
                                                    paginationModel: {
                                                      page: 0,
                                                      pageSize: 5,
                                                    },
                                                  },
                                                }}
                                                pageSizeOptions={[5, 10]}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}

                    {/* type 2 */}
                    {giftWithPurchase &&
                      (giftWithPurchase?.GWPType ?? 0) === 2 &&
                      giftWithPurchase &&
                      new Array(
                        Number(
                          giftWithPurchase?.categoryAmountType?.mileStones ?? 0
                        )
                      )
                        .fill("a")
                        .map((ele, index) => {
                          return (
                            <React.Fragment
                              key={
                                index +
                                giftWithPurchase.categoryAmountType.mileStones
                              }
                            >
                              <div className="edit-row">
                                <div className="edit-label edit-title">
                                  <div className="mandatory"></div>
                                  <span>{`Mốc ${index + 1}`}</span>
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                    icon="material-symbols:add-circle"
                                    onClick={() => {
                                      let temp = [
                                        ...giftWithPurchase.categoryAmountType
                                          .list,
                                      ];
                                      let elementToInsert = {
                                        amount: [0,0,0,0],
                                        gift: [],
                                      };
                                      temp.splice(
                                        index + 1,
                                        0,
                                        elementToInsert
                                      );
                                      return setGiftWithPurchase({
                                        ...giftWithPurchase,
                                        categoryAmountType: {
                                          ...giftWithPurchase.categoryAmountType,
                                          list: temp,
                                          mileStones: temp.length,
                                        },
                                      });
                                    }}
                                  />
                                  <Icon
                                    icon="clarity:remove-solid"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                      visibility:
                                        Number(
                                          giftWithPurchase.categoryAmountType
                                            .mileStones
                                        ) > 1
                                          ? "normal"
                                          : "hidden",
                                    }}
                                    onClick={() => {
                                      let temp = [
                                        ...giftWithPurchase.categoryAmountType
                                          .list,
                                      ];
                                      temp.splice(index, 1);
                                      return setGiftWithPurchase({
                                        ...giftWithPurchase,
                                        categoryAmountType: {
                                          ...giftWithPurchase.categoryAmountType,
                                          list: temp,
                                          mileStones: temp.length,
                                        },
                                      });
                                    }}
                                  />
                                </div>

                                <div className="edit-main">
                                  <div className="popover-wrap">
                                    <div className="variation-edit-item">
                                      <div className="variation-edit-panel">
                                        <Box
                                          component="form"
                                          size="small"
                                          style={{
                                            marginBottom: "10px",
                                            marginRight: "15px",
                                          }}
                                        >
                                          <div>
                                            <Paper
                                              elevation={0}
                                              style={{ background: "#ffffff" }}
                                            >
                                              <TextField
                                                style={{ minWidth: "100%" }}
                                                label="Vali"
                                                id="outlined-size-small"
                                                size="small"
                                                type="number"
                                                value={
                                                  giftWithPurchase
                                                    ?.categoryAmountType?.list[
                                                    index
                                                  ].amount[0] ?? 0
                                                }
                                                onChange={(e) => {
                                                  let temp = [
                                                    ...giftWithPurchase
                                                      .categoryAmountType.list,
                                                  ];

                                                  const value = e.target.value
                                                    .replace(/^0+/, "")
                                                    .replace(/[^0-9]/g, "");
                                                  temp[index].amount[0] =
                                                    Number(value);
                                                  return setGiftWithPurchase({
                                                    ...giftWithPurchase,
                                                    categoryAmountType: {
                                                      ...giftWithPurchase.categoryAmountType,
                                                      list: temp,
                                                    },
                                                  });
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </Box>

                                        <Box
                                          component="form"
                                          size="small"
                                          style={{
                                            marginBottom: "10px",
                                            marginRight: "15px",
                                          }}
                                        >
                                          <div>
                                            <Paper
                                              elevation={0}
                                              style={{ background: "#ffffff" }}
                                            >
                                              <TextField
                                                style={{ minWidth: "100%" }}
                                                label="Balo"
                                                id="outlined-size-small"
                                                size="small"
                                                type="number"
                                                value={
                                                  giftWithPurchase
                                                    ?.categoryAmountType?.list[
                                                    index
                                                  ].amount[1] ?? 0
                                                }
                                                onChange={(e) => {
                                                  let temp = [
                                                    ...giftWithPurchase
                                                      .categoryAmountType.list,
                                                  ];

                                                  const value = e.target.value
                                                    .replace(/^0+/, "")
                                                    .replace(/[^0-9]/g, "");
                                                  temp[index].amount[1] =
                                                    Number(value);
                                                  return setGiftWithPurchase({
                                                    ...giftWithPurchase,
                                                    categoryAmountType: {
                                                      ...giftWithPurchase.categoryAmountType,
                                                      list: temp,
                                                    },
                                                  });
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </Box>
                                        <Box
                                          component="form"
                                          size="small"
                                          style={{
                                            marginBottom: "10px",
                                            marginRight: "15px",
                                          }}
                                        >
                                          <div>
                                            <Paper
                                              elevation={0}
                                              style={{ background: "#ffffff" }}
                                            >
                                              <TextField
                                                style={{ minWidth: "100%" }}
                                                label="Túi xách"
                                                id="outlined-size-small"
                                                size="small"
                                                type="number"
                                                value={
                                                  giftWithPurchase
                                                    ?.categoryAmountType?.list[
                                                    index
                                                  ].amount[2] ?? 0
                                                }
                                                onChange={(e) => {
                                                  let temp = [
                                                    ...giftWithPurchase
                                                      .categoryAmountType.list,
                                                  ];

                                                  const value = e.target.value
                                                    .replace(/^0+/, "")
                                                    .replace(/[^0-9]/g, "");
                                                  temp[index].amount[2] =
                                                    Number(value);
                                                  return setGiftWithPurchase({
                                                    ...giftWithPurchase,
                                                    categoryAmountType: {
                                                      ...giftWithPurchase.categoryAmountType,
                                                      list: temp,
                                                    },
                                                  });
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </Box>
                                        <Box
                                          component="form"
                                          size="small"
                                          style={{
                                            marginBottom: "10px",
                                            marginRight: "15px",
                                          }}
                                        >
                                          <div>
                                            <Paper
                                              elevation={0}
                                              style={{ background: "#ffffff" }}
                                            >
                                              <TextField
                                                style={{ minWidth: "100%" }}
                                                label="Phụ kiện"
                                                id="outlined-size-small"
                                                size="small"
                                                type="number"
                                                value={
                                                  giftWithPurchase
                                                    ?.categoryAmountType?.list[
                                                    index
                                                  ].amount[3] ?? 0
                                                }
                                                onChange={(e) => {
                                                  let temp = [
                                                    ...giftWithPurchase
                                                      .categoryAmountType.list,
                                                  ];

                                                  const value = e.target.value
                                                    .replace(/^0+/, "")
                                                    .replace(/[^0-9]/g, "");
                                                  temp[index].amount[3] =
                                                    Number(value);
                                                  return setGiftWithPurchase({
                                                    ...giftWithPurchase,
                                                    categoryAmountType: {
                                                      ...giftWithPurchase.categoryAmountType,
                                                      list: temp,
                                                    },
                                                  });
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </Box>
                                      </div>
                                      <div className="variation-edit-panel">
                                        <div className="variation-edit-">
                                          File xls gồm 1 cột: SKU cột A, bắt đầu
                                          từ ô A1
                                        </div>
                                      </div>
                                      <div className="product-edit-form-item-content">
                                        <div style={{ marginBottom: "15px" }}>
                                          <input
                                            type="file"
                                            onChange={(e) => {
                                              return handleFileChange(
                                                e,
                                                giftWithPurchase?.GWPType,
                                                index
                                              );
                                            }}
                                            accept=".xlsx, .xls"
                                          />
                                        </div>
                                      </div>

                                      <div className="popover-wrap">
                                        <div className="product-edit-form-item-content">
                                          <div style={{ lineHeight: "0px" }}>
                                            <div
                                              style={{
                                                height: 300,
                                                width: "100%",
                                              }}
                                            >
                                              <DataGrid
                                                style={{background:"white"}}
                                                rows={giftWithPurchase.categoryAmountType.list[
                                                  index
                                                ].gift.reduce(
                                                  (pre, cur, index1) => {
                                                    return [
                                                      ...pre,
                                                      { id: index1, sku: cur, remain : (remainList[cur] ?? 0) },
                                                    ];
                                                  },
                                                  []
                                                )}
                                                columns={columns}
                                                disableSelectionOnClick
                                                initialState={{
                                                  pagination: {
                                                    paginationModel: {
                                                      page: 0,
                                                      pageSize: 5,
                                                    },
                                                  },
                                                }}
                                                pageSizeOptions={[5, 10]}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}

                    {/* type 3 */}
                    {giftWithPurchase &&
                      (giftWithPurchase?.GWPType ?? 0) === 3 &&
                      giftWithPurchase &&
                      new Array(
                        Number(
                          giftWithPurchase?.productGroupAmountType
                            ?.mileStones ?? 0
                        )
                      )
                        .fill("a")
                        .map((ele, index) => {
                          return (
                            <React.Fragment
                              key={
                                index +
                                giftWithPurchase.productGroupAmountType
                                  .mileStones
                              }
                            >
                              <div className="edit-row">
                                <div className="edit-label edit-title">
                                  <div className="mandatory"></div>
                                  <span>{`Mốc ${index + 1}`}</span>
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                    }}
                                    icon="material-symbols:add-circle"
                                    onClick={() => {
                                      let temp = [
                                        ...giftWithPurchase
                                          .productGroupAmountType.list,
                                      ];
                                      let elementToInsert = {
                                        amount: 0,
                                        gift: [],
                                        collection: "",
                                      };
                                      temp.splice(
                                        index + 1,
                                        0,
                                        elementToInsert
                                      );
                                      return setGiftWithPurchase({
                                        ...giftWithPurchase,
                                        productGroupAmountType: {
                                          ...giftWithPurchase.productGroupAmountType,
                                          list: temp,
                                          mileStones: temp.length,
                                        },
                                      });
                                    }}
                                  />
                                  <Icon
                                    icon="clarity:remove-solid"
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "5px",
                                      visibility:
                                        Number(
                                          giftWithPurchase
                                            .productGroupAmountType.mileStones
                                        ) > 1
                                          ? "normal"
                                          : "hidden",
                                    }}
                                    onClick={() => {
                                      let temp = [
                                        ...giftWithPurchase
                                          .productGroupAmountType.list,
                                      ];
                                      temp.splice(index, 1);
                                      return setGiftWithPurchase({
                                        ...giftWithPurchase,
                                        productGroupAmountType: {
                                          ...giftWithPurchase.productGroupAmountType,
                                          list: temp,
                                          mileStones: temp.length,
                                        },
                                      });
                                    }}
                                  />
                                </div>

                                <div className="edit-main">
                                  <div className="popover-wrap">
                                    <div className="variation-edit-item">
                                      <div style={{ lineHeight: "40px" }}>
                                        <div
                                          className="px-0 inline"
                                          style={{
                                            position: "relative",
                                            width: "100%",
                                            marginTop : 10,
                                            marginBottom : 10
                                          }}
                                        >
                                          <div
                                            className=""
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                            }}
                                          >
                                            <div
                                              style={{
                                                position: "relative",
                                                width: "100%",
                                              }}
                                            >
                                              {groupLabelListAll && (
                                                <Autocomplete
                                                  style={{background:"white"}}
                                                  sx={{ minWidth: 120 }}
                                                  id="tags-outlined"
                                                  size="small"
                                                  options={groupLabelListAll.filter(
                                                    (v, i) => v.type === 1
                                                  ).sort((a,b) => a.name - b.name)}
                                                  // tên hiển thị
                                                  getOptionLabel={(option) =>
                                                    option.name
                                                  }
                                                  renderInput={(params) => (
                                                    <Box
                                                      sx={{
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <TextField {...params} />
                                                      {/* Nhãn nhỏ ở góc trên bên phải */}
                                                      <Box
                                                        sx={{
                                                          position: "absolute",
                                                          top: -25,
                                                          left: 0,
                                                          fontSize: "0.8rem",
                                                          color: "black",
                                                        }}
                                                      >
                                                        Bộ sưu tập
                                                      </Box>
                                                    </Box>
                                                  )}
                                                  value={
                                                    groupLabelListAll.find(
                                                      (option) =>
                                                        option._id ===
                                                        giftWithPurchase
                                                          .productGroupAmountType
                                                          .list[index]
                                                          .collection
                                                    ) || null
                                                  }
                                                  filterOptions={(
                                                    options,
                                                    state
                                                  ) => {
                                                    const inputValue =
                                                      state.inputValue.toLowerCase();
                                                    return options.filter(
                                                      (option) =>
                                                        option.name
                                                          .toLowerCase()
                                                          .includes(inputValue)
                                                    );
                                                  }}
                                                  // không hiển thị các tag đã chọn
                                                  // renderTags={(value, getTagProps) =>
                                                  //     value.map((option, index) => (
                                                  //       <div key={index} style={{ display: 'none' }} /> // Ẩn sản phẩm đã chọn
                                                  //     ))
                                                  //   }
                                                  onChange={(
                                                    event,
                                                    newValue
                                                  ) => {
                                                    let temp = [
                                                      ...giftWithPurchase
                                                        .productGroupAmountType
                                                        .list,
                                                    ];
                                                    temp[index].collection =
                                                      newValue
                                                        ? newValue._id
                                                        : "";
                                                    return setGiftWithPurchase({
                                                      ...giftWithPurchase,
                                                      productGroupAmountType: {
                                                        ...giftWithPurchase.productGroupAmountType,
                                                        list: temp,
                                                      },
                                                    });
                                                  }}
                                                  //stop warning
                                                  isOptionEqualToValue={(
                                                    option,
                                                    value
                                                  ) => option._id === value._id}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="variation-edit-panel">
                                        <Box
                                          component="form"
                                          size="small"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <div>
                                            <Paper
                                              elevation={0}
                                              style={{ background: "#ffffff" }}
                                            >
                                              <TextField
                                                style={{ minWidth: "100%" }}
                                                label="Số lượng mua"
                                                id="outlined-size-small"
                                                size="small"
                                                disabled
                                                value={
                                                  giftWithPurchase
                                                    ?.productGroupAmountType
                                                    ?.list[index].amount ?? 1
                                                }
                                                onChange={(e) => {
                                                  const value = e.target.value
                                                    .replace(/^0+/, "")
                                                    .replace(/[^0-9]/g, "");
                                                  let temp = [
                                                    ...giftWithPurchase
                                                      .productGroupAmountType
                                                      .list,
                                                  ];
                                                  temp[index].amount =
                                                    Number(value);
                                                  return setGiftWithPurchase({
                                                    ...giftWithPurchase,
                                                    productGroupAmountType: {
                                                      ...giftWithPurchase.productGroupAmountType,
                                                      list: temp,
                                                    },
                                                  });
                                                }}
                                              />
                                            </Paper>
                                          </div>
                                        </Box>
                                      </div> */}
                                      <div className="variation-edit-panel">
                                        <div className="variation-edit-">
                                          File xls gồm 1 cột: SKU cột A, bắt đầu
                                          từ ô A1
                                        </div>
                                      </div>
                                      <div className="product-edit-form-item-content">
                                        <div style={{ marginBottom: "15px" }}>
                                          <input
                                            type="file"
                                            onChange={(e) => {
                                              return handleFileChange(
                                                e,
                                                giftWithPurchase?.GWPType,
                                                index
                                              );
                                            }}
                                            accept=".xlsx, .xls"
                                          />
                                        </div>
                                      </div>

                                      <div className="popover-wrap">
                                        <div className="product-edit-form-item-content">
                                          <div style={{ lineHeight: "0px" }}>
                                            <div
                                              style={{
                                                height: 300,
                                                width: "100%",
                                              }}
                                            >
                                              <DataGrid
                                                style={{background:"white"}}
                                                rows={giftWithPurchase.productGroupAmountType.list[
                                                  index
                                                ].gift.reduce(
                                                  (pre, cur, index1) => {
                                                    return [
                                                      ...pre,
                                                      { id: index1, sku: cur, remain : (remainList[cur] ?? 0) },
                                                    ];
                                                  },
                                                  []
                                                )}
                                                columns={columns}
                                                disableSelectionOnClick
                                                initialState={{
                                                  pagination: {
                                                    paginationModel: {
                                                      page: 0,
                                                      pageSize: 5,
                                                    },
                                                  },
                                                }}
                                                pageSizeOptions={[5, 10]}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                    {state === 1 && <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => createGiftWithPurchase()}
                                >
                                  Tạo chương trình
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                    {state === 2 && <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => updateGiftWithPurchase()}
                                >
                                  Cập nhật
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GiftWithPurchaseInfo;
