import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import * as XLSX from "xlsx";

function ChangeRemain({ setChangeRemainTab, setTrigger }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;

  const [type, setType] = useState(1);
  const [content, setContent] = useState({});

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          // Xử lý dữ liệu từ tệp Excel
          const newContent = {};
          for (const row of jsonData) {
            const key = row[0];
            const value = row[1];

            // Kiểm tra giá trị và trống
            if (!key || (value !== 0 && !value) || isNaN(value) || value < 0) {
              // Bỏ qua dòng lỗi và tiếp tục vòng lặp
              continue;
            }

            // Chuyển đổi value thành số
            const numericValue = Number(value);
            if (!isNaN(numericValue)) {
                // Kiểm tra trùng lặp và gán giá trị mới
                if (newContent[key]) {
                console.log(`Dữ liệu trùng lặp cho khóa ${key}. Giá trị mới được áp dụng.`);
                }
                newContent[key] = numericValue;
            }
          }

          // Cập nhật state
          setContent(newContent);
        } catch (error) {
          console.error("Lỗi khi đọc tệp Excel:", error);
          alert("Đã xảy ra lỗi khi đọc tệp Excel.");
        }
      };

      reader.readAsArrayBuffer(file);
    } else {
      // Nếu không có file hoặc file không hợp lệ, set lại content là {}
      setContent({});
    }
  };

  const update = () => {
    if (Object.keys(content).length !== 0) {
      fetch(`${apiURL}/product/massupdate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({
          type,
          content,
        }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            alert(result?.message ?? "Thành công");
          } else {
            alert(result?.message ?? "Có lỗi");
          }
        });
    } else {
      alert("File không có sản phẩm hợp lệ");
      return;
    }
  };

  return (
    <React.Fragment>
      <div
        className="toggleTab"
        onClick={(e) => {
          setChangeRemainTab(false);
          setTrigger((prev) => {
            return !prev;
          });
        }}
      >
        <div
          className="childTab"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div>
            <h3 className="vodal-title ">{`Cập nhật tồn kho`}</h3>

            <div className="form-group px-0 mgt20">
              <label className="label-input-group">Chọn loại nhập tồn</label>
              <div className="">
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={type}
                    name="radio-buttons-group"
                    onChange={(e) => {
                      setType(Number(e.target.value));
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Thay đổi tồn có trong file"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Thay đổi tồn mọi sản phẩm"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="form-group px-0 mgt20">
              <label className="label-input-group">Nhập file</label>
              <div className="">
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileChange}
                />
              </div>
              <div>{`Có ${
                Object.keys(content).length
              } sản phẩm có trong File`}</div>
            </div>

            <div className="right-button">
              <button
                className="btn btn-primary text-right"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.confirm("Xác nhận cập nhật tồn?")) {
                    update();
                  }
                }}
              >
                Cập nhật
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ChangeRemain;
