import React, { useEffect, useState } from "react";
import Api from "../../api/apis";
import axios from "axios";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Icon } from "@iconify/react";

function ImageContent({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [channelInfo, setChannelInfo] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [focus, setFocus] = useState({});

  const [inform, setInform] = useState("");

  const [mainSlideImg, setMainSlideImg] = useState([]);
  const [mainSlideUrl, setMainSlideUrl] = useState([]);
  const [swapSlide, setSwapSlide] = useState([]);
  const [firstSwapSlide, setFirstSwapSlide] = useState(-1)


  const [oldWebIntro, setOldWebIntro] = useState([]);
  const [webIntroImg, setWebIntroImg] = useState("");
  const [oldWebIntro2, setOldWebIntro2] = useState([]);
  const [webIntroImg2, setWebIntroImg2] = useState("");

  const [instaUrl, setInstaUrl] = useState([""]);
  const [instaImageUrl, setInstaImageUrl] = useState([""]);
  const [swapInsta, setSwapInsta] = useState([]);
  const [firstSwapInsta, setFirstSwapInsta] = useState(-1)

  const swap = (arr, idx, swapIdx) => {
    let toChange = idx[1], change=idx[0]
    if(idx[0]!==swapIdx){
      toChange = idx[0]
      change = idx[1]
    }
    if(swapIdx!==arr.length){
      if(toChange === arr.length){
        const removedElement = arr.splice(swapIdx, 1);
        arr.push(removedElement[0])
        return arr
      }else{
        const removedElement = arr.splice(swapIdx, 1)[0];
        arr.splice(change>toChange? toChange : toChange-1,0,removedElement);
        return arr
      }
    }else{
      return arr
    }
  }

  useEffect(() => {
    setSwapInsta(new Array(instaImageUrl.length + 1).fill(0));
  }, [instaImageUrl.length]);

  useEffect(() => {
    let zeroIndices = swapInsta.reduce((indices, value, index) => {
      if (value === 1) {
          indices.push(index);
      }
      return indices;
  }, []);

  if(zeroIndices.length === 1){
    setFirstSwapInsta(zeroIndices[0]);
  } 
  if(zeroIndices.length === 2){
    if(zeroIndices[0]!==swapInsta.length){
      let tempInstaUrl = [...instaUrl]
      setInstaUrl([...swap(tempInstaUrl, zeroIndices, firstSwapInsta)])
      let tempInstaImageUrl = [...instaImageUrl]
      setInstaImageUrl([...swap(tempInstaImageUrl, zeroIndices, firstSwapInsta)])
    }
    setSwapInsta(new Array(instaImageUrl.length + 1).fill(0));
    
  } 
  }, [swapInsta]);

  

  useEffect(() => {
    setSwapSlide(new Array(mainSlideImg.length + 1).fill(0));
  }, [mainSlideImg.length]);
  
  useEffect(() => {
    let zeroIndices = swapSlide.reduce((indices, value, index) => {
      if (value === 1) {
          indices.push(index);
      }
      return indices;
  }, []);

  if(zeroIndices.length === 1){
    setFirstSwapSlide(zeroIndices[0]);
  } 
  if(zeroIndices.length === 2){
    if(zeroIndices[0]!==swapSlide.length){
      let tempSlideUrl = [...mainSlideUrl]
      setMainSlideUrl([...swap(tempSlideUrl, zeroIndices, firstSwapSlide)])
      let tempSlideImageUrl = [...mainSlideImg]
      setMainSlideImg([...swap(tempSlideImageUrl, zeroIndices, firstSwapSlide)])
    }
    setSwapSlide(new Array(mainSlideImg.length + 1).fill(0));
    
  } 
  }, [swapSlide]);

  useEffect(() => {
    if (channelInfo._id) {
      const websiteImage = { ...channelInfo?.websiteImage } ?? {};
      setMainSlideImg([...websiteImage?.mainSlide] ?? [])
      setMainSlideUrl([...websiteImage?.mainSlideUrl] ?? [])
      setInstaImageUrl([...(channelInfo.instaImageUrl ?? [])]);
      const webIntro = { ...channelInfo?.webIntro } ?? {
        title: "",
        content: "",
        url: "",
      };
      const webIntro2 = { ...channelInfo?.webIntro2 } ?? {
        title: "",
        content: "",
        url: "",
      };
      setWebIntroImg(webIntro.url ?? "");
      setOldWebIntro(webIntro);
      
      setWebIntroImg2(webIntro2.url ?? "");
      setOldWebIntro2(webIntro2);
      setInform(channelInfo?.inform ?? "");
      setInstaUrl([...channelInfo?.instaUrl] ?? []);
    }
  }, [channelInfo]);


  useEffect(() => {
    setSelect("imageContent");
    let set = true;

    const fetchData = async () => {
      try {
        let rs = {};
        rs = { ...(await Api.findChannel("American Tourister")) };
        if (set && rs.success) {
          setChannelInfo(rs.channel);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      set = false;
    };
  }, [trigger]);

  const updateChannel = async () => {
    try {
      
      const newChannel = JSON.parse(JSON.stringify(channelInfo));
      newChannel.websiteImage.mainSlideUrl = mainSlideUrl;
      newChannel.webIntro = { ...oldWebIntro };
      newChannel.webIntro2 = { ...oldWebIntro2 };
      newChannel.inform = inform;
      newChannel.instaUrl = instaUrl;
      if (typeof webIntroImg !== "string") {
        try {
          const formData = new FormData();
          formData.append("img", webIntroImg);

          const response = await axios.post(
            `${apiURL}/product/image`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data.success === true) {
            newChannel.webIntro.url = response.data.result.Key ?? "";
          } else {
            throw new Error("Lỗi");
          }
        } catch (err) {
          console.log(err);
          alert("Lỗi cập nhật kênh");
          return;
        }
      }
      if (typeof webIntroImg2 !== "string") {
        try {
          const formData = new FormData();
          formData.append("img", webIntroImg2);

          const response = await axios.post(
            `${apiURL}/product/image`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data.success === true) {
            newChannel.webIntro2.url = response.data.result.Key ?? "";
          } else {
            throw new Error("Lỗi");
          }
        } catch (err) {
          console.log(err);
          alert("Lỗi cập nhật kênh");
          return;
        }
      }
      if (instaImageUrl.length > 0) {
        try {
          const promises = instaImageUrl.map(async (image) => {
            if (typeof image !== "string") {
              try {
                const formData = new FormData();
                formData.append("img", image);

                const response = await axios.post(
                  `${apiURL}/product/image`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );

                if (response.data.success === true) {
                  return response.data.result.Key ?? "";
                } else {
                  throw new Error("Lỗi");
                }
              } catch (e) {
                throw new Error("Lỗi");
              }
            } else {
              return image;
            }
          });

          const temp = await Promise.all(promises);
          newChannel.instaImageUrl = temp;
        } catch (err) {
          console.log(err);
          alert("Lỗi cập nhật kênh");
          return;
        }
      }

      if (mainSlideImg.length > 0) {
        try {
          const promises = mainSlideImg.map(async (image) => {
            if (typeof image !== "string") {
              try {
                const formData = new FormData();
                formData.append("img", image);

                const response = await axios.post(
                  `${apiURL}/product/image`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );

                if (response.data.success === true) {
                  return response.data.result.Key ?? "";
                } else {
                  throw new Error("Lỗi");
                }
              } catch (e) {
                throw new Error("Lỗi");
              }
            } else {
              return image;
            }
          });

          
          const temp1 = await Promise.all(promises);
          newChannel.websiteImage.mainSlide = temp1
        } catch (err) {
          console.log(err);
          alert("Lỗi cập nhật kênh");
          return;
        }
      }

      let rs = {};
      rs = {
        ...(await Api.updateChannelByName(channelInfo.name ?? "", newChannel)),
      };
      if (rs.success) {
        alert(rs.message);
        setTrigger((prev) => {
          return prev + 1;
        });
      } else {
        alert(rs.message);
      }
    } catch (error) {
      alert(error.message ?? "Có lỗi xảy ra, thử lại");
      console.log(error);
      return;
    }
  };

  const handleFocus = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: true,
    }));
  };

  const handleBlur = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: false,
    }));
  };

  return (
    <React.Fragment>
      <div className="page">
        <div className="edit_container">
          <section className="edit_section" style={{ marginBottom: "130px" }}>
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Nội dung kênh</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Thông báo</span>
                      </div>

                      <div className="edit-main">
                        <div className="popover-wrap">
                          <Box
                            component="form"
                            size="small"
                            style={{ marginBottom: "10px" }}
                          >
                            <div>
                              <Paper
                                elevation={0}
                                style={{ background: "#ffffff" }}
                              >
                                <TextField
                                  style={{ minWidth: "100%" }}
                                  label="Thông báo"
                                  id="outlined-size-small"
                                  size="small"
                                  value={inform ?? ""}
                                  onChange={(e) => {
                                    setInform(e.target.value);
                                  }}
                                />
                              </Paper>
                            </div>
                          </Box>
                        </div>
                      </div>
                    </div>


                    

                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span style={{ marginRight: 10 }}>Banner chính</span>

                        <Icon
                          style={{ cursor: "pointer" }}
                          icon="material-symbols:add-circle"
                          onClick={(e) => {
                            if (mainSlideUrl.length < 10) {
                              setMainSlideUrl(["", ...mainSlideUrl]);
                              setMainSlideImg(["", ...mainSlideImg]);
                            }
                          }}
                        />
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="variation-edit-item">
                            {mainSlideUrl.map((ele, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <div className="product-edit-form-item-content list-input">
                                    <div
                                      style={{
                                        lineHeight: "0px",
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        className={`input_inner ${
                                          focus[`bannerINS${index}`]
                                            ? "focused"
                                            : ""
                                        }`}
                                        style={{
                                          display: "inline-block",
                                          width: "calc(100% - 60px)",
                                        }}
                                      >
                                        <input
                                          style={{ display: "inline-block" }}
                                          type="text"
                                          value={mainSlideUrl[index] ?? ""}
                                          onChange={(e) => {
                                            let newArray = [...mainSlideUrl];
                                            newArray[index] = e.target.value;
                                            setMainSlideUrl(newArray);
                                          }}
                                          className="input_input"
                                          onFocus={() =>
                                            handleFocus(`bannerINS${index}`)
                                          }
                                          onBlur={() =>
                                            handleBlur(`bannerINS${index}`)
                                          }
                                        />
                                      </div>
                                      <Icon
                                        icon="charm:bin"
                                        style={{
                                          position: "absolute",
                                          right: 0,
                                          top: "calc(50% - 10px)",
                                          cursor: "pointer",
                                          height: 20,
                                        }}
                                        onClick={() => {
                                          if (mainSlideUrl.length > 0) {
                                            let newArray = mainSlideUrl.slice();
                                            let newImageArray =
                                              mainSlideImg.slice();
                                            setMainSlideUrl(
                                              newArray.filter(
                                                (_, i) => i !== index
                                              )
                                            );
                                            setMainSlideImg(
                                              newImageArray.filter(
                                                (_, i) => i !== index
                                              )
                                            );
                                          }
                                        }}
                                      />
                                      {swapSlide[index] === 0 && (
                                        <Icon
                                          icon="ph:swap"
                                          style={{
                                            position: "absolute",
                                            right: 40,
                                            top: "calc(50% - 10px)",
                                            cursor: "pointer",
                                            height: 20,
                                          }}
                                          onClick={() => {
                                            let temp = [...swapSlide];
                                            temp[index] = 1;
                                            setSwapSlide(temp);
                                          }}
                                        />
                                      )}
                                      {swapSlide[index] === 1 && (
                                        <Icon
                                          icon="ph:swap-duotone"
                                          style={{
                                            position: "absolute",
                                            right: 40,
                                            top: "calc(50% - 10px)",
                                            cursor: "pointer",
                                            height: 20,
                                          }}
                                          onClick={() => {
                                            let temp = [...swapSlide];
                                            temp[index] = 0;
                                            setSwapSlide(temp);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div style={{ marginTop: 5 }}>
                                      <div
                                        className="img_box_dis_auto"
                                      >
                                        <div className="img_each_create_auto">
                                          {mainSlideImg[index] !== "" && (
                                            <img
                                              className="img"
                                              src={
                                                typeof mainSlideImg[index] ===
                                                "string"
                                                  ? `${cloudFrontURL}/${mainSlideImg[index]}`
                                                  : URL.createObjectURL(
                                                      mainSlideImg[index]
                                                    )
                                              }
                                              alt="none"
                                            ></img>
                                          )}
                                        </div>
                                      </div>
                                      <input
                                        type="file"
                                        accept="image/*" // Để chỉ cho phép chọn các tập tin ảnh
                                        onChange={(e) => {
                                          let temp = [...mainSlideImg];
                                          temp[index] = e.target.files[0];
                                          setMainSlideImg(temp);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                            <div
                              style={{
                                lineHeight: "0px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline-block",
                                  width: "calc(100% - 60px)",
                                }}
                              ></div>
                              <Icon
                                icon="charm:bin"
                                style={{
                                  position: "absolute",
                                  display: "none",
                                  right: 0,
                                  top: "calc(50% - 10px)",
                                  cursor: "pointer",
                                  height: 20,
                                }}
                              />
                              {swapSlide[swapSlide.length - 1] === 0 && (
                                <Icon
                                  icon="ph:swap"
                                  style={{
                                    position: "absolute",
                                    right: 40,
                                    top: "calc(50% - 10px)",
                                    cursor: "pointer",
                                    height: 20,
                                  }}
                                  onClick={() => {
                                    let temp = [...swapSlide];
                                    temp[swapSlide.length - 1] = 1;
                                    setSwapSlide(temp);
                                  }}
                                />
                              )}
                              {swapSlide[swapSlide.length - 1] === 1 && (
                                <Icon
                                  icon="ph:swap-duotone"
                                  style={{
                                    position: "absolute",
                                    right: 40,
                                    top: "calc(50% - 10px)",
                                    cursor: "pointer",
                                    height: 20,
                                  }}
                                  onClick={() => {
                                    let temp = [...swapSlide];
                                    temp[swapSlide.length - 1] = 0;
                                    setSwapSlide(temp);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Giới thiệu</span>
                      </div>

                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="variation-edit-item">
                            <Box
                              component="form"
                              size="small"
                              style={{ marginBottom: "10px" }}
                            >
                              <div>
                                <Paper
                                  elevation={0}
                                  style={{ background: "#ffffff" }}
                                >
                                  <TextField
                                    style={{ minWidth: "100%" }}
                                    label="Tiêu đề"
                                    id="outlined-size-small"
                                    size="small"
                                    value={oldWebIntro?.title ?? ""}
                                    onChange={(e) => {
                                      const newObj = { ...oldWebIntro };
                                      newObj.title = e.target.value;
                                      setOldWebIntro(newObj);
                                    }}
                                  />
                                </Paper>
                              </div>
                            </Box>
                            <Box component="form" size="small">
                              <div>
                                <Paper
                                  elevation={0}
                                  style={{ background: "#ffffff" }}
                                >
                                  <TextField
                                    style={{ minWidth: "100%" }}
                                    label="Nội dung"
                                    id="outlined-size-small"
                                    size="small"
                                    value={oldWebIntro?.content ?? ""}
                                    onChange={(e) => {
                                      const newObj = { ...oldWebIntro };
                                      newObj.content = e.target.value;
                                      setOldWebIntro(newObj);
                                    }}
                                  />
                                </Paper>
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`edit-row `}>
                      <div className="edit-label edit-title">Ảnh</div>
                      <div className="edit-main">
                        <input
                          style={{ marginBottom: "10px" }}
                          type="file"
                          accept="image/*" // Để chỉ cho phép chọn các tập tin ảnh
                          onChange={(e) => {
                            if (Array.from(e.target.files).length === 1) {
                              setWebIntroImg(e.target.files[0]);
                            } else {
                              setWebIntroImg(oldWebIntro.url);
                            }
                          }}
                        />

                        <div
                          className="edit-main shopee-image-manager"
                          style={{
                            display: "inline-block",
                            width: "auto!important",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className="img_box_dis_auto">
                              <div className="img_each_create_auto">
                                <img
                                  className="img"
                                  src={
                                    typeof webIntroImg === "string"
                                      ? `${cloudFrontURL}/${webIntroImg}`
                                      : URL.createObjectURL(webIntroImg)
                                  }
                                  alt="none"
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Giới thiệu</span>
                      </div>

                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="variation-edit-item">
                            <Box
                              component="form"
                              size="small"
                              style={{ marginBottom: "10px" }}
                            >
                              <div>
                                <Paper
                                  elevation={0}
                                  style={{ background: "#ffffff" }}
                                >
                                  <TextField
                                    style={{ minWidth: "100%" }}
                                    label="Tiêu đề"
                                    id="outlined-size-small"
                                    size="small"
                                    value={oldWebIntro2?.title ?? ""}
                                    onChange={(e) => {
                                      const newObj = { ...oldWebIntro2 };
                                      newObj.title = e.target.value;
                                      setOldWebIntro2(newObj);
                                    }}
                                  />
                                </Paper>
                              </div>
                            </Box>
                            <Box component="form" size="small">
                              <div>
                                <Paper
                                  elevation={0}
                                  style={{ background: "#ffffff" }}
                                >
                                  <TextField
                                    style={{ minWidth: "100%" }}
                                    label="Nội dung"
                                    id="outlined-size-small"
                                    size="small"
                                    value={oldWebIntro2?.content ?? ""}
                                    onChange={(e) => {
                                      const newObj = { ...oldWebIntro2 };
                                      newObj.content = e.target.value;
                                      setOldWebIntro2(newObj);
                                    }}
                                  />
                                </Paper>
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`edit-row `}>
                      <div className="edit-label edit-title">Ảnh</div>
                      <div className="edit-main">
                        <input
                          style={{ marginBottom: "10px" }}
                          type="file"
                          accept="image/*" // Để chỉ cho phép chọn các tập tin ảnh
                          onChange={(e) => {
                            if (Array.from(e.target.files).length === 1) {
                              setWebIntroImg2(e.target.files[0]);
                            } else {
                              setWebIntroImg2(oldWebIntro2.url);
                            }
                          }}
                        />

                        <div
                          className="edit-main shopee-image-manager"
                          style={{
                            display: "inline-block",
                            width: "auto!important",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className="img_box_dis_auto">
                              <div className="img_each_create_auto">
                                <img
                                  className="img"
                                  src={
                                    typeof webIntroImg2 === "string"
                                      ? `${cloudFrontURL}/${webIntroImg2}`
                                      : URL.createObjectURL(webIntroImg2)
                                  }
                                  alt="none"
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span style={{ marginRight: 10 }}>Liên kết INSTA</span>

                        <Icon
                          style={{ cursor: "pointer" }}
                          icon="material-symbols:add-circle"
                          onClick={(e) => {
                            if (instaUrl.length < 10) {
                              setInstaUrl(["", ...instaUrl]);
                              setInstaImageUrl(["", ...instaImageUrl]);
                            }
                          }}
                        />
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="variation-edit-item">
                            {instaUrl.map((ele, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <div className="product-edit-form-item-content list-input">
                                    <div
                                      style={{
                                        lineHeight: "0px",
                                        position: "relative",
                                      }}
                                    >
                                      <div
                                        className={`input_inner ${
                                          focus[`bannerINS${index}`]
                                            ? "focused"
                                            : ""
                                        }`}
                                        style={{
                                          display: "inline-block",
                                          width: "calc(100% - 60px)",
                                        }}
                                      >
                                        <input
                                          style={{ display: "inline-block" }}
                                          type="text"
                                          value={instaUrl[index] ?? ""}
                                          onChange={(e) => {
                                            let newArray = [...instaUrl];
                                            newArray[index] = e.target.value;
                                            setInstaUrl(newArray);
                                          }}
                                          className="input_input"
                                          onFocus={() =>
                                            handleFocus(`bannerINS${index}`)
                                          }
                                          onBlur={() =>
                                            handleBlur(`bannerINS${index}`)
                                          }
                                        />
                                      </div>
                                      <Icon
                                        icon="charm:bin"
                                        style={{
                                          position: "absolute",
                                          right: 0,
                                          top: "calc(50% - 10px)",
                                          cursor: "pointer",
                                          height: 20,
                                        }}
                                        onClick={() => {
                                          if (instaUrl.length > 0) {
                                            let newArray = instaUrl.slice();
                                            let newImageArray =
                                              instaImageUrl.slice();
                                            setInstaUrl(
                                              newArray.filter(
                                                (_, i) => i !== index
                                              )
                                            );
                                            setInstaImageUrl(
                                              newImageArray.filter(
                                                (_, i) => i !== index
                                              )
                                            );
                                          }
                                        }}
                                      />
                                      {swapInsta[index] === 0 && (
                                        <Icon
                                          icon="ph:swap"
                                          style={{
                                            position: "absolute",
                                            right: 40,
                                            top: "calc(50% - 10px)",
                                            cursor: "pointer",
                                            height: 20,
                                          }}
                                          onClick={() => {
                                            let temp = [...swapInsta];
                                            temp[index] = 1;
                                            setSwapInsta(temp);
                                          }}
                                        />
                                      )}
                                      {swapInsta[index] === 1 && (
                                        <Icon
                                          icon="ph:swap-duotone"
                                          style={{
                                            position: "absolute",
                                            right: 40,
                                            top: "calc(50% - 10px)",
                                            cursor: "pointer",
                                            height: 20,
                                          }}
                                          onClick={() => {
                                            let temp = [...swapInsta];
                                            temp[index] = 0;
                                            setSwapInsta(temp);
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div style={{ marginTop: 5 }}>
                                      <div
                                        className="img_box_dis_auto"
                                        style={{ width: 100 }}
                                      >
                                        <div className="img_each_create_auto">
                                          {instaImageUrl[index] !== "" && (
                                            <img
                                              className="img"
                                              src={
                                                typeof instaImageUrl[index] ===
                                                "string"
                                                  ? `${cloudFrontURL}/${instaImageUrl[index]}`
                                                  : URL.createObjectURL(
                                                      instaImageUrl[index]
                                                    )
                                              }
                                              alt="none"
                                            ></img>
                                          )}
                                        </div>
                                      </div>
                                      <input
                                        type="file"
                                        accept="image/*" // Để chỉ cho phép chọn các tập tin ảnh
                                        onChange={(e) => {
                                          let temp = [...instaImageUrl];
                                          temp[index] = e.target.files[0];
                                          setInstaImageUrl(temp);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                            <div
                              style={{
                                lineHeight: "0px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline-block",
                                  width: "calc(100% - 60px)",
                                }}
                              ></div>
                              <Icon
                                icon="charm:bin"
                                style={{
                                  position: "absolute",
                                  display: "none",
                                  right: 0,
                                  top: "calc(50% - 10px)",
                                  cursor: "pointer",
                                  height: 20,
                                }}
                              />
                              {swapInsta[swapInsta.length - 1] === 0 && (
                                <Icon
                                  icon="ph:swap"
                                  style={{
                                    position: "absolute",
                                    right: 40,
                                    top: "calc(50% - 10px)",
                                    cursor: "pointer",
                                    height: 20,
                                  }}
                                  onClick={() => {
                                    let temp = [...swapInsta];
                                    temp[swapInsta.length - 1] = 1;
                                    setSwapInsta(temp);
                                  }}
                                />
                              )}
                              {swapInsta[swapInsta.length - 1] === 1 && (
                                <Icon
                                  icon="ph:swap-duotone"
                                  style={{
                                    position: "absolute",
                                    right: 40,
                                    top: "calc(50% - 10px)",
                                    cursor: "pointer",
                                    height: 20,
                                  }}
                                  onClick={() => {
                                    let temp = [...swapInsta];
                                    temp[swapInsta.length - 1] = 0;
                                    setSwapInsta(temp);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => {
                                    if (window.confirm("Xác nhận cập nhật?")) {
                                      updateChannel();
                                    }
                                  }}
                                >
                                  Cập nhật
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ImageContent;
