import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { moneyFormat, dateFormat } from "../../helper";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Stack from "@mui/material/Stack";
import * as XLSX from "xlsx";
import Api from "../../api/apis";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function Invoice({ setSelect, user }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [orderList, setOrderList] = useState([]);
  const [cityList, setCityList] = useState([]);

  //filter
  const [isCreateTime, setIsCreateTime] = useState(false);
  const [CreateTimeFrom, setCreateTimeFrom] = useState(new Date());
  const [CreateTimeTo, setCreateTimeTo] = useState(new Date());
  const [invoiceState, setInvoiceState] = useState([]);
  const [paymentState, setPaymentState] = useState([]);
  const [search, setSearch] = useState("");

  const invoiceStateArr = [
    "Tất cả",
    "Chưa xác nhận",
    "Đã xác nhận",
    "Đã xác nhận",
    "Đã hoàn thành",
    "Đã hủy",
  ];
  const paymentStateArr = ["Tất cả", "Đã thanh toán", "Chưa thanh toán"];
  const getPaymentMethod = (method) => {
    let methods = {
      1:"Tiền mặt",
      2:"Chuyển khoản ngân hàng",
      3:"Thanh toán online qua OnePay",
      4:"Payoo"
    }
    return methods[method]??""
  };
  //pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [trigger, setTrigger] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    let set = true;
    let filter = { page, search };
    if (isCreateTime) {
      filter.isCreateTime = isCreateTime;
      filter.CreateTimeFrom = CreateTimeFrom;
      filter.CreateTimeTo = CreateTimeTo;
    }
    if (paymentState.length !== 0) {
      filter.paymentState = paymentState;
    }
    if (invoiceState.length !== 0) {
      filter.invoiceState = invoiceState;
    }

    fetch(`${apiURL}/invoice/searchfilter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify(filter),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success && set) {
          setTotalCount(
            result.invoiceList[0].invoiceGroupList.length > 0
              ? result.invoiceList[0].count[0]["count"]
              : 0
          );
          setOrderList(
            result.invoiceList[0].invoiceGroupList.length > 0
              ? result.invoiceList[0].invoiceGroupList
              : []
          );
          setMaxPage(
            result.invoiceList[0].invoiceGroupList.length > 0
              ? Math.ceil(result.invoiceList[0].count[0]["count"] / 20)
              : 0
          );
        } else {
        }
      });
    return () => {
      set = false;
    };
  }, [page, trigger]);

  useEffect(() => {
    setPage(1);
    setTrigger((prev) => {
      return !prev;
    });
  }, [isCreateTime, CreateTimeFrom, CreateTimeTo, paymentState, invoiceState, search]);

  useEffect(() => {
    setSelect("invoice");
    let set = true
    let cList = []
    const fetchDataCt = async () => {
      try {
        cList = [...await Api.getCityList()];
        if(set){
          setCityList(cList)
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchDataCt()
    return () =>{
      set = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const exportFile = async () => {
    let filter = { };
    if (isCreateTime) {
      filter.isCreateTime = isCreateTime;
      filter.CreateTimeFrom = CreateTimeFrom;
      filter.CreateTimeTo = CreateTimeTo;
    }
    if (paymentState.length !== 0) {
      filter.paymentState = paymentState;
    }
    if (invoiceState.length !== 0) {
      filter.invoiceState = invoiceState;
    }
    let pArray = new Array(maxPage).fill([])

    if (maxPage > 0) {
      const promises = pArray.map(async (ele, index) => {
        filter.page = index + 1
        try {
          const response = await fetch(`${apiURL}/invoice/searchfilter`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + localStorage.banyanAdminToken
                  ? localStorage.banyanAdminToken
                  : "",
            },
            body: JSON.stringify(filter),
          })
          if (!response.ok) {
            throw new Error("Something went wrong");
          }
          const result = await response.json();
          if (result.success) {
            return result.invoiceList[0].invoiceGroupList.length > 0
            ? result.invoiceList[0].invoiceGroupList
            : [];
          } else {
            throw new Error("Failed to fetch product data");
          }
        } catch (error) {
          console.error("Error fetching product data:", error);
          return [];
        }
      });
    
      try {
        const invoiceArray = await Promise.all(promises);
        const arr = invoiceArray.flatMap(items => items);
        if (arr.length > 0) {
          if (arr.length > 0) {
            var data = [
              [
                "Mã đơn hàng",
                "Ngày mua hàng",
                "Tên khách hàng",
                "Số đt",
                "Email",
                "Địa chỉ",
                "Tỉnh/Thành phố",
                "Mã sử dụng",
                "Giá trị đơn",
                "Số lượng sản phẩm",
                "Phương thức thanh toán",
                "Trạng thái đơn",
                "Trạng thái thanh toán",
                "GClid"
              ], // Định nghĩa các cột
            ];
            arr.map((ele, index) => {
              data.push([
                ele?.code ?? "",
                dateFormat(ele?.dateCreate ?? new Date()),
                ele?.customerName ?? "",
                ele?.phone ?? "",
                ele?.email ?? "",
                ele?.address ?? "",
                findCityAndDistrict(ele?.cityCode??0, ele?.districtCode??0),
                ele?.voucher?.code??"",
                ele?.finalPrice ?? 0-ele?.deliverFee??0,
                (ele?.productList??[]).reduce((accumulator, currentObject) => {
                  return accumulator + currentObject.quantity;
                }, 0),
                getPaymentMethod(ele?.paymentMethod),
                ele.invoiceState === 1
                          ? "Đang chờ"
                          : ele.invoiceState === 3
                          ? "Đã xác nhận"
                          : "Đã hủy",
                ele?.paymentState ? "Đã thanh toán" : "Chưa thanh toán" ,
                ele?.gClid ?? "",
              ]);
            });
          }

          convertDataToExcel(data);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    } 
 
  };

  const findCityAndDistrict = (cityCode, districtCode) => {
    let rs = ""
    for(let i  in cityList){
      if(cityList[i].code === (cityCode??"")){
        rs += cityList[i].name??""
        for(let j of cityList[i].districts??[]){
          if ((j.code??"")===(districtCode??"")){
            rs =  (j.name??"") + ", " + rs
          }
        }
      }
    }
    return rs
  }

  function convertDataToExcel(arr) {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(arr); //array of array

    XLSX.utils.book_append_sheet(wb, ws, "DonHang");
    ws["!cols"] = fitToColumn(arr);
    XLSX.writeFile(wb, "DonHang.xlsx");
  }

  function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
}

  

const deleteInvoice = (_id) => {
  fetch(`${apiURL}/invoice/${_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer " + localStorage.banyanAdminToken
          ? localStorage.banyanAdminToken
          : "",
    },
  })
    .then((res) => {
      if (res.ok) return res.json();
      throw new Error("Something went wrong");
    })
    .then((result) => {
      if(result.success){
        alert('Xóa đơn thành công')
        setTrigger((prev)=>{return !prev})
      }else{
        alert('Có lỗi')
      }
    });
};

  return (
    <React.Fragment>
      <div className="AdminTab">
        <h1>Đơn hàng</h1>

        <span className="css-1f0mdg1 mgb20 inline"></span>
        <div className="flexRow mgb50">
          <div className="unitBox w100 mr20">
            <div className="headerBox">Đơn đặt hàng</div>
            <div className="contentBox">
              <div>
                <div className="">
                <div className="form-group px-0 inline mr20">
                  <div className="inline">
                    <label className="label-input-group">Từ khóa</label>
                    <div className="buttonDiv">
                      <input
                        className="next-input numInput"
                        onBlur={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                  <div className=" px-0 inline">
                    <div
                      onClick={(e) => {
                        setIsCreateTime((prev) => {
                          return !prev;
                        });
                      }}
                    >
                      <input
                        type="radio"
                        className="ml20"
                        checked={isCreateTime}
                      ></input>

                      <label className="label-input-group ml10">
                        Thời gian tạo
                      </label>
                    </div>

                    <div className="form-group px-0 inline ">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDateTimePicker
                            views={["month", "day", "hours", "minutes"]}
                            inputFormat="DD/MM/YYYY HH:mm"
                            label="Bắt đầu từ"
                            value={CreateTimeFrom}
                            onChange={(newValue) => {
                              setCreateTimeFrom(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                style={{ width: "200px" }}
                                {...params}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    <div className="form-group px-0 inline ml20">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDateTimePicker
                            views={["month", "day", "hours", "minutes"]}
                            inputFormat="DD/MM/YYYY HH:mm"
                            label="Đến"
                            value={CreateTimeTo}
                            onChange={(newValue) => {
                              setCreateTimeTo(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                style={{ width: "200px" }}
                                {...params}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="form-group px-0 ml20 inline">
                    <div>
                      <label className="label-input-group">Trạng thái</label>
                    </div>

                    <div className="buttonDiv inline">
                      <select
                        className="next-input width200 inline"
                        onChange={(e) => {
                          if (e.target.value === "0") {
                            setInvoiceState([]);
                          } else {
                            setInvoiceState([Number(e.target.value)]);
                          }
                        }}
                      >
                        {invoiceStateArr &&
                          invoiceStateArr.map((ele, index) => {
                            return (
                              index !== 2 &&
                              index !== 4 && (
                                <option key={index} value={index}>
                                  {ele}
                                </option>
                              )
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="form-group px-0 ml20 mr20 inline">
                    <div>
                      <label className="label-input-group">Thanh toán</label>
                    </div>

                    <div className="buttonDiv inline">
                      <select
                        className="next-input width200 inline"
                        onChange={(e) => {
                          if (e.target.value === "0") {
                            setPaymentState([]);
                          } else {
                            setPaymentState([e.target.value === "1"]);
                          }
                        }}
                      >
                        {paymentStateArr &&
                          paymentStateArr.map((ele, index) => {
                            return (
                              <option key={index} value={index}>
                                {ele}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      exportFile();
                    }}
                  >
                    Xuất file
                  </Button>
                </div>
              </div>
              <div className="position-relative">
                <div className="table-list--config">
                  <div className="ui-table-normal-container">
                    <table className="ui-table">
                      <thead>
                        <tr>
                          <th className=""style={{maxWidth:100}}>
                            <span>Mã</span>
                          </th>
                          <th className=""style={{maxWidth:100}}>
                            <span>Ngày tạo</span>
                          </th>
                          <th className="" style={{maxWidth:110, whiteSpace:"nowrap", overflow:"hidden"

                        }}>
                            <span>Khách hàng</span>
                          </th>
                          <th className="" style={{maxWidth:200}}>
                            <span>Ghi chú</span>
                          </th>
                          <th className="" style={{maxWidth:150}}>
                            <span>Thanh toán</span>
                          </th>
                          <th className="">
                            <span>Trạng thái</span>
                          </th>
                          <th className="">
                            <span>Tổng tiền</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderList &&
                          orderList.map((ele, index) => {
                            return (
                              <tr
                                style={{
                                  borderBottom: "1px solid #eee",
                                }}
                                key={index}
                              >
                                <td className="" style={{ maxWidth: 130, width:130 }}>
                                  {ele?.code ?? ""}
                                </td>
                                <td
                                  className="align-top white-space-normal"
                                  style={{ maxWidth: 100 }}
                                >
                                  {dateFormat(ele.dateCreate)}
                                </td>
                                <td
                                  className="align-top white-space-normal"
                                  style={{
                                    maxWidth: 110,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow:"ellipsis"
                                  }}
                                >
                                  {ele?.customerName ?? ""}
                                </td>
                                <td
                                  className="align-top white-space-normal"
                                  style={{
                                    maxWidth: 100,
                                    minWidth:50,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow:"ellipsis"
                                  }}
                                  title={ele?.adminNote ?? ""}
                                >
                                  {ele?.adminNote ?? ""}
                                </td>
                                <td
                                  className={`align-top pt-3 ${
                                    ele.paymentState ? "green" : "yellow"
                                  }`}
                                  style={{ maxWidth: 150 }}
                                >
                                  {Api.getPaymentState(ele.paymentState)}
                                </td>
                                <td
                                  className={`align-top pt-3 ${
                                    ele.invoiceState === 1
                                      ? "yellow"
                                      : ele.invoiceState === 3
                                      ? "green"
                                      : ele.invoiceState === 5
                                      ? "red"
                                      : "red"
                                  }`}
                                >
                                  {Api.getInvoiceState(ele.invoiceState ?? "")}
                                </td>
                                <td className="align-top pt-3">
                                  {moneyFormat(ele.finalPrice)}
                                </td>
                                <td className="align-top pt-3">
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    icon="bx:right-arrow"
                                    onClick={(e) => {
                                      window.open(
                                        `/invoice/orderinfo?id=${ele._id}`
                                      );
                                    }}
                                  />
                                </td>
                            {user&&user.role ===1 &&<td className="align-top pt-3">
                              <IconButton style={{padding:0}}
                                onClick={(e) => {
                                  if (
                                    window.confirm("Xác nhận xóa đơn?")
                                  ) {
                                    deleteInvoice(ele._id);
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </td>}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div>
                <div className="row no-gutters justify-content-between paginate">
                  <div className="col-auto "></div>
                  <div className="col-auto ">
                    <div className="row no-gutters align-items-center">
                      <div className="col-auto mr-3">
                        <span
                          style={{ color: "rgb(128, 128, 128)" }}
                        >{`Tổng: ${totalCount}`}</span>
                      </div>
                      <div className="col">
                        <ul className="pagination pagination-lg justify-content-center">
                          {page > 2 && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) => setPage(1)}
                            >
                              <div className="page-link">
                                <Icon icon="material-symbols:first-page" />
                              </div>
                            </li>
                          )}
                          {page > 1 && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) =>
                                setPage((prev) => {
                                  return prev - 1;
                                })
                              }
                            >
                              <div className="page-link">{page - 1}</div>
                            </li>
                          )}
                          <li className="page-item hidden-mobile active">
                            <div className="page-link">{page}</div>
                          </li>
                          {page < maxPage && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) =>
                                setPage((prev) => {
                                  return prev + 1;
                                })
                              }
                            >
                              <div className="page-link">{page + 1}</div>
                            </li>
                          )}
                          {page < maxPage - 1 && (
                            <li
                              className="page-item hidden-mobile"
                              onClick={(e) => setPage(maxPage)}
                            >
                              <div className="page-link">
                                <Icon icon="material-symbols:last-page" />
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Invoice;
