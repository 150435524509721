import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import * as XLSX from "xlsx";
import AddVoucher from "../../components/voucher/AddVoucher";
import ChangeVoucher from "../../components/voucher/ChangeVoucher";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function Voucher({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [voucherList, setVoucherList] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(0);
  const [activeFilter, setActiveFilter] = useState(0);
  const [createFilter, setCreateFilter] = useState(0);
  const [focus, setFocus] = useState({});

  const [id, setId] = useState("");

  const [changeVoucherTab, setChangeVoucherTab] = useState(false);
  const [addVoucherTab, setAddVoucherTab] = useState(false);

  //pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [trigger, setTrigger] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    let set = true;
    let filter = { page };
    if (categoryFilter !== 0) {
      filter["type"] = categoryFilter;
    }
    if (activeFilter !== 0) {
      filter["active"] = activeFilter === 1 ? true : false;
    }

    if (createFilter !== 0) {
      filter["create"] = createFilter === 1 ? true : false;
    }

    fetch(`${apiURL}/voucher/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify(filter),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success && set) {
          setTotalCount(
            result.voucherList[0].voucherList.length > 0
              ? result.voucherList[0].count[0]["count"]
              : 0
          );
          setVoucherList(
            result.voucherList[0].voucherList.length > 0
              ? result.voucherList[0].voucherList
              : []
          );
          setMaxPage(
            result.voucherList[0].voucherList.length > 0
              ? Math.ceil(result.voucherList[0].count[0]["count"] / 20)
              : 0
          );
        } else {
        }
      });
    return () => {
      set = false;
    };
  }, [page, trigger]);

  const deleteFilter = () => {
    let filter = {};
    if (categoryFilter !== 0) {
      filter["type"] = categoryFilter;
    }
    if (activeFilter !== 0) {
      filter["active"] = activeFilter === 1 ? true : false;
    }

    if (createFilter !== 0) {
      filter["create"] = createFilter === 1 ? true : false;
    }

    fetch(`${apiURL}/voucher/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify(filter),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        console.log(result);
        if (result.success) {
          alert("Xóa thành công");
          setTrigger((prev) => {
            return !prev;
          });
        } else {
          alert("Lỗi");
        }
      });
  };

  useEffect(() => {
    setPage(1);
    setTrigger((prev) => {
      return !prev;
    });
  }, [categoryFilter, activeFilter, createFilter]);

  useEffect(() => {
    setSelect("voucher");
  }, []);

  const deleteVoucher = (_id) => {
    fetch(`${apiURL}/voucher/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          alert("Xóa voucher thành công");
          setTrigger((prev) => {
            return !prev;
          });
        } else {
          alert("Có lỗi");
        }
      });
  };

  return (
    <React.Fragment>
      {addVoucherTab && (
        <AddVoucher
          setAddVoucherTab={setAddVoucherTab}
          setTrigger={setTrigger}
        />
      )}
      {changeVoucherTab && (
        <ChangeVoucher
          setChangeVoucherTab={setChangeVoucherTab}
          setTrigger={setTrigger}
          voucher={id}
        />
      )}

      <div className="page">
        <div className="edit_container">
          <section className="edit_section" style={{ marginBottom: "130px" }}>
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Voucher</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row" style={{ marginBottom: "0px" }}>
                      <div className="edit-label edit-title"> </div>
                    </div>

                    <div className="px-0 inline">
                      <label className="label-input-group">Loại voucher</label>
                      <div className="">
                        <select
                          className="next-input"
                          style={{
                            width: "200px",
                            marginRight: "20px",
                            marginBottom: "5px",
                            marginLeft: "0",
                          }}
                          onChange={(e) => {
                            setCategoryFilter(Number(e.target.value));
                          }}
                        >
                          <option value={0}>Tất cả</option>

                          <option value={1}>{"Theo phần trăm"}</option>
                          <option value={2}>{"Giảm thẳng"}</option>
                        </select>
                      </div>
                    </div>
                    <div className="px-0 inline">
                      <label className="label-input-group">Trạng thái</label>
                      <div className="">
                        <select
                          className="next-input"
                          style={{
                            width: "200px",
                            marginRight: "20px",
                            marginBottom: "5px",
                            marginLeft: "0",
                          }}
                          onChange={(e) => {
                            setActiveFilter(Number(e.target.value));
                          }}
                        >
                          <option value={0}>Tất cả</option>

                          <option value={1}>{"Đang hoạt động"}</option>
                          <option value={2}>{"Đã ngừng"}</option>
                        </select>
                      </div>
                    </div>

                    <div className="px-0 inline">
                      <label className="label-input-group">Loại khởi tạo</label>
                      <div className="">
                        <select
                          className="next-input"
                          style={{
                            width: "200px",
                            marginRight: "20px",
                            marginBottom: "5px",
                            marginLeft: "0",
                          }}
                          onChange={(e) => {
                            setCreateFilter(Number(e.target.value));
                          }}
                        >
                          <option value={0}>Tất cả</option>

                          <option value={1}>{"Nhập tay"}</option>
                          <option value={2}>{"Nhập file"}</option>
                        </select>
                      </div>
                    </div>
                    <button
                      style={{ marginRight: 15 }}
                      className="site-button orange-button "
                      onClick={(e) => {
                        if (window.confirm(`Xác nhận xóa ${totalCount} mã?`)) {
                          deleteFilter();
                        }
                      }}
                    >
                      Xóa theo filter
                    </button>
                    <button
                      className="site-button orange-button "
                      onClick={(e) => {
                        e.preventDefault();
                        setAddVoucherTab(true);
                      }}
                    >
                      Tạo Voucher
                    </button>
                    <div className="edit-row">
                      {voucherList.length > 0 && (
                        <div className="position-relative">
                          <div className="table-list--config">
                            <div className="ui-table-normal-container">
                              <table className="ui-table">
                                <thead>
                                  <tr>
                                    <th className="thName">
                                      <span>Loại</span>
                                    </th>
                                    <th className="thCode">
                                      <span>Mã</span>
                                    </th>
                                    <th className="thInven text-center">
                                      <span>Giá trị</span>
                                    </th>
                                    <th className="thInven text-center">
                                      <span>Kích hoạt</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {voucherList.map((ele, index) => {
                                    return (
                                      <tr key={ele._id}>
                                        <td className="tdName">
                                          <div className="row align-items-start flex-nowrap">
                                            <div className="col">
                                              <div className="col max-width-350px">
                                                <div className="mb-2 table-break-word">
                                                  {`${
                                                    ele.type === 1
                                                      ? "Giảm theo %"
                                                      : "Giảm thẳng"
                                                  }`}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="align-top white-space-normal">
                                          {ele.code}
                                        </td>
                                        <td className="text-center align-top">
                                          {ele.amount +
                                            (ele.type === 1 ? "%" : " Đ ")}
                                        </td>
                                        <td
                                          className={`text-center align-top ${
                                            ele.active ? `green` : `red`
                                          }`}
                                        >
                                          {ele.active ? "Bật" : "Tắt"}
                                        </td>
                                        <td className="align-top pt-3">
                                          <Icon
                                            icon="material-symbols:edit"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                              setId(ele._id);
                                              setChangeVoucherTab(true);
                                            }}
                                          />
                                        </td>

                                        <td className="align-top pt-3">
                                          <IconButton
                                            style={{ padding: 0 }}
                                            onClick={(e) => {
                                              if (
                                                window.confirm(
                                                  "Xác nhận xóa voucher?"
                                                )
                                              ) {
                                                deleteVoucher(ele._id);
                                              }
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div>
                            <div className="row no-gutters justify-content-between paginate">
                              <div className="col-auto "></div>
                              <div className="col-auto ">
                                <div className="row no-gutters align-items-center">
                                  <div className="col-auto mr-3">
                                    <span
                                      style={{ color: "rgb(128, 128, 128)" }}
                                    >{`Tổng: ${totalCount}`}</span>
                                  </div>
                                  <div className="col">
                                    <ul className="pagination pagination-lg justify-content-center">
                                      {page > 2 && (
                                        <li
                                          className="page-item hidden-mobile"
                                          onClick={(e) => setPage(1)}
                                        >
                                          <div className="page-link">
                                            <Icon icon="material-symbols:first-page" />
                                          </div>
                                        </li>
                                      )}
                                      {page > 1 && (
                                        <li
                                          className="page-item hidden-mobile"
                                          onClick={(e) =>
                                            setPage((prev) => {
                                              return prev - 1;
                                            })
                                          }
                                        >
                                          <div className="page-link">
                                            {page - 1}
                                          </div>
                                        </li>
                                      )}
                                      <li className="page-item hidden-mobile active">
                                        <div className="page-link">{page}</div>
                                      </li>
                                      {page < maxPage && (
                                        <li
                                          className="page-item hidden-mobile"
                                          onClick={(e) =>
                                            setPage((prev) => {
                                              return prev + 1;
                                            })
                                          }
                                        >
                                          <div className="page-link">
                                            {page + 1}
                                          </div>
                                        </li>
                                      )}
                                      {page < maxPage - 1 && (
                                        <li
                                          className="page-item hidden-mobile"
                                          onClick={(e) => setPage(maxPage)}
                                        >
                                          <div className="page-link">
                                            <Icon icon="material-symbols:last-page" />
                                          </div>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Voucher;
