import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { splitString } from "../../helper";
import AddNews from "../../components/AddNews";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { dateFormat } from "../../helper";

function NewsFeed({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  const [newsList, setNewsList] = useState([]);
  const [name, setName] = useState("");
  const [news, setNews] = useState({});
  const [newsState, setNewsState] = useState(1);

  useEffect(() => {
    setSelect("news");
  }, [setSelect]);

  //pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [trigger, setTrigger] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const [memberEmail, setMemberEmail] = useState("");
  const [memberTab, setMemberTab] = useState(false);
  const [addNews, setAddNewsTab] = useState(false);
  const [role, setRole] = useState(0);
  useEffect(() => {
    if (memberTab === false) {
      setTrigger((prev) => {
        return !prev;
      });
    }
  }, [memberTab]);

  const updateNews = async (update) => {
    fetch(`${apiURL}/news`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({ update, id : update._id }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setTrigger((prev) => {
            return !prev;
          });
        } else {
          alert("Có lỗi");
        }
      });
  };

  const deleteNews = async (id) => {
    fetch(`${apiURL}/news`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({ id }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setTrigger((prev) => {
            return !prev;
          });
        } else {
          alert("Có lỗi");
        }
      });
  };

  useEffect(() => {
    var set = true;
    fetch(`${apiURL}/news/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        page,
        name,
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success && set) {
          setTotalCount(
            result.newsList[0].newsList.length > 0
              ? result.newsList[0].count[0]["count"]
              : 0
          );
          setNewsList(
            result.newsList[0].newsList.length > 0
              ? result.newsList[0].newsList
              : []
          );
          setMaxPage(
            result.newsList[0].newsList.length > 0
              ? Math.ceil(result.newsList[0].count[0]["count"] / 10)
              : 0
          );
        }
      });
    fetch(apiURL + "/employee/loginCheck", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (set) {
          if (result.success) {
            localStorage &&
              localStorage.setItem(
                "BayanAdminInfo",
                JSON.stringify(result.decoded)
              );

            setMemberEmail(result?.decoded?.email ?? "");
            setRole(result?.decoded?.role ?? 0);
          } else {
            setMemberEmail("");
            setRole(0);
          }
        }
      });
    return () => {
      set = false;
    };
  }, [apiURL, name, page, trigger]);

  return (
    <React.Fragment>
      {/* state 1 = them, 2 = sua bai viet */}
      {addNews && (
        <AddNews
          setAddNewsTab={setAddNewsTab}
          setTrigger={setTrigger}
          newsState={newsState}
          news={newsState === 1 ? {} : news}
        />
      )}
      <h2 className="MuiTypography-root MuiTypography-h2 css-dip02n">
        Bài viết
      </h2>
      {role === 1 && (
        <span className="css-1f0mdg1 mgb20 inline">
          <button
            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation css-1a96ihp"
            tabIndex="0"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setNewsState(1);
              setAddNewsTab(true);
            }}
          >
            Thêm bài viết
            <span className="MuiTouchRipple-root css-w0pj6f"></span>
          </button>
        </span>
      )}

      {newsList &&
        newsList.map((ele, index) => {
          return (
            <React.Fragment key={index}>
              <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1dg90v7">
                <div className="css-idc0u3">
                  <span className="css-8g3mcy">
                    <div className="css-u4p24i">
                      <span className="css-zkfegi">
                        <span className="MuiBadge-root BaseBadge-root css-5lanty">
                          <div className="MuiAvatar-root MuiAvatar-circular css-1oj7qn1">
                            <img src={`${cloudFrontURL}/${ele?.mainImage??""}`} alt = "img"/>
                          </div>
                        </span>
                      </span>
                      <span
                        className="css-zkfegi"
                        onClick={(e) => {
                          e.preventDefault();
                          setNews(ele ?? {})
                          setNewsState(2);
                          setAddNewsTab(true);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <h6 className="MuiTypography-root MuiTypography-h6 css-cvhosu">
                          {ele.name ?? ""}
                        </h6>
                        
                      </span>
                    </div>
                  </span>
                  <span className="css-dyqsm1"></span>
                  <span className="css-1jbsagp">
                    {/* <div className="css-1wydmn0">
                      <span className="css-zkfegi">
                        <h6 className="MuiTypography-root MuiTypography-h6 css-cvhosu">
                          {ele.name ?? ""}
                        </h6>
                        <p className="MuiTypography-root MuiTypography-body1 css-hxfal">
                          Tiêu đề
                        </p>
                      </span>
                    </div> */}
                  </span>
                  {role === 1 && (
                    <React.Fragment>
                      <FormControlLabel
                        checked={ele?.show ?? false}
                        style={{ width: 80 }}
                        control={<Switch color="primary" />}
                        label="Kích họat"
                        labelPlacement="top"
                        onChange={(e) => {
                          updateNews({
                            _id: ele._id,
                            show: Boolean(e.target.checked),
                          });
                        }}
                      />
                      {
                        <IconButton
                          disabled={ele.role === 1 ? true : false}
                          aria-label="delete"
                          onClick={(e) => {
                            if (window.confirm("Xác nhận xóa bài viết?")) {
                              deleteNews(ele._id);
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    </React.Fragment>
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        })}

      <div>
        <div className="row no-gutters justify-content-between paginate">
          <div className="col-auto "></div>
          <div className="col-auto ">
            <div className="row no-gutters align-items-center">
              <div className="col-auto mr-3">
                <span
                  style={{ color: "rgb(128, 128, 128)" }}
                >{`Tổng: ${totalCount}`}</span>
              </div>
              <div className="col">
                <ul className="pagination pagination-lg justify-content-center">
                  {page > 2 && (
                    <li
                      className="page-item hidden-mobile"
                      onClick={(e) => setPage(1)}
                    >
                      <div className="page-link">
                        <Icon icon="material-symbols:first-page" />
                      </div>
                    </li>
                  )}
                  {page > 1 && (
                    <li
                      className="page-item hidden-mobile"
                      onClick={(e) =>
                        setPage((prev) => {
                          return prev - 1;
                        })
                      }
                    >
                      <div className="page-link">{page - 1}</div>
                    </li>
                  )}
                  <li className="page-item hidden-mobile active">
                    <div className="page-link">{page}</div>
                  </li>
                  {page < maxPage && (
                    <li
                      className="page-item hidden-mobile"
                      onClick={(e) =>
                        setPage((prev) => {
                          return prev + 1;
                        })
                      }
                    >
                      <div className="page-link">{page + 1}</div>
                    </li>
                  )}
                  {page < maxPage - 1 && (
                    <li
                      className="page-item hidden-mobile"
                      onClick={(e) => setPage(maxPage)}
                    >
                      <div className="page-link">
                        <Icon icon="material-symbols:last-page" />
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default NewsFeed;
