import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { splitString } from "../../helper";
import AddEmp from "../../components/AddEmp";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function Employee({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [userList, setUserList] = useState([]);
  const [name, setName] = useState("")

  useEffect(() => {
    setSelect("employee");
  }, [setSelect]);

  //pagination
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [trigger, setTrigger] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const [memberEmail, setMemberEmail] = useState("");
  const [memberTab, setMemberTab] = useState(false);
  const [addEmpTab, setAddEmpTab] = useState(false);
  const [role, setRole] = useState(0);
  const getRole = (role) => {
    if(role === 1){
      return "Admin"
    }else if(role === 2){
      return "Nhân viên"
    }
    return ""
  }
  useEffect(() => {
    if (memberTab === false) {
      setTrigger((prev) => {
        return !prev;
      });
    }
  }, [memberTab]);

  const updateEmp = async (update) => {
    fetch(`${apiURL}/employee`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({update}),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setTrigger(prev=>{return !prev})
        }else{
          alert('Có lỗi')
        }
      });
  }
  
  const deleteEmp = async (id) => {
    fetch(`${apiURL}/employee`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({id}),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setTrigger(prev=>{return !prev})
        }else{
          alert('Có lỗi')
        }
      });
  }

  useEffect(() => {
    var set = true;
    fetch(`${apiURL}/employee/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        page,
        name,
        export: false,
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success && set) {
          setTotalCount(
            result.memberList[0].memberList.length > 0
              ? result.memberList[0].count[0]["count"]
              : 0
          );
          setUserList(
            result.memberList[0].memberList.length > 0
              ? result.memberList[0].memberList
              : []
          );
          setMaxPage(
            result.memberList[0].memberList.length > 0
              ? Math.ceil(result.memberList[0].count[0]["count"] / 10)
              : 0
          );
        }
      });
      fetch(apiURL + "/employee/loginCheck", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (set) {
            if (result.success) {
              localStorage &&
                localStorage.setItem(
                  "BayanAdminInfo",
                  JSON.stringify(result.decoded)
                );
                
              setMemberEmail(result?.decoded?.email??"")
              setRole(result?.decoded?.role??0)
            } else {
              setMemberEmail("")
              setRole(0)
            }
          }
        });
    return () => {
      set = false;
    };
  }, [apiURL, name, page, trigger]);

  return (
    <React.Fragment>
      {addEmpTab&& (
        <AddEmp
          setAddEmpTab={setAddEmpTab}
          setTrigger= {setTrigger}
        />
      )}
      <h2 className="MuiTypography-root MuiTypography-h2 css-dip02n">
        Nhân viên
      </h2>
      {role === 1 &&<span className="css-1f0mdg1 mgb20 inline">
        <button
          className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-disableElevation css-1a96ihp"
          tabIndex="0"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            setAddEmpTab(true)
          }}
        >
          Thêm người dùng
          <span className="MuiTouchRipple-root css-w0pj6f"></span>
        </button>
      </span>}

      {userList &&
        userList.map((ele, index) => {
          return (
            <React.Fragment

              key={index}
            >
            <div
              className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1dg90v7"
            >
              <div className="css-idc0u3">
                <span className="css-8g3mcy">
                  <div className="css-u4p24i">
                    <span className="css-zkfegi">
                      <span className="MuiBadge-root BaseBadge-root css-5lanty">
                        <div className="MuiAvatar-root MuiAvatar-circular css-1oj7qn1">
                          {splitString(ele.name ?? "U").toUpperCase()}
                        </div>
                      </span>
                    </span>
                    <span
                      className="css-zkfegi"
                      onClick={(e) => {
                        e.preventDefault();
                        // setMemberTab(true);
                        // setMemberEmail(ele._id);
                        // setTempMember(ele);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <h6 className="MuiTypography-root MuiTypography-h6 css-cvhosu">
                        {ele.name ?? ""}
                      </h6>
                      <p className="MuiTypography-root MuiTypography-body1 css-hxfal">
                        {ele.email ?? ""}
                      </p>
                    </span>
                  </div>
                </span>
                <span className="css-dyqsm1"></span>
                <span className="css-1jbsagp">
                  <div className="css-1wydmn0">
                    <span className="css-zkfegi">
                      <h6 className="MuiTypography-root MuiTypography-h6 css-cvhosu">
                        {(ele.phone ?? "")}
                      </h6>
                      <p className="MuiTypography-root MuiTypography-body1 css-hxfal">
                        Số điện thoại
                      </p>
                    </span>
                    <span className="css-zkfegi width200">
                      <h6 className="MuiTypography-root MuiTypography-h6 css-cvhosu">
                        {getRole(ele.role)}
                      </h6>
                      <p className="MuiTypography-root MuiTypography-body1 css-hxfal">
                        Vai trò
                      </p>
                    </span>
                    
                  </div>
                </span> 
                      {
                      role === 1 && <React.Fragment>

<FormControlLabel
                  checked={ele?.mailReceive??false}
                  style={{width:80}}
                  control={<Switch color="primary" />}
                  label="Nhận mail"
                  labelPlacement="top"
                  onChange={(e)=>{
                    updateEmp({_id:ele._id ,mailReceive: Boolean(e.target.checked)})
                  }}
                />
                    <FormControlLabel
                      disabled = {ele.email === memberEmail ? true : false}
                      checked={ele?.active??false}
                      style={{width:80}}
                      control={<Switch color="primary" />}
                      label="Kích họat"
                      labelPlacement="top"
                      onChange={(e)=>{
                        updateEmp({_id:ele._id ,active: Boolean(e.target.checked)})
                      }}
                    />
                    {<IconButton
                      disabled = {ele.role===1 ? true : false} aria-label="delete"
                      onClick={(e)=>{
                        if(window.confirm("Xác nhận xóa người dùng?")){
                          deleteEmp(ele._id)
                        }
                      }}
                      >
                      <DeleteIcon/>
                    </IconButton> }
                      </React.Fragment>
                      }
              </div>
            </div>
            </React.Fragment>
          );
        })}
        
      <div>
        <div className="row no-gutters justify-content-between paginate">
          <div className="col-auto "></div>
          <div className="col-auto ">
            <div className="row no-gutters align-items-center">
              <div className="col-auto mr-3">
                <span
                  style={{ color: "rgb(128, 128, 128)" }}
                >{`Tổng: ${totalCount}`}</span>
              </div>
              <div className="col">
                <ul className="pagination pagination-lg justify-content-center">
                  {page > 2 && (
                    <li
                      className="page-item hidden-mobile"
                      onClick={(e) => setPage(1)}
                    >
                      <div className="page-link">
                        <Icon icon="material-symbols:first-page" />
                      </div>
                    </li>
                  )}
                  {page > 1 && (
                    <li
                      className="page-item hidden-mobile"
                      onClick={(e) =>
                        setPage((prev) => {
                          return prev - 1;
                        })
                      }
                    >
                      <div className="page-link">{page - 1}</div>
                    </li>
                  )}
                  <li className="page-item hidden-mobile active">
                    <div className="page-link">{page}</div>
                  </li>
                  {page < maxPage && (
                    <li
                      className="page-item hidden-mobile"
                      onClick={(e) =>
                        setPage((prev) => {
                          return prev + 1;
                        })
                      }
                    >
                      <div className="page-link">{page + 1}</div>
                    </li>
                  )}
                  {page < maxPage - 1 && (
                    <li
                      className="page-item hidden-mobile"
                      onClick={(e) => setPage(maxPage)}
                    >
                      <div className="page-link">
                        <Icon icon="material-symbols:last-page" />
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Employee;
