import React, { useEffect, useState } from "react";
import { moneyFormat } from "../../helper";
import Button from "@mui/material/Button";
import * as XLSX from "xlsx";

function Guided({ setSelect }) {
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const [dateSelect, setDateSelect] = useState("");
  const [guidedList, setGuidedList] = useState([]);
  const [monthList, setMonthList] = useState(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Lấy tháng hiện tại
    const currentYear = currentDate.getFullYear(); // Lấy năm hiện tại

    const monthYearList = [];

    // Bắt đầu từ tháng hiện tại và năm hiện tại
    let month = currentMonth;
    let year = currentYear;

    // Lặp từ tháng hiện tại trở về đầu năm 2023
    while (year > 2023) {
      monthYearList.push([month, year]);
      // Giảm tháng đi 1
      month--;
      if (month === 0) {
        // Nếu tháng bằng 0 thì chuyển sang năm trước đó
        month = 12; // Tháng cuối năm
        year--;
      }
    }
    // Gán giá trị cho dateSelect
    if (monthYearList.length > 0) {
      setDateSelect(`${monthYearList[0][0]}/${monthYearList[0][1]}`);
    }
    return monthYearList;
  });

  useEffect(() => {
    let set = true;
    if (dateSelect !== "") {
      fetch(`${apiURL}/invoice/guided`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + localStorage.banyanAdminToken
              ? localStorage.banyanAdminToken
              : "",
        },
        body: JSON.stringify({ dateSelect }),
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success && set) {
            setGuidedList(result?.results??[])
          } else {
          }
        });
    }
    return () => {
      set = false;
    };
  }, [dateSelect]);

  useEffect(() => {
    setSelect("guided");
  }, []);

  

  const exportFile = async () => {
    
    try { 
      if (guidedList.length > 0) {
        
        var data = [
          [
            `Tổng đơn: ${(
              guidedList.reduce((pre, cur) => {
                return pre + cur[0].count + cur[1].count + cur[2].count;
              }, 0)
            )}`
          ],
          [
            `Tổng giá trị: ${(
              guidedList.reduce((pre, cur) => {
                return pre + cur[0].value + cur[1].value + cur[2].value;
              }, 0)
            )}`
          ],
          [],
          [ 
            "Ngày", "Nguồn", "Số đơn", "Số tiền", "Tổng đơn", "Tổng doanh thu"
          ],  
        ];
        guidedList.map((ele, index) => {
          data.push(
            [`${index + 1}/${dateSelect}`,"FB", ele[0].count, (ele[0].value), ele[0].count+ele[1].count+ele[2].count, (ele[0].value+ele[1].value+ele[2].value)],
            [`${index + 1}/${dateSelect}`,"Google", ele[1].count, (ele[1].value)],
            [`${index + 1}/${dateSelect}`,"Web", ele[2].count, (ele[2].value)]
          );
        });

        convertDataToExcel(data);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  

  function convertDataToExcel(arr) {
    var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(arr); //array of array

    // Set number format for cells containing numbers greater than or equal to 1000
    var range = XLSX.utils.decode_range(ws['!ref']);
    for (var rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        for (var colNum = range.s.c; colNum <= range.e.c; colNum++) {
            var cellAddress = { c: colNum, r: rowNum };
            var cellRef = XLSX.utils.encode_cell(cellAddress);
            var cell = ws[cellRef];
            if (cell && !isNaN(cell.v) && cell.t === 'n' && cell.v >= 1000) {
                cell.z = '0,000';
            }
        }
    }

    XLSX.utils.book_append_sheet(wb, ws, "guided");
    ws["!cols"] = fitToColumn(arr);
    ws["!cols"][3] = { wch: 20 };
    ws["!cols"][5] = { wch: 20 };
    XLSX.writeFile(wb, "Guided.xlsx");
}

  

  function fitToColumn(arrayOfArray) {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({
      wch: Math.max(
        ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
      ),
    }));
  }

  return (
    <React.Fragment>
      <div className="AdminTab">
        <h1>Báo cáo guided</h1>

        <span className="css-1f0mdg1 mgb20 inline"></span>
        <div className="flexRow mgb50">
          <div className="unitBox w100 mr20">
            <div className="headerBox">Báo cáo guided</div>
            <div className="contentBox">
              <div>
                <div className="">
                  <div className="form-group px-0 inline mr20">
                    <div className="inline">
                      <label className="label-input-group">Thời gian</label>
                      <div className="buttonDiv">
                        <select
                          className="next-input"
                          style={{
                            width: "200px",
                            marginRight: "20px",
                            marginBottom: "5px",
                            marginLeft: "0",
                          }}
                          value={dateSelect}
                          onChange={(e) => setDateSelect(e.target.value)}
                        >
                          {monthList.map((item, index) => (
                            <option key={index} value={`${item[0]}/${item[1]}`}>
                              {`${item[0]}/${item[1]}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  
            <Button
              variant="contained"
              style={{
                marginLeft: 20,
                textTransform: "none",
                fontSize: "14px",
              }}
              onClick={(e) => {
                exportFile()
              }}
            >
              Xuất file
            </Button>
                </div>
              </div>

              <label className="label-input-group">{`Tổng đơn: ${moneyFormat(
                guidedList.reduce((pre, cur) => {
                  return pre + cur[0].count + cur[1].count + cur[2].count;
                }, 0)
              )}`}</label>
              <br/>
              <label style={{paddingBottom:"15px"}} className="label-input-group">{`Tổng giá trị: ${moneyFormat(
                guidedList.reduce((pre, cur) => {
                  return pre + cur[0].value + cur[1].value + cur[2].value;
                }, 0)
              )}`}</label>
              <div className="position-relative">
                <div className="table-list--config">
                  <div className="ui-table-normal-container" style={{maxHeight : "700px", overflowY:"auto"}}>
                    <table className="ui-table">
                      <thead id = 'guided'>
                        <tr style = {{position:"sticky", top : "0px"}}>
                          <th className="" style={{width:"120px"}}>
                            <span>Ngày</span>
                          </th>
                          <th className="">
                            <span>Nguồn</span>
                          </th>
                          <th className="">
                            <span>Số đơn</span>
                          </th>
                          <th className="">
                            <span>Số tiền</span>
                          </th>
                          <th className="">
                            <span>Tổng đơn</span>
                          </th>
                          <th className="">
                            <span>Tổng doanh thu</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {guidedList &&
                          guidedList.map((ele, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr
                                  style={{
                                    borderBottom: "1px solid #eee",
                                  }}
                                >
                                  <td className="align-top white-space-normal">
                                    {`${index + 1}/${dateSelect}`}
                                  </td>
                                  <td className="align-top white-space-normal">
                                    FB
                                  </td>
                                  <td className={`align-top pt-3 `}>
                                    {ele[0].count}
                                  </td>
                                  <td className={`align-top pt-3 `}>
                                    {moneyFormat(ele[0].value)}
                                  </td>
                                  <td className={`align-top pt-3 `}>
                                    {(ele[0].count+ele[1].count+ele[2].count)}
                                  </td>
                                  <td className={`align-top pt-3 `}>
                                    {moneyFormat(ele[0].value+ele[1].value+ele[2].value)}
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    borderBottom: "1px solid #eee",
                                  }}
                                >
                                  <td className="align-top white-space-normal">
                                    {/* {`${index + 1}/${dateSelect}`} */}
                                  </td>
                                  <td className="align-top white-space-normal">
                                    Google
                                  </td>
                                  <td className={`align-top pt-3 `}>
                                    {ele[1].count}
                                  </td>
                                  <td className={`align-top pt-3 `}>
                                    {moneyFormat(ele[1].value)}
                                  </td>
                                </tr>

                                <tr
                                  style={{
                                    borderBottom: "1.5px solid #000",
                                  }}
                                >
                                  <td className="align-top white-space-normal">
                                    {/* {`${index + 1}/${dateSelect}`} */}
                                  </td>
                                  <td className="align-top white-space-normal">
                                    Web AT
                                  </td>
                                  <td className={`align-top pt-3 `}>
                                    {ele[2].count}
                                  </td>
                                  <td className={`align-top pt-3 `}>
                                    {moneyFormat(ele[2].value)}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Guided;
