import React, { useEffect, useState } from "react";
import Api from "../../api/apis";
import { moneyFormat } from "../../helper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function Shipping({ setSelect }) {
  const [channelInfo, setChannelInfo] = useState({});
  const [trigger, setTrigger] = useState(0);
  const [focus, setFocus] = useState({});

  useEffect(() => {
    setSelect("shipping");
    let set = true;

    const fetchData = async () => {
      try {
        let rs = {};
        rs = { ...(await Api.findChannel("American Tourister")) };
        if (set && rs.success) {
          setChannelInfo(rs.channel);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();

    return () => {
      set = false;
    };
  }, [setSelect, trigger]);

  const updateChannel = async () => {
    try {
      let rs = {};
      rs = {
        ...(await Api.updateChannelByName(channelInfo.name ?? "", channelInfo)),
      };
      if (rs.success) {
        alert(rs.message);
        setTrigger((prev) => {
          return prev + 1;
        });
      } else {
        alert(rs.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFocus = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: true,
    }));
  };

  const handleBlur = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: false,
    }));
  };

  return (
    <React.Fragment>
      <div className="page">
        <div className="edit_container">
          <section className="edit_section" style={{ marginBottom: "130px" }}>
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Phí giao hàng</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Phí ship nội thành</span>
                      </div>
                      <div className="edit-main" style={{ display: "flex" }}>
                        <div className="popover-wrap" style={{ width: "25%" }}>
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner input-container ${
                                  focus.urban ? "focused" : ""
                                }`}
                                style={{
                                  position: "relative",
                                  marginBottom: "20px",
                                }}
                              >
                                <input
                                  //   type="number"
                                  value={moneyFormat(
                                    channelInfo &&
                                      channelInfo.shipDetail &&
                                      channelInfo.shipDetail.urban &&
                                      channelInfo.shipDetail.urban[0]
                                      ? channelInfo.shipDetail.urban[0]
                                      : 0
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    const urban = [...(shipDetail.urban ?? [])];
                                    urban[0] = Number(value);
                                    shipDetail = {
                                      ...shipDetail,
                                      urban,
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("urban")}
                                  onBlur={() => handleBlur("urban")}
                                />

                                <label
                                  htmlFor="myInput"
                                  className="input-label"
                                >
                                  Cabin
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="popover-wrap"
                          style={{ width: "25%", marginLeft: "5px" }}
                        >
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner input-container ${
                                  focus.urban ? "focused" : ""
                                }`}
                                style={{
                                  position: "relative",
                                  marginBottom: "20px",
                                }}
                              >
                                <input
                                  //   type="number"
                                  value={moneyFormat(
                                    channelInfo &&
                                      channelInfo.shipDetail &&
                                      channelInfo.shipDetail.urban &&
                                      channelInfo.shipDetail.urban[1]
                                      ? channelInfo.shipDetail.urban[1]
                                      : 0
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    const urban = [...(shipDetail.urban ?? [])];
                                    urban[1] = Number(value);
                                    shipDetail = {
                                      ...shipDetail,
                                      urban,
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("urban")}
                                  onBlur={() => handleBlur("urban")}
                                />

                                <label
                                  htmlFor="myInput"
                                  className="input-label"
                                >
                                  Trung
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="popover-wrap"
                          style={{ width: "25%", marginLeft: "5px" }}
                        >
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner input-container ${
                                  focus.urban ? "focused" : ""
                                }`}
                                style={{
                                  position: "relative",
                                  marginBottom: "20px",
                                }}
                              >
                                <input
                                  //   type="number"
                                  value={moneyFormat(
                                    channelInfo &&
                                      channelInfo.shipDetail &&
                                      channelInfo.shipDetail.urban &&
                                      channelInfo.shipDetail.urban[2]
                                      ? channelInfo.shipDetail.urban[2]
                                      : 0
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    const urban = [...(shipDetail.urban ?? [])];
                                    urban[2] = Number(value);
                                    shipDetail = {
                                      ...shipDetail,
                                      urban,
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("urban")}
                                  onBlur={() => handleBlur("urban")}
                                />

                                <label
                                  htmlFor="myInput"
                                  className="input-label"
                                >
                                  Đại
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="popover-wrap"
                          style={{ width: "25%", marginLeft: "5px" }}
                        >
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner input-container ${
                                  focus.urban ? "focused" : ""
                                }`}
                                style={{
                                  position: "relative",
                                  marginBottom: "20px",
                                }}
                              >
                                <input
                                  //   type="number"
                                  value={moneyFormat(
                                    channelInfo &&
                                      channelInfo.shipDetail &&
                                      channelInfo.shipDetail.urban &&
                                      channelInfo.shipDetail.urban[3]
                                      ? channelInfo.shipDetail.urban[3]
                                      : 0
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    const urban = [...(shipDetail.urban ?? [])];
                                    urban[3] = Number(value);
                                    shipDetail = {
                                      ...shipDetail,
                                      urban,
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("urban")}
                                  onBlur={() => handleBlur("urban")}
                                />

                                <label
                                  htmlFor="myInput"
                                  className="input-label"
                                >
                                  Khác
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Phí ship tỉnh</span>
                      </div>
                      <div className="edit-main" style={{ display: "flex" }}>
                        <div className="popover-wrap" style={{ width: "25%" }}>
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner input-container ${
                                  focus.rural ? "focused" : ""
                                }`}
                                style={{
                                  position: "relative",
                                  marginBottom: "20px",
                                }}
                              >
                                <input
                                  //   type="number"
                                  value={moneyFormat(
                                    channelInfo &&
                                      channelInfo.shipDetail &&
                                      channelInfo.shipDetail.rural &&
                                      channelInfo.shipDetail.rural[0]
                                      ? channelInfo.shipDetail.rural[0]
                                      : 0
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    const rural = [...(shipDetail.rural ?? [])];
                                    rural[0] = Number(value);
                                    shipDetail = {
                                      ...shipDetail,
                                      rural,
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("rural")}
                                  onBlur={() => handleBlur("rural")}
                                />

                                <label
                                  htmlFor="myInput"
                                  className="input-label"
                                >
                                  Cabin
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="popover-wrap"
                          style={{ width: "25%", marginLeft: "5px" }}
                        >
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner input-container ${
                                  focus.rural ? "focused" : ""
                                }`}
                                style={{
                                  position: "relative",
                                  marginBottom: "20px",
                                }}
                              >
                                <input
                                  //   type="number"
                                  value={moneyFormat(
                                    channelInfo &&
                                      channelInfo.shipDetail &&
                                      channelInfo.shipDetail.rural &&
                                      channelInfo.shipDetail.rural[1]
                                      ? channelInfo.shipDetail.rural[1]
                                      : 0
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    const rural = [...(shipDetail.rural ?? [])];
                                    rural[1] = Number(value);
                                    shipDetail = {
                                      ...shipDetail,
                                      rural,
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("rural")}
                                  onBlur={() => handleBlur("rural")}
                                />

                                <label
                                  htmlFor="myInput"
                                  className="input-label"
                                >
                                  Trung
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="popover-wrap"
                          style={{ width: "25%", marginLeft: "5px" }}
                        >
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner input-container ${
                                  focus.rural ? "focused" : ""
                                }`}
                                style={{
                                  position: "relative",
                                  marginBottom: "20px",
                                }}
                              >
                                <input
                                  //   type="number"
                                  value={moneyFormat(
                                    channelInfo &&
                                      channelInfo.shipDetail &&
                                      channelInfo.shipDetail.rural &&
                                      channelInfo.shipDetail.rural[2]
                                      ? channelInfo.shipDetail.rural[2]
                                      : 0
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    const rural = [...(shipDetail.rural ?? [])];
                                    rural[2] = Number(value);
                                    shipDetail = {
                                      ...shipDetail,
                                      rural,
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("rural")}
                                  onBlur={() => handleBlur("rural")}
                                />

                                <label
                                  htmlFor="myInput"
                                  className="input-label"
                                >
                                  Đại
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="popover-wrap"
                          style={{ width: "25%", marginLeft: "5px" }}
                        >
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner input-container ${
                                  focus.rural ? "focused" : ""
                                }`}
                                style={{
                                  position: "relative",
                                  marginBottom: "20px",
                                }}
                              >
                                <input
                                  //   type="number"
                                  value={moneyFormat(
                                    channelInfo &&
                                      channelInfo.shipDetail &&
                                      channelInfo.shipDetail.rural &&
                                      channelInfo.shipDetail.rural[3]
                                      ? channelInfo.shipDetail.rural[3]
                                      : 0
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    const rural = [...(shipDetail.rural ?? [])];
                                    rural[3] = Number(value);
                                    shipDetail = {
                                      ...shipDetail,
                                      rural,
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("rural")}
                                  onBlur={() => handleBlur("rural")}
                                />

                                <label
                                  htmlFor="myInput"
                                  className="input-label"
                                >
                                  Khác
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Điều kiện miễn ship</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    channelInfo &&
                                    channelInfo.shipDetail &&
                                    channelInfo.shipDetail.salesDiscount
                                      ? channelInfo.shipDetail.salesDiscount
                                      : false
                                  }
                                  onChange={(e) => {
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    shipDetail = {
                                      ...shipDetail,
                                      salesDiscount: e.target.checked,
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                />
                              }
                              label="Có chương trình"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Tổng mặt hàng tối thiểu</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.minAmount ? "focused" : ""
                                }`}
                              >
                                <input
                                  //   type="number"
                                  value={
                                    channelInfo &&
                                    channelInfo.shipDetail &&
                                    channelInfo.shipDetail.minAmount
                                      ? channelInfo.shipDetail.minAmount
                                      : 0
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    shipDetail = {
                                      ...shipDetail,
                                      minAmount: Number(value),
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("minAmount")}
                                  onBlur={() => handleBlur("minAmount")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Giá trị tối thiểu</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.minValue ? "focused" : ""
                                }`}
                              >
                                <input
                                  //   type="number"
                                  value={moneyFormat(
                                    channelInfo &&
                                      channelInfo.shipDetail &&
                                      channelInfo.shipDetail.minValue
                                      ? channelInfo.shipDetail.minValue
                                      : 0
                                  )}
                                  onChange={(e) => {
                                    const value = e.target.value
                                      .replace(/^0+/, "")
                                      .replace(/[^0-9]/g, "");
                                    let newObject = { ...channelInfo };
                                    let shipDetail = {
                                      ...(newObject.shipDetail ?? {}),
                                    };
                                    shipDetail = {
                                      ...shipDetail,
                                      minValue: Number(value),
                                    };
                                    newObject = { ...newObject, shipDetail };
                                    setChannelInfo(newObject);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("minValue")}
                                  onBlur={() => handleBlur("minValue")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Xác nhận cập nhật nội dung phí ship?"
                                      )
                                    ) {
                                      updateChannel();
                                    }
                                  }}
                                >
                                  Cập nhật
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Shipping;
