import React, { useEffect, useState  } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
//text customize
import { CKEditor } from "ckeditor4-react";
// multiple check

import {moneyFormat} from '../../helper'

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddProductInfo from "../../components/AddProductInfo";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';import { SvgIcon } from '@mui/material';
import { useSearchParams } from "react-router-dom";
import Api from '../../api/apis'
import Autocomplete from '@mui/material/Autocomplete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const tableStyle = {
  borderLeft: "1px solid #ccc", // Thêm đường viền bên trái
  borderRight: "1px solid #ccc", // Thêm đường viền bên phải
}


const categoryValue = {
  1: "Vali",
  2: "Balo",
  3: "Túi xách",
  4: "Phụ kiện",
};

function ProductInfo({ setSelect }) {
  const colorList = {
  "Nude": "Be",
  "Black": "Đen",
  "Blue": "Xanh dương",
  "Green": "Xanh lá",
  "Grey": "Xám",
  "Light Blue": "Xanh nhạt",
  "Navy" : "Xanh đen",
  "Orange": "Cam",
  "Pink": "Hồng",
  "Purple": "Tím",
  "Red": "Đỏ",
  "Silver" : "Bạc",
  "White": "Trắng",
  "Yellow": "Vàng",
  "Brown" :"Nâu",
}
  const apiURL = process.env.REACT_APP_API_ENDPOINT;
  const cloudFrontURL = process.env.REACT_APP_CLOUDFRONT_ENDPOINT;
  //list nhóm phân loại để discount
    
  const [groupForProduct, setGroupForProduct] = useState([]);
  const [groupAddType, setGroupAddType] = useState(1);
  const [addProductInfoTab, setAddProductInfoTab] = useState(false);
  const [addType, setAddType] = useState("");


  const [isProduct, setIsProduct] = useState(true)
  const [productFind, setProductFind] = useState({})
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(true);
  const [showImgList, setShowImgList] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [sizeDiff, setSizeDiff] = useState(false);
  const [groupLabelListAll, setGroupLabelListAll] = useState([]);

  const [focus, setFocus] = useState({});
  const [productType, setProductType] = useState(1);
  const [name, setName] = useState("");
  const [SEOLink, setSEOLink] = useState("");
  const [sizeArray, setSizeArray] = useState([false, false, false]);
  const [sizeArrayDesc, setSizeArrayDesc] = useState(["Cabin", "Trung", "Đại"]);
  const [sizeArrayLen, setSizeArrayLen] = useState(["", "", ""]);
  const [nonSizeDesc, setNonSizeDesc] = useState("");
  const [colorArray, setColorArray] = useState([``]);
  const [colorArrayDesc, setColorArrayDesc] = useState([``]);

  const [productList, setProductList] = useState([[]])
  const [productImgList, setProductImgList] = useState([[]])
  const [colorImgList, setColorImgList] = useState([undefined])

  const [description, setDescription] = useState("");
  const [allSet, setAllSet] = useState([0,0,""])
  const onDescriptionChange = (event) => {
    setDescription(event.editor.getData());
  };
 
  
  useEffect(()=>{
    if(isProduct===true && productFind._id){
      setDescription(productFind.description??"")
      setShow(productFind.show??true)
      setName(productFind.name??"")
      setSEOLink(productFind.SEOLink??"")
      setProductType(productFind.productType??1)
      setGroupForProduct(productFind.categoryIndex)
      setSizeArrayDesc(productFind.sizeArrayDesc??['','',''])
      setSizeArrayLen(productFind.sizeArrayLen??['','',''])
      setNonSizeDesc(productFind.nonSizeDesc??"")
      setSizeArray(productFind.sizeArray??[false,false,false])
      setSizeDiff(productFind.sizeDiff??true)

      setColorArray(productFind.colorArray??[''])
      if( (productFind.colorArrayDesc??['']).length < (productFind.colorArray??['']).length ) {
        setColorArrayDesc(new Array((productFind.colorArray??['']).length ).fill(""))
      }else{
        setColorArrayDesc(productFind.colorArrayDesc??[''])
      }

      setProductList(productFind.productList??[[]])
      setProductImgList(productFind.productImgListUrl??[[[]]])
      setColorImgList(productFind.colorArrayUrl)
    }
  },[groupLabelListAll, isProduct, productFind])
  

  const product = {
    price: 0,
    remain : 0,
    sku : "",
    img : [],
    show : true,
    popular : false,
    new : false
  }
  const getSizeName = (index) => {
    let rs = -99;
    let count = 0;
  
    for (let idx = 0; idx < sizeArray.length; idx++) {
      if (sizeArray[idx]) {
        if (count === index) {
          rs = sizeArrayDesc[idx];
          break; // Dừng vòng lặp ngay khi tìm thấy phần tử thứ index có giá trị true
        } else {
          count++;
        }
      }
    }
  
    return rs;
  };

  
  const [swapProductImage, setSwapProductImage] = useState([[[]]]);
  const [firstSwapProductImage, setFirstSwapProductImage] = useState([[]]);

  const swap = (arr, idx, swapIdx) => {
    let toChange = idx[1],
      change = idx[0];
    if (idx[0] !== swapIdx) {
      toChange = idx[0];
      change = idx[1];
    }
    if (swapIdx !== arr.length) {
      if (toChange === arr.length) {
        const removedElement = arr.splice(swapIdx, 1);
        arr.push(removedElement[0]);
        return arr;
      } else {
        const removedElement = arr.splice(swapIdx, 1)[0];
        arr.splice(
          change > toChange ? toChange : toChange - 1,
          0,
          removedElement
        );
        return arr;
      }
    } else {
      return arr;
    }
  };

  //cập nhật swap mỗi khi thay đổi số lượng biến thể, số lượng hình ảnh sản phẩm
  useEffect(() => {
    if(productImgList.length>0){
      const arr = new Array(productImgList.length).fill(
        []
      );
      const arrF = new Array(productImgList.length).fill(
        new Array(productImgList[0].length).fill(-1)
      );
      for (let i in productImgList) {
        arr[i] = new Array(productImgList[i].length).fill([])
        for (let j in productImgList[i]) {
          arr[i][j] = new Array(productImgList[i][j].length + 1).fill(0);
        }
      }
      setSwapProductImage(arr);
      setFirstSwapProductImage(arrF);
    }
  }, [productImgList]);

  useEffect(() => {
    const zeroIndices = new Array(swapProductImage.length).fill(
      new Array(swapProductImage[0].length).fill([])
    );
    for (let i in swapProductImage) {
      for (let j in swapProductImage[i]) {
        zeroIndices[i][j] = swapProductImage[i][j].reduce(
          (indices, value, index) => {
            if (value === 1) {
              indices.push(index);
            }
            return indices;
          },
          []
        );
      }
    }
    
    for (let i in zeroIndices) {
      for (let j in zeroIndices[i]) {
        if (zeroIndices[i][j].length === 1) {
          let temp = [...firstSwapProductImage];
          temp[i][j] = zeroIndices[i][j][0];
          setFirstSwapProductImage([...temp]);
          break;
        }
        if (zeroIndices[i][j].length === 2) {
          if (zeroIndices[i][j][0] !== swapProductImage.length) {
            let temp = [...productImgList];
            let temp1 = [...temp[i]];
            let temp2 = [...temp1[j]];
            let newArr = swap(
              temp2,
              zeroIndices[i][j],
              firstSwapProductImage[i][j]
            );
            temp[i][j] = [...newArr];
            setProductImgList([...temp]);
          }

          break;
        }
      }
    }
  }, [swapProductImage]);

  useEffect(() => {
    if(sizeDiff === true && !sizeArray.includes(true)){
      setProductList([])
      setProductImgList([])
    }else if(sizeDiff){
      setProductList(new Array(sizeArray.reduce((acc, current) => (current === true ? acc + 1 : acc), 0)).fill(new Array(colorArray.length).fill(product)))
      setProductImgList(new Array(sizeArray.reduce((acc, current) => (current === true ? acc + 1 : acc), 0)).fill(new Array(colorArray.length).fill([])))
    }else if(!sizeDiff){
      if(colorArray.length>=1 && Array.isArray(colorArray)){
        setProductImgList([new Array(colorArray.length).fill([])])
        setProductList([new Array(colorArray.length).fill(product)])
      }
    }
  }, [sizeDiff]);


  useEffect(() => {
    if(sizeDiff === true && !sizeArray.includes(true)){
      setProductList([])
    }
  }, [sizeArray]);
  
  const handleProductListChange = (type, sizeIndex, colorIndex, value) => {
    const newProductList = [...productList]
    if(type === 'price'){
      newProductList[sizeIndex] = [...newProductList[sizeIndex]]
      newProductList[sizeIndex][colorIndex] = { ...newProductList[sizeIndex][colorIndex], price: value };
    }else if (type === 'remain'){
      newProductList[sizeIndex] = [...newProductList[sizeIndex]]
      newProductList[sizeIndex][colorIndex] = { ...newProductList[sizeIndex][colorIndex], remain: value };
    }else if (type === 'sku'){
      newProductList[sizeIndex] = [...newProductList[sizeIndex]]
      newProductList[sizeIndex][colorIndex] = { ...newProductList[sizeIndex][colorIndex], sku: value };
    }else if (type === 'show'){
      newProductList[sizeIndex] = [...newProductList[sizeIndex]]
      newProductList[sizeIndex][colorIndex] = { ...newProductList[sizeIndex][colorIndex], show: !newProductList[sizeIndex][colorIndex]?.show??false };
    }else if (type === 'popular'){
      newProductList[sizeIndex] = [...newProductList[sizeIndex]]
      newProductList[sizeIndex][colorIndex] = { ...newProductList[sizeIndex][colorIndex], popular: !newProductList[sizeIndex][colorIndex]?.popular??false };
    }else if (type === 'new'){
      newProductList[sizeIndex] = [...newProductList[sizeIndex]]
      newProductList[sizeIndex][colorIndex] = { ...newProductList[sizeIndex][colorIndex], new: !newProductList[sizeIndex][colorIndex]?.new??false };
    }
    setProductList(newProductList)
  }

  

  const handleSizeColorChange = (type, index, value, changeType) => {
    if(type === 'size' && sizeDiff === true){
      const newArray = [...sizeArray]
      newArray[index] = value
      let c = 0
      newArray.map((ele,idx)=> {
        if(idx < index){
          c += newArray[idx] ? 1 : 0 
        }
      })
      const newProductImgList = [...productImgList]
      const newProductList = [...productList]
      if(value === true){
        newProductList.splice(c,0,new Array(colorArray.length).fill(product))
        newProductImgList.splice(c,0,new Array(colorArray.length).fill([]))
      }else{
        newProductList.splice(c,1)
        newProductImgList.splice(c,1)
      }
      setProductList(newProductList)
      setSizeArray(newArray)
      setProductImgList(newProductImgList)
    }else if (type ==='color'){
      if(changeType === 'add'){
        const newProductList = []
        productList.map((ele,idx)=>{
          newProductList.push([...ele, product])
        })
        setProductList(newProductList)
        const newArray = [...colorArray]
        newArray.push(value)
        setColorArray(newArray)
        const newArrayDesc = [...colorArrayDesc]
        newArrayDesc.push("")
        setColorArrayDesc(newArrayDesc)
        const newColorImgList = [...colorImgList]
        newColorImgList.push(undefined)
        setColorImgList(newColorImgList)
        const newProductImgList = productImgList.map((ele) => [...ele, [undefined]]);
        setProductImgList(newProductImgList);
      }else if (changeType === 'delete') {
        const newProductList = productList.map((ele) => {
          return ele.filter((color, idx) => idx !== index);
        });
        setProductList(newProductList);
      
        const newArray = [...colorArray];
        newArray.splice(index, 1);
        setColorArray(newArray);
        
        const newArrayDesc = [...colorArrayDesc];
        newArrayDesc.splice(index, 1);
        setColorArrayDesc(newArrayDesc);
      
        const newColorImgList = [...colorImgList];
        newColorImgList.splice(index, 1);
        setColorImgList(newColorImgList);
      
        const newProductImgList = productImgList.map((ele) => {
          return ele.filter((color, idx) => idx !== index);
        });
        setProductImgList(newProductImgList);
      }
    }
  };


  useEffect(() => {
    setSelect("allproduct");
    let id = searchParams.get("id") ?? "";
    if (id !== "") {
      fetch(`${apiURL}/product/find?id=` + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
        },
      })
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
        })
        .then((result) => {
          if (result.success) {
            setProductFind(result.product);
            setIsProduct(true)
          } else {
            setIsProduct(false)
          }
        });
    }
    fetch(`${apiURL}/product/getchanneldetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        type: "groupcategory",
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setGroupLabelListAll(result.list);
        }
      });
  }, []);

  
  useEffect(() => {
    fetch(`${apiURL}/product/getchanneldetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        type: "groupcategory",
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          setGroupLabelListAll(result.list);
        }
      });
  }, [trigger]);

  const handleFocus = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: true,
    }));
  };

  const handleBlur = (fieldName) => {
    setFocus((prevFocus) => ({
      ...prevFocus,
      [fieldName]: false,
    }));
  };

  const preloadImage = (url) => {
    const img = new Image();
    img.onload = () => {
    };
    img.src = cloudFrontURL+`/`+url;
  };

  useEffect(() => {
    
    const productImgListUrl = productFind.productImgListUrl??[];
      
    for (let i = 0; i < productImgListUrl.length; i++) {
      const subArray = productImgListUrl[i];
      for (let j = 0; j < subArray.length; j++) {
        const imgArray = subArray[j];
        for (let k = 0; k < imgArray.length; k++) {
          const url = imgArray[k];
          preloadImage(url);
        }
      }
    }
  }, [productFind]);

  const updateProduct = async(e) => {
    //check
    e.preventDefault();

    if (name === "") {
      alert("Nhập tên sản phẩm");
      return;
    }
    if (colorArray.includes("") || colorImgList.includes(null) || colorImgList.includes(undefined) || colorImgList.length === 0) {
      alert("Chọn màu và ảnh cho màu");
      return;
    }
    if (SEOLink === "") {
      alert("Nhập SEOlink");
      return;
    }

    // check product img
    let pImageCheck = true
    productImgList.length>0&&(
      productImgList.map((ele,sizeIdx)=>{
        ele.map((ele1,colorIdx)=>{
          if((ele1.length === 0 || ele1[0] === null || ele1[0] === undefined )&& sizeIdx < productList.length){
            pImageCheck = false
          }
        })
      })
    )
    if(!pImageCheck){
      alert('Chọn hình cho sản phẩm')
      return;
    }

    // CHECK seolink and sku

    // create arrays for all sku of group and active sku
    const SKUArrayActive = []
    const SkuArrayAll = []

    productList.length !== 0 &&
    (
      productList.map((ele,sizeIdx)=> {
        ele.map((ele1,colorIdx)=>{
          
          if(ele1['show']){
            SKUArrayActive.push(ele1['sku'])
          }
          SkuArrayAll.push(ele1['sku'])
        })
      })
    )
    if(SkuArrayAll.includes("")){
      alert("Không để trống Sku");
      return;
    }
    if(new Set(SkuArrayAll).size < SkuArrayAll.length){
      alert("Sku trùng nhau");
      return;
    }
    //check active sku

    let skuCheck = true, seoCheck = true

    //check seolink group if already exits
    fetch(apiURL + "/product/checkseo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({ 
        exceptId : true,
        id : productFind._id,
        SEOLink
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success === true) {
          if(result.check === false){
            seoCheck = false
          }
        }else{
          alert(result.message??"Có lỗi")
          return
        }
      })
      .catch((e)=>{
        alert(e.message??"Có lỗi 1")
        return
      });
    
    if(!seoCheck){
      alert('SEO link đã tồn tại')
      return
    }
    if(!skuCheck){
      alert('Có Sku đã tồn tại và đang được bán')
      return
    }
    //create img

    const colorArrayUrl = new Array(colorImgList.length).fill("")
    for (let t = 0; t < colorImgList.length; t++) {
      if(typeof colorImgList[t] === 'string'){
        colorArrayUrl[t] = colorImgList[t]
      }else{
        let formData = new FormData();
        formData.append("img", colorImgList[t]);
        try {
          const res = await axios.post(`${apiURL}/product/image`, 
          formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          colorArrayUrl[t] = res.data.result.Key ?? "";
        } catch (err) {
          console.log(err);
          alert('Lỗi tạo ảnh màu')
          return
        }
      }
    }
// Khởi tạo mảng 2 chiều với các mảng con độc lập

    let productImgListUrl = new Array(productImgList.length);
    for (let t = 0; t < productImgList.length; t++) {
      productImgListUrl[t] = new Array(colorArray.length).fill([]);
    }
    for (let t = 0; t < productImgList.length; t++) {
      for (let i = 0; i < colorArray.length; i++) {
        
        try {
          if(typeof productImgList[t][i][0] === 'string'){
            productImgListUrl[t][i] = productImgList[t][i]
          }else{
            const formData = new FormData();
            productImgList[t][i].forEach((image) => {
              formData.append('images', image);
            });
        
            const response = await axios.post(`${apiURL}/product/images`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            if(response.data.success === true){
              productImgListUrl[t][i] = Api.getLocationArray(response.data.results)
  
            }else{
              throw new Error('Lỗi')
            }
          }
        } catch (err) {
          console.log(err)
          alert('Lỗi tải ảnh sản phẩm, thử lại')
          return
        }
      }
    }

    
    const categoryIndex = groupForProduct

    fetch(apiURL + "/product", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + localStorage.banyanAdminToken
            ? localStorage.banyanAdminToken
            : "",
      },
      body: JSON.stringify({
        show,
        categoryIndex,
        name,
        SEOLink,
        productType,
        description,
        sizeDiff,
        sizeArray,
        sizeArrayDesc,
        colorArray,
        colorArrayUrl,
        SKUArrayActive,
        SkuArrayAll,
        productList,
        productImgListUrl,
        nonSizeDesc,
        sizeArrayLen,
        id: productFind._id,
        groupForProduct,
        colorArrayDesc
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error("Something went wrong");
      })
      .then((result) => {
        if (result.success) {
          alert("Cập nhật sản phẩm");
        }else{
          alert(result.message)
          return
        }
      });

  }

  return ( 
    (isProduct&&productFind._id)?<React.Fragment>
      <div className="page productDetail">
        <div className="edit_container">
          <section className="edit_section">
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Thông tin cơ bản</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span></span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={show}
                                  onChange={(e) => {
                                    return setShow(e.target.checked);
                                  }}
                                />
                              }
                              label="Hiện bán"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <div className="mandatory">
                          <div className="mandatory-icon">*</div>
                        </div>
                        <span>Tên sản phẩm</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.name ? "focused" : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  value={name}
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("name")}
                                  onBlur={() => handleBlur("name")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <div className="mandatory">
                          <div className="mandatory-icon">*</div>
                        </div>
                        <span>SEO link</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div
                                className={`input_inner ${
                                  focus.SEOLink ? "focused" : ""
                                }`}
                              >
                                <input
                                  type="text"
                                  value={SEOLink}
                                  onChange={(e) => {
                                    const regex = /^[a-zA-Z0-9-_]*$/;
                                    const value = e.target.value;
                                    if (regex.test(value)) {
                                      setSEOLink(value);
                                    }
                                  }}
                                  className="input_input"
                                  onFocus={() => handleFocus("SEOLink")}
                                  onBlur={() => handleBlur("SEOLink")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <div className="mandatory">
                          <div className="mandatory-icon">*</div>
                        </div>
                        <span>Ngành hàng</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div style={{ lineHeight: "40px" }}>
                            <Box sx={{ minWidth: 120 }}>
                              <FormControl fullWidth size="small">
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={productType}
                                  disable
                                  onChange={(e) => {
                                    setProductType(e.target.value);
                                  }}
                                >
                                  {Object.keys(categoryValue).map(
                                    (ele, index) => {
                                      return (
                                        <MenuItem key = {index} value={ele}>
                                          {categoryValue[ele]}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Nhãn</span>
                        <Icon
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                          icon="material-symbols:add-circle"
                          onClick={() => {
                            setAddProductInfoTab(true);
                            setAddType("groupcategory");
                            setGroupAddType(0)
                          }}
                        />
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div style={{ lineHeight: "40px" }}>
                            <div className="px-0 inline" style={{ position: "relative", minWidth:"100%" }}>
                              <div className="" style={{ position: "relative", minWidth:"100%" }}>
                                <div style={{ position: "relative", minWidth:"100%" }}>
                                {(groupLabelListAll)&&<Autocomplete
                                multiple
                                sx={{ minWidth: 120 }}
                                id="tags-outlined"
                                size = "small"
                                
                                value={(groupLabelListAll.filter(
                                  (v, i) => v.type === 0 && (v.ofProductType).includes(Number(productType))
                                )).filter((option) => {
                                  return groupForProduct.includes(option._id)})}

                                options= {groupLabelListAll.filter(
                                  (v, i) => v.type === 0 && (v.ofProductType).includes(Number(productType))
                                )}
                                // tên hiển thị 
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                />
                                
                                )}
                                filterOptions={(options, state) => {
                                    const inputValue = state.inputValue.toLowerCase();
                                    return options.filter((option) =>
                                      option.name.toLowerCase().includes(inputValue)
                                    );
                                  }}
                                onChange={(event, newValue) => {
                                    const selectedGroupIds = newValue.map((option) => option._id);
                                    const oldValue = (groupLabelListAll.filter(
                                      (v, i) => v.type !== 0 && (v.ofProductType).includes(Number(productType))
                                    )).filter((option) => groupForProduct.includes(option._id))
                                    .map(option => option._id)
                                    setGroupForProduct([...selectedGroupIds, ...oldValue]);
                                  }}
                                  
                                //stop warning
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                            />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Bộ sưu tập</span>
                        <Icon
                          style={{ cursor: "pointer", marginLeft: "5px" }}
                          icon="material-symbols:add-circle"
                          onClick={() => {
                            setAddProductInfoTab(true);
                            setAddType("groupcategory");
                            setGroupAddType(1)
                          }}
                        />
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div style={{ lineHeight: "40px" }}>
                            <div className="px-0 inline" style={{ position: "relative", minWidth:"100%" }}>
                              <div className="" style={{ position: "relative", minWidth:"100%" }}>
                                <div style={{ position: "relative", minWidth:"100%" }}>
                                {(groupLabelListAll)&&<Autocomplete
                                multiple
                                sx={{ minWidth: 120 }}
                                id="tags-outlined"
                                size = "small"
                                
                                value={(groupLabelListAll.filter(
                                  (v, i) => v.type === 1 && (v.ofProductType).includes(Number(productType))
                                )).filter((option) => {
                                  return groupForProduct.includes(option._id)})}

                                options= {groupLabelListAll.filter(
                                  (v, i) => v.type === 1 && (v.ofProductType).includes(Number(productType))
                                )}
                                // tên hiển thị 
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                />
                                
                                )}
                                filterOptions={(options, state) => {
                                    const inputValue = state.inputValue.toLowerCase();
                                    return options.filter((option) =>
                                      option.name.toLowerCase().includes(inputValue)
                                    );
                                  }}
                                onChange={(event, newValue) => {
                                    const selectedGroupIds = newValue.map((option) => option._id);
                                    const oldValue = (groupLabelListAll.filter(
                                      (v, i) => v.type !== 1 && (v.ofProductType).includes(Number(productType))
                                    )).filter((option) => groupForProduct.includes(option._id))
                                    .map(option => option._id)
                                    setGroupForProduct([...selectedGroupIds, ...oldValue]);
                                  }}
                                  
                                //stop warning
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                            />}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Mô tả</span>
                      </div>
                      {productFind._id&&isProduct&&<div className="edit-main">
                        <div className="popover-wrap">
                          <div style={{ lineHeight: "40px" }}>
                            <>
                              <CKEditor
                                initData={productFind.description}
                                data={description}
                                onChange={onDescriptionChange}
                              />
                            </>
                          </div>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="edit_section">
            <div className="product-detail-panel product-basic-info">
              <div className="panel-header">
                <div className="panel-title">
                  <div className="basic-info-title">Thông tin biến thể</div>
                </div>
              </div>
              <div className="panel-content-wrapper">
                <div className="panel-content">
                  <div className="p_container">
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Phân loại biến thể</span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={sizeDiff}
                                  onChange={(e) => {
                                    return setSizeDiff(e.target.checked);
                                  }}
                                />
                              }
                              label="Kích cỡ"
                            />
                          </FormGroup>
                          {sizeDiff && (
                            <div
                              className="variation-edit-item"
                              style={{ marginBottom: "16px" }}
                            >
                              <div className="variation-edit-panel">
                                <div className="variation-edit-left">
                                  Phân loại kích cỡ
                                </div>
                                <div className="variation-edit-right">
                                  <div className="child_tabs_">
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={sizeArray[0]}
                                            onChange={(e) => {
                                              return handleSizeColorChange(
                                                "size",
                                                0,
                                                e.target.checked,
                                                ""
                                              );
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="body2"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Size S
                                          </Typography>
                                        }
                                      />
                                    </FormGroup>
                                    <Box component="form" size="small">
                                      <div>
                                        <Paper
                                          elevation={0}
                                          style={{ background: "#ffffff" }}
                                        >
                                          <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            value={sizeArrayDesc[0]}
                                            onChange={(e) => {
                                              const newArray = [
                                                ...sizeArrayDesc,
                                              ];
                                              newArray[0] = e.target.value;
                                              setSizeArrayDesc(newArray);
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault(); // Hủy sự kiện onSubmit
                                              }
                                            }}
                                          />
                                        </Paper>
                                      </div>
                                    </Box>
                                    <Box component="form" size="small" style = {{marginTop:"5px"}}>
                                      <div>
                                        <Paper
                                          elevation={0}
                                          style={{ background: "#ffffff" }}
                                        >
                                          <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            value={sizeArrayLen[0]}
                                            onChange={(e) => {
                                              const newArray = [
                                                ...sizeArrayLen,
                                              ];
                                              newArray[0] = e.target.value;
                                              setSizeArrayLen(newArray);
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault(); // Hủy sự kiện onSubmit
                                              }
                                            }}
                                          />
                                        </Paper>
                                      </div>
                                    </Box>
                                  </div>
                                  <div className="child_tabs_">
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={sizeArray[1]}
                                            onChange={(e) => {
                                              return handleSizeColorChange(
                                                "size",
                                                1,
                                                e.target.checked,
                                                ""
                                              );
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="body2"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Size M
                                          </Typography>
                                        }
                                      />
                                    </FormGroup>
                                    <Box component="form" size="small">
                                      <div>
                                        <Paper
                                          elevation={0}
                                          style={{ background: "#ffffff" }}
                                        >
                                          <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            value={sizeArrayDesc[1]}
                                            onChange={(e) => {
                                              const newArray = [
                                                ...sizeArrayDesc,
                                              ];
                                              newArray[1] = e.target.value;
                                              setSizeArrayDesc(newArray);
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault(); // Hủy sự kiện onSubmit
                                              }
                                            }}
                                          />
                                        </Paper>
                                      </div>
                                    </Box>
                                    <Box component="form" size="small" style = {{marginTop:"5px"}}>
                                      <div>
                                        <Paper
                                          elevation={0}
                                          style={{ background: "#ffffff" }}
                                        >
                                          <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            value={sizeArrayLen[1]}
                                            onChange={(e) => {
                                              const newArray = [
                                                ...sizeArrayLen,
                                              ];
                                              newArray[1] = e.target.value;
                                              setSizeArrayLen(newArray);
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault(); // Hủy sự kiện onSubmit
                                              }
                                            }}
                                          />
                                        </Paper>
                                      </div>
                                    </Box>
                                  </div>
                                  <div className="child_tabs_">
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={sizeArray[2]}
                                            onChange={(e) => {
                                              return handleSizeColorChange(
                                                "size",
                                                2,
                                                e.target.checked,
                                                ""
                                              );
                                            }}
                                          />
                                        }
                                        label={
                                          <Typography
                                            variant="body2"
                                            style={{ fontSize: "14px" }}
                                          >
                                            Size L
                                          </Typography>
                                        }
                                      />
                                    </FormGroup>
                                    <Box component="form" size="small">
                                      <div>
                                        <Paper
                                          elevation={0}
                                          style={{ background: "#ffffff" }}
                                        >
                                          <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            value={sizeArrayDesc[2]}
                                            onChange={(e) => {
                                              const newArray = [
                                                ...sizeArrayDesc,
                                              ];
                                              newArray[2] = e.target.value;
                                              setSizeArrayDesc(newArray);
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault(); // Hủy sự kiện onSubmit
                                              }
                                            }}
                                          />
                                        </Paper>
                                      </div>
                                    </Box>
                                    <Box component="form" size="small" style = {{marginTop:"5px"}}>
                                      <div>
                                        <Paper
                                          elevation={0}
                                          style={{ background: "#ffffff" }}
                                        >
                                          <TextField
                                            id="outlined-size-small"
                                            size="small"
                                            value={sizeArrayLen[2]}
                                            onChange={(e) => {
                                              const newArray = [
                                                ...sizeArrayLen,
                                              ];  
                                              newArray[2] = e.target.value;
                                              setSizeArrayLen(newArray);
                                            }}  
                                            onKeyDown={(e) => {
                                              if (e.key === 'Enter') {
                                                e.preventDefault(); // Hủy sự kiện onSubmit
                                              }
                                            }}
                                          />
                                        </Paper>
                                      </div>
                                    </Box>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="variation-edit-item">
                            <div className="variation-edit-panel">
                              <div className="variation-edit-left">
                                Phân loại màu
                              </div>
                              <div className="variation-edit-right">
                                <div className="option-container">
                                  {colorArray.map((ele, index) => {
                                    return (
                                      <div key={index} className="options-item">
                                        <div
                                          style={{
                                            width: "200px",
                                            marginRight: "15px",
                                          }}
                                          className="popover-wrap variation-input-item"
                                        >
                                          <div className="product-edit-form-item-content">
                                            <div style={{ lineHeight: "40px" }}>
                                              <Box sx={{ width: 200 }}>
                                                <FormControl
                                                  fullWidth
                                                  size="small"
                                                >
                                                  <Select
                                                    className="white-background"
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={colorArray[index]}
                                                    onChange={(e) => {
                                                      const newArray = [
                                                        ...colorArray,
                                                      ];
                                                      newArray[index] =
                                                        e.target.value;
                                                      setColorArray(newArray);
                                                    }}
                                                  >
                                                    <MenuItem value="">
                                                      Chọn màu
                                                    </MenuItem>
                                                    {Object.keys(colorList).map(
                                                      (ele1, index1) => {
                                                        return (
                                                          <MenuItem
                                                            key={index1}
                                                            value={ele1}
                                                          >
                                                            {colorList[ele1]}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                  </Select>
                                                </FormControl>
                                                <Box component="form" size="small" style = {{marginTop:"5px"}}>
                                              <div>
                                                <Paper
                                                  elevation={0}
                                                  style={{ background: "#ffffff" }}
                                                >
                                                  <TextField
                                                    id="outlined-size-small"
                                                    size="small"
                                                    value={colorArrayDesc[index]}
                                                    onChange={(e) => {
                                                      const newArray = [
                                                        ...colorArrayDesc,
                                                      ];
                                                      newArray[index] = e.target.value;
                                                      setColorArrayDesc(newArray);
                                                    }}
                                                    onKeyDown={(e) => {
                                                      if (e.key === 'Enter') {
                                                        e.preventDefault(); // Hủy sự kiện onSubmit
                                                      }
                                                    }}
                                                  />
                                                </Paper>
                                              </div>
                                            </Box>
                                              </Box>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="options-action">
                                          <label className="file-input-label">
                                            {!colorImgList[index] && (
                                              <Icon
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                              icon="ri:image-fill"
                                            />
                                            )}
                                            {colorImgList[index] && (
                                              <img
                                                src={typeof colorImgList[index] === "object" ? URL.createObjectURL(colorImgList[index]) : `${cloudFrontURL}/${colorImgList[index]}`}
                                                alt="x"
                                                style={{
                                                  width: "30px",
                                                  height: "30px",
                                                }}
                                              />
                                            )}
                                            <input
                                              className="colorSelector"
                                              type="file"
                                              accept="image/*" // Để chỉ cho phép chọn các tập tin ảnh
                                              onChange={(e) => {
                                                const file = e.target.files[0];
                                                const newArray = [
                                                  ...colorImgList,
                                                ];
                                                newArray[index] = file;
                                                if(file){
                                                  newArray[index] = file;
                                                  setColorImgList(newArray);
                                                }
                                              }}
                                              style={{ marginTop:5, width:40,display:'block' }} // Ẩn phần tử input file mặc định
                                            />
                                          </label>
                                          <div className="options-item-btn">
                                            {colorArray.length > 1 && (
                                              <Icon
                                                style={{ marginTop: "5px" }}
                                                onClick={(e) => {
                                                  handleSizeColorChange(
                                                    "color",
                                                    index,
                                                    "",
                                                    "delete"
                                                  );
                                                }}
                                                icon="ph:trash-light"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  <div className="options-item">
                                    <div className="popover-wrap variation-input-item">
                                      <div className="product-edit-form-item-content">
                                        <div style={{ lineHeight: "40px" }}>
                                          <Box sx={{ width: 200 }}>
                                            <FormControl fullWidth size="small">
                                              <Select
                                                className="white-background"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={""}
                                                onChange={(e) => {
                                                  handleSizeColorChange(
                                                    "color",
                                                    "",
                                                    e.target.value,
                                                    "add"
                                                  );
                                                }}
                                              >
                                                {Object.keys(colorList).map(
                                                  (ele1, index1) => {
                                                    return (
                                                      <MenuItem
                                                        key={index1}
                                                        value={ele1}
                                                      >
                                                        {colorList[ele1]}
                                                      </MenuItem>
                                                    );
                                                  }
                                                )}
                                                <MenuItem value="">
                                                  Chọn màu
                                                </MenuItem>
                                              </Select>
                                            </FormControl>
                                          </Box>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      !sizeDiff && 
                      
                    <div className="edit-row">
                    <div className="edit-label edit-title">
                      <span>Mô tả</span>
                    </div>
                    <div className="edit-main">
                      <div className="popover-wrap">
                        <div className="product-edit-form-item-content">
                          <div style={{ lineHeight: "0px" }}>
                            <div
                              className={`input_inner ${
                                focus.nonSizeDesc ? "focused" : ""
                              }`}
                            >
                              <input
                                type="text"
                                value={nonSizeDesc}
                                onChange={(e) => {
                                  setNonSizeDesc(e.target.value);
                                }}
                                className="input_input"
                                onFocus={() => handleFocus("nonSizeDesc")}
                                onBlur={() => handleBlur("nonSizeDesc")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    }
                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span>Danh sách phân loại</span>
                      </div>
                      <div className="edit-row-right-full batch-container">
                        <Box
                          component="form"
                          size="small"
                          style={{
                            width: "150px",
                            display: "inline-flex",
                            marginRight: "10px",
                          }}
                        >
                          <div>
                            <Paper
                              elevation={0}
                              style={{ background: "#ffffff" }}
                            >
                              <TextField
                                label="Giá"
                                id="outlined-size-small"
                                size="small"
                                // type="number"
                                value={moneyFormat(allSet[0] ?? 0)}
                                onChange={(e) => {
                                  const value = e.target.value
                                    .replace(/^0+/, "")
                                    .replace(/[^0-9]/g, "");
                                  let newArray = [...allSet];
                                  newArray[0] = Number(value);
                                  setAllSet(newArray);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault(); // Hủy sự kiện onSubmit
                                  }
                                }}
                              />
                            </Paper>
                          </div>
                        </Box>
                        <Box
                          component="form"
                          size="small"
                          style={{
                            width: "150px",
                            display: "inline-flex",
                            marginRight: "10px",
                          }}
                        >
                          <div>
                            <Paper
                              elevation={0}
                              style={{ background: "#ffffff" }}
                            >
                              <TextField
                                label="Tồn kho"
                                id="outlined-size-small"
                                size="small"
                                type="number"
                                value={allSet[1]}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  let newArray = [...allSet];
                                  newArray[1] = Number(value);
                                  setAllSet(newArray);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault(); // Hủy sự kiện onSubmit
                                  }
                                }}
                              />
                            </Paper>
                          </div>
                        </Box>
                        <Box
                          component="form"
                          size="small"
                          style={{
                            width: "150px",
                            display: "inline-flex",
                            marginRight: "10px",
                          }}
                        >
                          <div>
                            <Paper
                              elevation={0}
                              style={{ background: "#ffffff" }}
                            >
                              <TextField
                                label="SKU"
                                id="outlined-size-small"
                                size="small"
                                // type="number"
                                value={allSet[2]}
                                onChange={(e) => {
                                  let newArray = [...allSet];
                                  newArray[2] = e.target.value;
                                  setAllSet(newArray);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault(); // Hủy sự kiện onSubmit
                                  }
                                }}
                              />
                            </Paper>
                          </div>
                        </Box>
                        <div
                          style={{ marginLeft: "16px", padding: "0 16px", top:"7px" }}
                          className="batch-apply-button shopee-button shopee-button--primary shopee-button--normal "
                          onClick={(e)=>{
                            if(productList.length>0){
                              let newProductList = JSON.parse(JSON.stringify(productList))
                              for(let i in newProductList){
                                for (let j in newProductList[i]){
                                  if(allSet[0]!==0){
                                    newProductList[i][j].price = allSet[0]
                                  }
                                  if(allSet[1]!==0){
                                    newProductList[i][j].remain = allSet[1]
                                  }
                                  if(allSet[2]!==""){
                                    newProductList[i][j].sku = allSet[2]
                                  }
                                }
                              }
                              setProductList(newProductList)
                            }
                          }}
                        >
                          <span
                            style={{
                              lineHeight: 1,
                              verticalAlign: "middle",
                            }}
                          >
                            {" "}
                            Áp dụng cho tất cả phân loại{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="edit-row">
                      <div className="edit-label edit-title">
                        <span></span>
                      </div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          {productList.length !== 0 && (
                            <TableContainer component={Paper}>
                              <Table align="center">
                                <TableHead>
                                  <TableRow>
                                    {sizeDiff && (
                                      <TableCell
                                        style={{
                                          ...tableStyle,
                                          width: "120px",
                                        }}
                                        align="center"
                                        className="greyBackground"
                                        rowSpan={1}
                                        colSpan={1}
                                      >
                                        Kích thước
                                      </TableCell>
                                    )}
                                    <TableCell
                                      style={{ ...tableStyle, width: "80px" }}
                                      align="center"
                                      className="greyBackground"
                                      colSpan={1}
                                    >
                                      Màu sắc
                                    </TableCell>
                                    <TableCell
                                      style={{ ...tableStyle, width: "120px" }}
                                      align="center"
                                      className="greyBackground"
                                      colSpan={1}
                                    >
                                      Giá
                                    </TableCell>
                                    <TableCell
                                      style={{ ...tableStyle, width: "120px" }}
                                      align="center"
                                      className="greyBackground"
                                      colSpan={1}
                                    >
                                      Tồn kho
                                    </TableCell>
                                    <TableCell
                                      style={{ ...tableStyle, width: "120px" }}
                                      align="center"
                                      className="greyBackground"
                                      colSpan={1}
                                    >
                                      Sku
                                    </TableCell>
                                    <TableCell
                                      style={{ ...tableStyle, width: "100px" }}
                                      align="center"
                                      className="greyBackground"
                                      colSpan={1}
                                    >
                                      Trạng thái
                                    </TableCell>
                                    <TableCell
                                      style={{ ...tableStyle, width: "50px" }}
                                      align="center"
                                      className="greyBackground"
                                      colSpan={1}
                                    >
                                      Ảnh
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {true &&
                                    (!sizeDiff
                                      ? [true]
                                      : new Array(
                                          sizeArray.reduce(
                                            (acc, current) =>
                                              current === true ? acc + 1 : acc,
                                            0
                                          )
                                        ).fill(true)
                                    ).map((size, sizeIndex) =>
                                      new Array(colorArray.length)
                                        .fill(true)
                                        .map((color, colorIndex) => (
                                          <TableRow
                                            key={`${sizeIndex}-${colorIndex}`}
                                          >
                                            {colorIndex === 0 && sizeDiff && (
                                              <TableCell
                                                style={{
                                                  ...tableStyle,
                                                  width: "120px",
                                                }}
                                                align="center"
                                                rowSpan={colorArray.length}
                                              >
                                                {getSizeName(sizeIndex)}
                                              </TableCell>
                                            )}
                                            <TableCell
                                              style={{
                                                ...tableStyle,
                                                width: "80px",
                                              }}
                                              align="center"
                                            >
                                              {
                                                colorList[
                                                  colorArray[colorIndex]
                                                ]
                                              }
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                ...tableStyle,
                                                width: "120px",
                                              }}
                                              align="center"
                                            >
                                              <Box
                                                component="form"
                                                size="small"
                                                style={{
                                                  width: "100%",
                                                  display: "block",
                                                }}
                                              >
                                                <div>
                                                  <Paper
                                                    elevation={0}
                                                    style={{
                                                      background: "#ffffff",
                                                    }}
                                                  >
                                                    <TextField
                                                      id="outlined-size-small"
                                                      size="small"
                                                      // type="number"
                                                      value={moneyFormat(
                                                        productList[
                                                          sizeIndex
                                                        ]?.[colorIndex]
                                                          ?.price ?? 0
                                                      )}
                                                      onChange={(e) => {
                                                        const value =
                                                          e.target.value
                                                            .replace(/^0+/, "")
                                                            .replace(
                                                              /[^0-9]/g,
                                                              ""
                                                            );
                                                        handleProductListChange(
                                                          "price",
                                                          sizeIndex,
                                                          colorIndex,
                                                          Number(value)
                                                        );
                                                      }}
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                          e.preventDefault(); // Hủy sự kiện onSubmit
                                                        }
                                                      }}
                                                    />
                                                  </Paper>
                                                </div>
                                              </Box>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                ...tableStyle,
                                                width: "120px",
                                              }}
                                              align="center"
                                            >
                                              <Box
                                                component="form"
                                                size="small"
                                                style={{
                                                  width: "100%",
                                                  display: "block",
                                                }}
                                              >
                                                <div>
                                                  <Paper
                                                    elevation={0}
                                                    style={{
                                                      background: "#ffffff",
                                                    }}
                                                  >
                                                    <TextField
                                                      id="outlined-size-small"
                                                      size="small"
                                                      // type="number"
                                                      value={
                                                        (productList[sizeIndex]&&productList[sizeIndex][colorIndex])?(productList[sizeIndex][
                                                          colorIndex
                                                        ]?.remain ?? 0):0
                                                      }
                                                      onChange={(e) => {
                                                        const value =
                                                          e.target.value.replace(
                                                            /[^0-9]/g,
                                                            ""
                                                          );
                                                        const newValue =
                                                          Number(value) !== 0
                                                            ? value.replace(
                                                                /^0+/,
                                                                ""
                                                              )
                                                            : Number(value);
                                                        handleProductListChange(
                                                          "remain",
                                                          sizeIndex,
                                                          colorIndex,
                                                          newValue
                                                        );
                                                      }}
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                          e.preventDefault(); // Hủy sự kiện onSubmit
                                                        }
                                                      }}
                                                    />
                                                  </Paper>
                                                </div>
                                              </Box>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                ...tableStyle,
                                                width: "120px",
                                              }}
                                              align="center"
                                            >
                                              <Box
                                                component="form"
                                                size="small"
                                                style={{
                                                  width: "100%",
                                                  display: "block",
                                                }}
                                              >
                                                <div>
                                                  <Paper
                                                    elevation={0}
                                                    style={{
                                                      background: "#ffffff",
                                                    }}
                                                  >
                                                    <TextField
                                                      id="outlined-size-small"
                                                      size="small"
                                                      value={
                                                        (productList[sizeIndex]&&productList[sizeIndex][colorIndex])?(productList[sizeIndex][
                                                          colorIndex
                                                        ]?.sku ?? 0):0
                                                      }
                                                      onChange={(e) => {
                                                        handleProductListChange(
                                                          "sku",
                                                          sizeIndex,
                                                          colorIndex,
                                                          e.target.value
                                                        );
                                                      }}
                                                      onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                          e.preventDefault(); // Hủy sự kiện onSubmit
                                                        }
                                                      }}
                                                    />
                                                  </Paper>
                                                </div>
                                              </Box>
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                ...tableStyle,
                                                width: "100px",
                                              }}
                                              align="center"
                                            >
                                              <FormControlLabel
                                                style={{
                                                  marginRight: "8px",
                                                  marginLeft: "10px",
                                                }}
                                                control={
                                                  <Checkbox
                                                    style={{
                                                      width: "15px",
                                                      height: "15px",
                                                    }}
                                                    checked={
                                                      (productList[sizeIndex]&&productList[sizeIndex][colorIndex])?(productList[sizeIndex][
                                                        colorIndex
                                                      ]?.new ?? false):false
                                                    }
                                                    onChange={(e) => {
                                                      handleProductListChange(
                                                        "new",
                                                        sizeIndex,
                                                        colorIndex,
                                                        null
                                                      );
                                                    }}
                                                    icon={<StarBorderIcon />}
                                                    checkedIcon={<StarIcon />}
                                                  />
                                                }
                                                title="Mới về"
                                              />
                                              <FormControlLabel
                                                style={{ marginRight: "8px" }}
                                                control={
                                                  <Checkbox
                                                    style={{
                                                      width: "15px",
                                                      height: "15px",
                                                      marginLeft: "8px",
                                                    }}
                                                    checked={
                                                      (productList[sizeIndex]&&productList[sizeIndex][colorIndex])?(productList[sizeIndex][
                                                        colorIndex
                                                      ]?.popular ?? false):false
                                                    }
                                                    onChange={(e) => {
                                                      handleProductListChange(
                                                        "popular",
                                                        sizeIndex,
                                                        colorIndex,
                                                        null
                                                      );
                                                    }}
                                                    icon={
                                                      <BookmarkBorderIcon />
                                                    }
                                                    checkedIcon={
                                                      <BookmarkIcon />
                                                    }
                                                  />
                                                }
                                                title="Phổ biến"
                                              />
                                              <FormControlLabel
                                                style={{ marginRight: "0px" }}
                                                control={
                                                  <Checkbox
                                                    style={{
                                                      width: "15px",
                                                      height: "15px",
                                                      marginLeft: "8px",
                                                    }}
                                                    checked={
                                                      (productList[sizeIndex]&&productList[sizeIndex][colorIndex])?(productList[sizeIndex][
                                                        colorIndex
                                                      ]?.show ?? false):false
                                                    }
                                                    onChange={(e) => {
                                                      handleProductListChange(
                                                        "show",
                                                        sizeIndex,
                                                        colorIndex,
                                                        null
                                                      );
                                                    }}
                                                    icon={<VisibilityOffIcon />}
                                                    checkedIcon={
                                                      <VisibilityIcon />
                                                    }
                                                  />
                                                }
                                                title="Ẩn/hiện"
                                              />
                                            </TableCell>
                                            <TableCell
                                              style={{
                                                ...tableStyle,
                                                width: "50px",
                                              }}
                                              align="center"
                                              colSpan={1}
                                            >
                                              <label className="file-input-label">
                                                {
                                                (typeof productImgList[sizeIndex]?.[colorIndex] === 'object' &&
                                                (!productImgList[sizeIndex]?.[colorIndex]?.[0])) && (
                                                  <Icon
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                    }}
                                                    icon="ri:image-fill"
                                                  />
                                                )}
                                                {
                                                  
                                                productImgList&&
                                                productImgList[sizeIndex]&&
                                                productImgList[sizeIndex][colorIndex]&&  
                                                productImgList[sizeIndex][colorIndex][0] && 
                                                (
                                                  <img
                                                    src={(typeof productImgList[sizeIndex][colorIndex][0] === 'object')
                                                    ?URL.createObjectURL(productImgList[sizeIndex][colorIndex][0])
                                                    :(productImgList[sizeIndex][colorIndex][0]?`${cloudFrontURL}/${productImgList[sizeIndex][colorIndex][0]}`:"")
                                                  }
                                                    // src = ""
                                                    alt="x"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                    }}
                                                  />
                                                )}
                                                <input
                                                  className="colorSelector"
                                                  type="file"
                                                  multiple
                                                  accept="image/*" // Để chỉ cho phép chọn các tập tin ảnh
                                                  onChange={(e) => {
                                                    const newArray = [
                                                      ...productImgList,
                                                    ];
                                                    const selectedFiles =
                                                      e.target.files;
                                                    if(selectedFiles.length!==0){
                                                      const newImageFiles =
                                                      Array.from(selectedFiles);
                                                      //sắp xếp theo tên
                                                      // newImageFiles.sort((a, b) =>
                                                      //   a.name.localeCompare(
                                                      //     b.name
                                                      //   )
                                                      // );
                                                      newArray[sizeIndex] = [
                                                        ...newArray[sizeIndex]
                                                      ];
                                                      newArray[sizeIndex][
                                                        colorIndex
                                                      ] = newImageFiles;
                                                      setProductImgList(newArray);

                                                    }
                                                  }}
                                                  style={{ marginTop:5, width:40,display:'block' }} // Ẩn phần tử input file mặc định
                                                />
                                              </label>
                                            </TableCell>
                                          </TableRow>
                                        ))
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </div>
                      </div>
                    </div>
                    
                    <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div>
                                <button
                                  className="site-button orange-button "
                                  onClick={(e) => {
                                    setShowImgList(prev=>{return !prev})
                                  }}
                                >
                                  Xem ảnh
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`edit-row show-img-list ${showImgList?"active":""}`}>
                    { swapProductImage && productList.length > 0 &&
                        productList.map((ele, index) => {
                          return (
                            <div key={index}>
                              {swapProductImage[index]&&ele.map((ele1, index1) => {
                                return (
                                  <div
                                    className={`edit-row `}
                                    style={{ marginBottom: 0 }}
                                    key={index1}
                                  >
                                    <div className="edit-label edit-title"></div>
                                    <div className="edit-main">
                                      <div
                                        className="edit-main shopee-image-manager"
                                        style={{
                                          display: "inline-block",
                                          width: "auto!important",
                                          position: "relative",
                                        }}
                                      >
                                        <div>{`${
                                          sizeDiff
                                            ? getSizeName(index) + " / "
                                            : ""
                                        }${
                                          colorList[colorArray[index1]] ?? ""
                                        }`}</div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {productImgList[index][index1]
                                            .length === 0 ? (
                                            "Chưa có ảnh"
                                          ) : (
                                            <React.Fragment>
                                              {swapProductImage[index][index1] && (productImgList[index][
                                                index1
                                              ]).map((ele2, index2) => { 
                                                return (
                                                  (swapProductImage[index][index1][index2]>=0) &&<div
                                                    key={index2}
                                                    className="img_box_dis"
                                                  >
                                                    <div
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                      }}
                                                    >
                                                      <div className="img_each_create">
                                                        <img
                                                          className="img"
                                                          src={typeof ele2 === "object" ? URL.createObjectURL(ele2) : `${cloudFrontURL}/${ele2}`}
                                                          alt="none"
                                                        ></img>
                                                        <div style={{alignItems:"center", textAlign:"center"}}>
                                                          
                                                          {swapProductImage[
                                                            index
                                                          ][index1][index2] ===
                                                            0 && (
                                                            <Icon
                                                              icon="ph:swap"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                let temp = [
                                                                  ...swapProductImage,
                                                                ];
                                                                let temp1 = [
                                                                  ...temp[
                                                                    index
                                                                  ],
                                                                ];
                                                                let temp2 = [
                                                                  ...temp1[
                                                                    index1
                                                                  ],
                                                                ];
                                                                temp2[
                                                                  index2
                                                                ] = 1;
                                                                temp1[index1] =
                                                                  temp2;
                                                                temp[index] =
                                                                  temp1;
                                                                setSwapProductImage(
                                                                  [...temp]
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                          {swapProductImage[
                                                            index
                                                          ][index1][index2] ===
                                                            1 && (
                                                            <Icon
                                                              icon="ph:swap-duotone"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                let temp = [
                                                                  ...swapProductImage,
                                                                ];
                                                                let temp1 = [
                                                                  ...temp[
                                                                    index
                                                                  ],
                                                                ];
                                                                let temp2 = [
                                                                  ...temp1[
                                                                    index1
                                                                  ],
                                                                ];
                                                                temp2[
                                                                  index2
                                                                ] = 0;
                                                                temp1[index1] =
                                                                  temp2;
                                                                temp[index] =
                                                                  temp1;
                                                                setSwapProductImage(
                                                                  [...temp]
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                          <Icon
                                                            icon="charm:bin"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                              let temp = [
                                                                ...productImgList,
                                                              ];
                                                              let temp1 = [
                                                                ...temp[
                                                                  index
                                                                ],
                                                              ];
                                                              let temp2 = [
                                                                ...temp1[
                                                                  index1
                                                                ],
                                                              ];
                                                              temp2.splice(index2,1)
                                                              temp1[index1] =
                                                                temp2;
                                                              temp[index] =
                                                                temp1;
                                                              setProductImgList(
                                                                [...temp]
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                              <div className="img_box_dis">
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                >
                                                  <div className="img_each_create">
                                                    <img
                                                      className="img"
                                                      src=""
                                                      alt="none"
                                                      style={{ opacity: 0 }}
                                                    ></img>
                                                    <div style={{alignItems:"center", textAlign:"center"}}>
                                                          
                                                          {swapProductImage&&swapProductImage[index]&&swapProductImage[index][index1]&&
                                                          swapProductImage[
                                                            index
                                                          ][index1][swapProductImage[index][index1].length-1] ===
                                                            0 && (
                                                            <Icon
                                                              icon="ph:swap"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                let temp = [
                                                                  ...swapProductImage,
                                                                ];
                                                                let temp1 = [
                                                                  ...temp[
                                                                    index
                                                                  ],
                                                                ];
                                                                let temp2 = [
                                                                  ...temp1[
                                                                    index1
                                                                  ],
                                                                ];
                                                                temp2[
                                                                  swapProductImage[index][index1].length-1
                                                                ] = 1;
                                                                temp1[index1] =
                                                                  temp2;
                                                                temp[index] =
                                                                  temp1;
                                                                setSwapProductImage(
                                                                  [...temp]
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                          {swapProductImage&&swapProductImage[index]&&swapProductImage[index][index1]&&swapProductImage[
                                                            index
                                                          ][index1][swapProductImage[index][index1].length-1] ===
                                                            1 && (
                                                            <Icon
                                                              icon="ph:swap-duotone"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                let temp = [
                                                                  ...swapProductImage,
                                                                ];
                                                                let temp1 = [
                                                                  ...temp[
                                                                    index
                                                                  ],
                                                                ];
                                                                let temp2 = [
                                                                  ...temp1[
                                                                    index1
                                                                  ],
                                                                ];
                                                                temp2[
                                                                  swapProductImage[index][index1].length-1
                                                                ] = 0;
                                                                temp1[index1] =
                                                                  temp2;
                                                                temp[index] =
                                                                  temp1;
                                                                setSwapProductImage(
                                                                  [...temp]
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                          
                                                        </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </React.Fragment>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>


                    <div className="edit-row">
                      <div className="edit-label edit-title"></div>
                      <div className="edit-main">
                        <div className="popover-wrap">
                          <div className="product-edit-form-item-content">
                            <div style={{ lineHeight: "0px" }}>
                              <div style={{ marginBottom: "50px" }}>
                                <button
                                  className="site-button orange-button "
                                  onClick={(e) => {
                                    if (
                                      window.confirm("Cập nhật sản phẩm?")
                                    ) {
                                      updateProduct(e);
                                    }
                                  }}
                                >
                                  Cập nhật
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {addProductInfoTab && (
        <AddProductInfo
          setAddProductInfoTab={setAddProductInfoTab}
          setTrigger={setTrigger}
          addType={addType}
          groupAddType = {groupAddType}
          groupLabelListAll={groupLabelListAll}
        />
      )}
    </React.Fragment>:
    <React.Fragment>
    <div>
      Sản phẩm không tồn tại
    </div>
  </React.Fragment>
  );
}

export default ProductInfo;
