import React, { useEffect, useState, useRef } from "react";

function Login() {
    const apiURL = process.env.REACT_APP_API_ENDPOINT
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleLogin = () => {
      if (email === '' || password === '') {
          alert('Không được để trống');
          return;
      }
  
      // Thêm biến timeout để theo dõi thời gian chờ đợi
      let timeout;
  
      // Hàm xử lý khi yêu cầu quá thời gian
      const handleTimeout = () => {
          alert('Yêu cầu đăng nhập quá lâu. Vui lòng thử lại sau!');
      };
  
      // Đặt hạn chế thời gian là 10 giây
      timeout = setTimeout(handleTimeout, 10000);
  
      fetch(apiURL + '/employee/login', {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              email,
              pwd: password
          })
      })
      .then((res) => {
          clearTimeout(timeout); // Hủy bỏ hạn chế thời gian nếu nhận được phản hồi trước đó
          if (res.ok) return res.json();
          throw new Error("Something went wrong");
      })
      .then((result) => {
          if (result.success) {
              localStorage && localStorage.setItem("banyanAdminToken", result.token);
              window.location.reload(false);
          } else {
              alert(result.mes);
          }
      })
      .catch((error) => {
          clearTimeout(timeout); // Hủy bỏ hạn chế thời gian nếu xảy ra lỗi
          alert('Đã xảy ra lỗi khi thực hiện yêu cầu đăng nhập!');
          console.error(error);
      });
  };
  

  return (
    <React.Fragment>
      <div
        className="toggleTab"
      >
        <div
          className="childTab"
        >
          <form onSubmit={(e)=> {
            e.preventDefault()
            handleLogin()
          }}> 
          <div>
            <h3 className="text-center">Đăng nhập</h3>

            <div>
              <div className=" px-0 mgt20">
                <div>
                  <label className="label-input-group">Email </label>
                  
                <div className="buttonDiv">
                  <input
                    className="next-input numInput"
                    value = {email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                  />
                </div>
                </div>
              </div>
              <div className="px-0 mgt20">
                <label className="label-input-group">
                  Mật khẩu
                </label>
                
                <div className="buttonDiv">
                  <input
                    type = "password"
                    className="next-input numInput"
                    value = { password }
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                  />
                </div>

              </div>
              
            </div>
            <div className="text-center mgt20">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogin()
                }}
              >
                Đăng nhập
              </button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
